import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateHistoryResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ICandidateHistory<T> extends IAsyncType<T> {
  candidateHistory: T;
  setCandidateHistory: Dispatch<SetStateAction<T>>;
}

const useCandidateHistory = (
  id?: number
): ICandidateHistory<CandidateHistoryResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
  CandidateHistoryResponse[]
  >(cadabraService.getCandidateHistory, [], id ? [id] : null);
  return {
    candidateHistory: data,
    setCandidateHistory: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCandidateHistory;

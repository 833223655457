import './Login.css';

import { Button, Container, CssBaseline, Typography } from '@mui/material';
import React, { useState } from 'react';

import { COSTextField } from '../../helpers/ui';
import { logInWithEmailAndPassword } from '../../firebase';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={'classes.paper'}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <form className={'classes.form'} noValidate>
          <COSTextField
            label="E-mail"
            name="username"
            autoComplete="username"
            autoFocus
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <COSTextField
            label="Password"
            name="password"
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={(value) => setPassword(value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={'classes.submit'}
            onClick={async () => await logInWithEmailAndPassword(email, password, setError)}
            disabled={!email || !password}
          >
            Login
          </Button>
          {error && (
            <Typography component="h6" variant="h6" color="error">
              {error}
            </Typography>
          )}
        </form>
      </div>
    </Container>
  );
};

export default Login;

import { Add, Check, CheckBox, CheckBoxOutlineBlank, Clear } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material';
import {
  COSButton,
  COSCellProps,
  COSLink,
  COSTable,
  COSTextField,
  COSUserAvatar
} from '../../helpers/ui';
import React, { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Axios from 'axios';
import { UserResponse } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../../helpers/hooks';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const fetchSize = 25;

const UsersComponent = () => {
  const { users, loading, setParameters } = useUsers();
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setParameters([search, showInactive]);
  }, [showInactive]);

  const onEnter = useCallback(() => {
    setParameters([search, showInactive]);
  }, [search, showInactive]);

  const visibleUsers = useMemo(() => users.slice(0, (page + 1) * fetchSize), [users, page]);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 400 && visibleUsers.length < users.length) {
          setPage(page + 1);
        }
      }
    },

    [users, page, visibleUsers]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);
  const handleAdd = useCallback(() => navigate('/user'), [navigate]);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Users
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <COSTextField
              label="Search"
              value={search}
              onChange={(value: string) => setSearch(value)}
              onEnter={onEnter}
            />
          </Grid>
          <Grid item lg={3} sx={{ marginTop: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                    color="primary"
                  />
                }
                label="Show Inactive"
              />
              <COSButton text="NEW" variant="outlined" onClick={handleAdd} startIcon={<Add />} />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <COSTable<UserResponse>
              isLoading={loading}
              data={visibleUsers}
              enablePagination={false}
              enableRowNumbers={true}
              enableStickyHeader={true}
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: { maxHeight: '600px' },
                onScroll: (event: UIEvent<HTMLDivElement>) =>
                  fetchMoreOnBottomReached(event.target as HTMLDivElement)
              }}
              columns={[
                {
                  accessorKey: 'id',
                  header: '',
                  size: 50,
                  Cell: ({ row }: COSCellProps<UserResponse>) => (
                    <COSLink href={`/user?id=${row.original.id}`}>
                      <COSUserAvatar
                        id={row.original.id}
                        name={row.original.name}
                        tooltipPlacement="left"
                        sx={{ marginY: 0.5 }}
                        withImage={true}
                      />
                    </COSLink>
                  )
                },
                {
                  accessorKey: 'name',
                  header: 'Name',
                  size: 250,
                  Cell: ({ row }: COSCellProps<UserResponse>) => (
                    <COSLink href={`/user?id=${row.original.id}`}>{row.original.name}</COSLink>
                  )
                },
                {
                  accessorKey: 'email',
                  header: 'Email',
                  size: 530,
                  Cell: ({ row }: COSCellProps<UserResponse>) => (
                    <Tooltip title={`Send e-email to ${row.original.email}`}>
                      <COSLink href={`mailto:${row.original.email}`}>{row.original.email}</COSLink>
                    </Tooltip>
                  )
                },
                {
                  accessorKey: 'isAdmin',
                  header: 'Admin',
                  size: 80,
                  Cell: ({ row }: COSCellProps<UserResponse>) =>
                    row.original.isAdmin ? <Check /> : <Clear />
                },
                {
                  accessorKey: 'isActive',
                  header: 'Active',
                  size: 80,
                  Cell: ({ row }: COSCellProps<UserResponse>) =>
                    row.original.isActive ? <Check /> : <Clear />
                }
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(UsersComponent, Axios);

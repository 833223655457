import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { TechnologyResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ITechnologies<T> extends IAsyncType<T> {
  technologies: T;
  setTechnologies: Dispatch<SetStateAction<T>>;
}

const useTechnologies = (
  search?: string,
  showInactive: boolean = false,
  names: string[] = [],
  limit: number = 25,
  skip: number = 0
): ITechnologies<TechnologyResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    TechnologyResponse[]
  >(cadabraService.getTechnologies, [], [search, showInactive, names, limit, skip]);
  return {
    technologies: data,
    setTechnologies: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useTechnologies;

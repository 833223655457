import {
  Badge,
  Box,
  Card,
  CardContent,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';
import { COSCandidateAvatar, stringToColor } from '../../../helpers/ui';

import { CandidateResponse } from '../../../services/api';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import ViewCandidateComponent from '../ViewCandidateComponent';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 700,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

interface CandidateCardComponentProps {
  candidate: CandidateResponse;
  selected: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const CandidateCardComponent = ({
  candidate,
  selected = false,
  onClick
}: CandidateCardComponentProps) => {
  return (
    <HtmlTooltip
      arrow
      placement="right"
      color="white"
      title={<ViewCandidateComponent candidate={candidate} />}
    >
      <Card
        sx={{
          display: 'flex',
          borderRadius: 50,
          padding: '0.25rem 0.5rem 0.25rem 1rem',
          marginBottom: 1,
          cursor: 'pointer',
          opacity: selected ? 1 : 0.4,
          backgroundColor: `${stringToColor(`${candidate.id} ${candidate.name}`)}11`
        }}
        onClick={onClick}
      >
        <Badge
          badgeContent={selected ? <CheckIcon sx={{ fontSize: 20 }} /> : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          overlap="circular"
          color="info"
        >
          <COSCandidateAvatar
            id={candidate.id}
            name={candidate.name}
            imagePath={candidate.imagePath}
            sx={{
              width: '5rem',
              height: '5rem',
              fontSize: '2.5rem',
              margin: 'auto',
              padding: 'auto'
            }}
          />
        </Badge>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '2 0 auto' }}>
            <Typography component="div" variant="h5">
              {candidate.name}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {candidate.headline ?? '-'}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
              {candidate.email ?? '-'} {candidate.phoneNumber ?? '-'}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </HtmlTooltip>
  );
};

export default CandidateCardComponent;

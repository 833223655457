import { COSCandidateAvatar, COSLink, COSUserAvatar } from '../../../../helpers/ui';
import { Card, CardContent, CardHeader, Tooltip, Typography } from '@mui/material';

import React from 'react';
import { UserInteractionResponse } from '../../../../services/api';
import moment from 'moment';

interface CandidateCardProps {
  candidateId: number;
  candidateName: string;
  candidateImagePath?: string;
  userName: string;
  createdAt: string;
  users: UserInteractionResponse[];
  weight: number;
}

const colors = ['#3F51B577', '#8B5C3D77', '#F4CD0377', '#AE236777'];
const CandidateCard = ({
  candidateId,
  candidateName,
  candidateImagePath,
  userName,
  createdAt,
  users,
  weight
}: CandidateCardProps) => {
  return (
    <Card
      sx={{
        borderRadius: 6,
        marginBottom: 2,
        borderWeight: weight,
        border: `5px solid ${colors[weight]}`,
        padding: '8px !important'
      }}
    >
      <CardHeader
        avatar={
          <COSLink href={`/candidate?id=${candidateId}`}>
            <COSCandidateAvatar
              id={candidateId}
              name={candidateName}
              imagePath={candidateImagePath}
              tooltipPlacement="left"
              sx={{ width: 48, height: 48, fontSize: 24 }}
            />
          </COSLink>
        }
        title={
          <>
            <COSLink href={`/candidate?id=${candidateId}`}>{candidateName}</COSLink>
            <Tooltip
              arrow
              placement="right"
              title={`${userName}, ${moment(createdAt).format('DD/MM/YY, hh:mm:ss')}`}
            >
              <Typography sx={{ paddingLeft: 1, paddingTop: 0.25, cursor: 'pointer' }}>
                {moment(createdAt).fromNow()}
              </Typography>
            </Tooltip>
          </>
        }
        titleTypographyProps={{
          component: 'span',
          fontSize: '18px'
        }}
        sx={{
          padding: '2px !important'
        }}
      />
      <CardContent sx={{ display: 'flex', padding: '2px !important' }}>
        {users.map((user: UserInteractionResponse) => (
          <COSLink key={user.userId} href={`/user?id=${user.userId}`}>
            <COSUserAvatar
              id={user.userId}
              name={user.userName}
              tooltipText={`${user.userName}, ${moment(user.createdAt).format(
                'DD/MM/YY, hh:mm:ss'
              )}`}
              tooltipPlacement="bottom"
              sx={{ width: 28, height: 28, fontSize: 12, marginRight: 0.3 }}
              withImage={true}
            />
          </COSLink>
        ))}
      </CardContent>
    </Card>
  );
};

export default CandidateCard;

import { CircularProgress, Container, Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Axios from 'axios';
import { COSTabPanel } from '../../helpers/ui';
import CandidateEventLogsComponent from './CandidateEventLogsComponent';
import CandidateInfoPanel from './CandidateInfoPanel/CandidateInfoPanel';
import CandidateProjectSuggestionsComponent from './CandidateProjectSuggestionsComponent/CandidateProjectSuggestionsComponent';
import { CandidateResponse } from '../../services/api';
import CandidateUserRelationComponent from './CandidateUserRelation/CandidateUserRelationComponent';
import { cadabraService } from '../../services/services';
import { parseQuery } from '../../helpers/url';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

interface CandidateForm extends Partial<CandidateResponse> {}

const CandidateComponent = () => {
  const location = useLocation();
  const { id, tabId } = parseQuery(location.search);
  const navigate = useNavigate();

  const [candidate, setCandidate] = useState<CandidateForm>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [tab, setTab] = useState(tabId ? +tabId : 0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (id) {
        const candidateResp = await cadabraService.getCandidate(id);
        setCandidate(candidateResp ? candidateResp.data : {});
      } else {
        setCandidate({});
      }
      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    setTab(tabId ? +tabId : 0);
  }, [tabId, setTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    navigate(`/candidate?${id ? `id=${id}&` : ''}tabId=${newTab}`);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl">
      {id && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Candidate Info" />
          <Tab label="Activity" />
          <Tab label="Suggestions" />
          <Tab label="Relations" />
        </Tabs>
      )}
      <COSTabPanel value={tab} index={0}>
        <CandidateInfoPanel candidate={candidate} setCandidate={setCandidate} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={1}>
        <CandidateEventLogsComponent candidateId={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={2}>
        {candidate && (
          <CandidateProjectSuggestionsComponent technologies={candidate.technologies ?? []} />
        )}
      </COSTabPanel>
      <COSTabPanel value={tab} index={3}>
        <CandidateUserRelationComponent candidateId={id} />
      </COSTabPanel>
    </Container>
  );
};

export default withErrorHandler(CandidateComponent, Axios);

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../../../services/cadabraService';
import React, { useEffect, useMemo, useState } from 'react';

import { CandidateProjectEventLogResponse } from '../../../../services/api';
import { Pie } from 'react-chartjs-2';
import { cadabraService } from '../../../../services/services';
import { stringToColor } from '../../../../helpers/ui';

const interactionTypeIds = [101, 102, 103, 104, 105];
ChartJS.register(ArcElement, Tooltip, Legend);

interface RejectionsDistributionProps {
  projectId: number;
}

const RejectionsDistribution = ({ projectId }: RejectionsDistributionProps) => {
  const [eventLogs, setEventLogs] = useState<CandidateProjectEventLogResponse[]>([]);
  useEffect(() => {
    if (projectId) {
      const getEventLogs = async () => {
        const resp = await cadabraService.getCandidateProjectEventLogs(
          [],
          [projectId],
          [],
          interactionTypeIds,
          undefined,
          undefined,
          0,
          1000
        );
        if (resp) {
          setEventLogs(resp.data);
        }
      };
      getEventLogs();
    }
  }, [projectId]);
  const rejectionInfo = useMemo(
    () =>
      eventLogs.reduce((res: any, log: CandidateProjectEventLogResponse) => {
        if (!res[log.type]) {
          res[log.type] = 0;
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        res[log.type] = res[log.type] + 1;
        return res;
      }, {}),
    [eventLogs]
  );
  return (
    <Pie
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'Rejections distribution'
          }
        }
      }}
      data={{
        labels: Object.keys(rejectionInfo).map(
          (key) => getInteractionTypesByKey(key as InteractionTypeKey).label
        ),
        datasets: [
          {
            label: 'Rejections distributions',
            data: Object.values(rejectionInfo),
            backgroundColor: Object.keys(rejectionInfo).map((key) => stringToColor(key))
          }
        ]
      }}
    />
  );
};

export default RejectionsDistribution;

import {
  Add,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AutoFixHighOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  LinkOutlined,
  Search
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Collapse,
  Grid,
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import {
  COSAsyncMultipleAutocomplete,
  COSButton,
  COSCandidateAvatar,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSRowActions,
  COSTable,
  COSTextField,
  Lookup
} from '../../helpers/ui';
import {
  CandidateResponse,
  CityResponse,
  PositionResponse,
  TechnologyResponse
} from '../../services/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Axios from 'axios';
import LinkIcon from '@mui/icons-material/Link';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { RelationTypeKey } from '../../services/cadabraService';
import Star from '@mui/icons-material/Star';
import { cadabraService } from '../../services/services';
import moment from 'moment';
import useCandidates from '../../helpers/hooks/useCandidates';
import { useDebounce } from '@react-hook/debounce';
import { useFlag } from '../../helpers/hooks';
import usePositions from '../../helpers/hooks/usePositions';
import useTechnologies from '../../helpers/hooks/useTechnologies';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const apiFetchSize = 50;

const iconStyle: SxProps<Theme> = {
  fontSize: 20,
  borderRadius: 12,
  padding: '1px !important',
  color: 'white',
  backgroundColor: '#be4e85'
};

const userRelationIcon = {
  [RelationTypeKey.FIRST_CONNECTION]: <LinkIcon sx={iconStyle} />,
  [RelationTypeKey.HAS_EMAIL_PERMISSION]: <MailOutlineIcon sx={iconStyle} />,
  [RelationTypeKey.HAS_PHONE_PERMISSION]: <LocalPhoneIcon sx={iconStyle} />,
  [RelationTypeKey.PERSONAL_FRIEND]: <Star sx={iconStyle} />
};

const CandidatesComponent = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const { candidates, loading, setParameters } = useCandidates();
  const [expandedView, setExpandedView] = useFlag(
    searchParams.has('expandedView') ? searchParams.get('expandedView') === 'true' : false
  );
  const [search, setSearch] = useState<string>(searchParams.get('query') ?? '');
  const technologiesData = useTechnologies();
  const [technologiesValue, setTechnologiesValue] = useState<Lookup[]>(
    searchParams.has('technologyIds') ? JSON.parse(searchParams.get('technologyIds')!) : []
  );
  const [technologiesFilter, setTechnologiesFilter] = useDebounce<string>('', 250);
  const desiredTechnologiesData = useTechnologies();
  const [desiredTechnologiesValue, setDesiredTechnologiesValue] = useState<Lookup[]>(
    searchParams.has('desiredTechnologyIds')
      ? JSON.parse(searchParams.get('desiredTechnologyIds')!)
      : []
  );
  const [desiredTechnologiesFilter, setDesiredTechnologiesFilter] = useDebounce<string>('', 250);
  const mainTechnologiesData = useTechnologies();
  const [mainTechnologiesValue, setMainTechnologiesValue] = useState<Lookup[]>(
    searchParams.has('mainTechnologyIds') ? JSON.parse(searchParams.get('mainTechnologyIds')!) : []
  );
  const [mainTechnologiesFilter, setMainTechnologiesFilter] = useDebounce<string>('', 250);
  const positionsData = usePositions();
  const [positionsValue, setPositionsValue] = useState<Lookup[]>(
    searchParams.has('positionIds') ? JSON.parse(searchParams.get('positionIds')!) : []
  );
  const [positionsFilter, setPositionsFilter] = useDebounce<string>('', 250);
  const [cities, setCities] = useState<PositionResponse[]>([]);
  const [citiesValue, setCitiesValue] = useState<Lookup[]>(
    searchParams.has('cityIds') ? JSON.parse(searchParams.get('cityIds')!) : []
  );
  const [citiesFilter, setCitiesFilter] = useDebounce<string>('', 250);
  const [weeksSinceLastContact, setWeeksSinceLastContact] = useState<number>(
    searchParams.get('weeksSinceLastContact')
      ? +(searchParams.get('weeksSinceLastContact') ?? '0')
      : 0
  );
  const [page, setPage] = useState<number>(
    searchParams.has('selectedPage') ? +(searchParams.get('selectedPage') ?? '0') : 0
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    technologiesData.setParameters([technologiesFilter]);
  }, [technologiesFilter]);

  useEffect(() => {
    desiredTechnologiesData.setParameters([desiredTechnologiesFilter]);
  }, [desiredTechnologiesFilter]);

  useEffect(() => {
    mainTechnologiesData.setParameters([mainTechnologiesFilter]);
  }, [mainTechnologiesFilter]);

  useEffect(() => {
    positionsData.setParameters([positionsFilter]);
  }, [positionsFilter]);

  const onEnter = useCallback(() => {
    setPage(0);
    setParameters([
      search,
      undefined,
      technologiesValue.map((t) => t.id),
      desiredTechnologiesValue.map((t) => t.id),
      mainTechnologiesValue.map((t) => t.id),
      positionsValue.map((p) => p.id),
      citiesValue.map((c) => c.id),
      weeksSinceLastContact,
      apiFetchSize,
      0
    ]);
    setSearchParams(
      createSearchParams({
        query: search,
        technologyIds: JSON.stringify(technologiesValue),
        desiredTechnologyIds: JSON.stringify(desiredTechnologiesValue),
        mainTechnologyIds: JSON.stringify(mainTechnologiesValue),
        positionIds: JSON.stringify(positionsValue),
        cityIds: JSON.stringify(citiesValue),
        selectedPage: '0',
        weeksSinceLastContact: weeksSinceLastContact.toString(),
        expandedView: expandedView.toString()
      })
    );
  }, [
    search,
    technologiesValue,
    desiredTechnologiesValue,
    mainTechnologiesValue,
    positionsValue,
    citiesValue,
    weeksSinceLastContact,
    expandedView
  ]);

  useEffect(() => {
    setPage(page);
    setParameters([
      search,
      undefined,
      technologiesValue.map((t) => t.id),
      mainTechnologiesValue.map((m) => m.id),
      desiredTechnologiesValue.map((d) => d.id),
      positionsValue.map((p) => p.id),
      citiesValue.map((c) => c.id),
      weeksSinceLastContact,
      apiFetchSize,
      page * apiFetchSize
    ]);
    setSearchParams(
      createSearchParams({
        query: search,
        technologyIds: JSON.stringify(technologiesValue),
        desiredTechnologyIds: JSON.stringify(desiredTechnologiesValue),
        mainTechnologyIds: JSON.stringify(mainTechnologiesValue),
        positionIds: JSON.stringify(positionsValue),
        cityIds: JSON.stringify(citiesValue),
        selectedPage: page.toString(),
        weeksSinceLastContact: weeksSinceLastContact.toString(),
        expandedView: expandedView.toString()
      })
    );
  }, [page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [candidates]);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await cadabraService.getCities(citiesFilter);
      setCities(citiesData);
    };
    fetchCities().catch(console.error);
  }, [citiesFilter]);

  const handleAdd = useCallback(() => navigate('/candidate'), [navigate]);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Candidates
      </Typography>
      <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <COSTextField
            label="Search"
            value={search}
            onChange={(value) => setSearch(value)}
            onEnter={onEnter}
          />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <COSAsyncMultipleAutocomplete<Lookup>
            label="Main technologies"
            value={mainTechnologiesValue}
            options={mainTechnologiesData.technologies.map((technology: TechnologyResponse) => ({
              id: technology.id,
              value: technology.name
            }))}
            loading={mainTechnologiesData.loading}
            onChange={(value: Lookup[]) => setMainTechnologiesValue(value)}
            minChar={0}
            filter={mainTechnologiesFilter}
            onChangeFilter={(mainTechnologiesFilter: string) =>
              setMainTechnologiesFilter(mainTechnologiesFilter)
            }
          />
        </Grid>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <COSTextField
            label="Weeks since last contact"
            type="number"
            value={`${weeksSinceLastContact}`}
            onChange={(value) => setWeeksSinceLastContact(+value)}
          />
        </Grid>
        <Grid item lg={2} md={12}>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              paddingTop: 1,
              justifyContent: 'space-between'
            }}
          >
            <COSButton text="SEARCH" variant="contained" onClick={onEnter} startIcon={<Search />} />
            <COSButton text="NEW" variant="outlined" onClick={handleAdd} startIcon={<Add />} />
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Collapse in={expandedView} sx={{ width: '100%' }}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <COSAsyncMultipleAutocomplete<Lookup>
                  label="Desired technologies"
                  value={desiredTechnologiesValue}
                  options={desiredTechnologiesData.technologies.map(
                    (technology: TechnologyResponse) => ({
                      id: technology.id,
                      value: technology.name
                    })
                  )}
                  loading={desiredTechnologiesData.loading}
                  onChange={(value: Lookup[]) => setDesiredTechnologiesValue(value)}
                  minChar={0}
                  filter={desiredTechnologiesFilter}
                  onChangeFilter={(desiredTechnologiesFilter: string) =>
                    setDesiredTechnologiesFilter(desiredTechnologiesFilter)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <COSAsyncMultipleAutocomplete<Lookup>
                  label="Technologies"
                  value={technologiesValue}
                  options={technologiesData.technologies.map((technology: TechnologyResponse) => ({
                    id: technology.id,
                    value: technology.name
                  }))}
                  loading={technologiesData.loading}
                  onChange={(value: Lookup[]) => setTechnologiesValue(value)}
                  minChar={0}
                  filter={technologiesFilter}
                  onChangeFilter={(technologiesFilter: string) =>
                    setTechnologiesFilter(technologiesFilter)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <COSAsyncMultipleAutocomplete<Lookup>
                  label="Cities"
                  value={citiesValue}
                  options={cities.map((city: CityResponse) => ({
                    id: city.id,
                    value: city.name
                  }))}
                  loading={loading}
                  onChange={(value: Lookup[]) => setCitiesValue(value)}
                  minChar={0}
                  filter={citiesFilter}
                  onChangeFilter={(citiesFilter: string) => setCitiesFilter(citiesFilter)}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <COSAsyncMultipleAutocomplete<Lookup>
                  label="Positions"
                  value={positionsValue}
                  options={positionsData.positions.map((position: PositionResponse) => ({
                    id: position.id,
                    value: position.name
                  }))}
                  loading={loading}
                  onChange={(value: Lookup[]) => setPositionsValue(value)}
                  minChar={0}
                  filter={positionsFilter}
                  onChangeFilter={(positionsFilter: string) => setPositionsFilter(positionsFilter)}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} container justifyContent="center">
          <COSIconButton
            onClick={() => setExpandedView(!expandedView)}
            sx={{ marginTop: expandedView ? -1 : -2 }}
          >
            <Tooltip arrow placement="top" title={expandedView ? 'Less options' : 'More options'}>
              {expandedView ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
            </Tooltip>
          </COSIconButton>
        </Grid>
      </Grid>
      <Box sx={{ margin: 2, marginTop: 0 }}>
        <COSTable<CandidateResponse>
          isLoading={loading}
          data={candidates}
          enablePagination={false}
          enableStickyHeader={true}
          muiTableContainerProps={{
            ref: tableContainerRef,
            sx: { maxHeight: '60vh', minHeight: '300px' }
          }}
          columns={[
            {
              accessorKey: 'id',
              header: '#',
              size: 50,
              Cell: ({ row }: COSCellProps<CandidateResponse>) =>
                row.index + page * apiFetchSize + 1
            },
            {
              accessorKey: 'name',
              header: 'Candidate',
              size: 250,
              Cell: ({ row }: COSCellProps<CandidateResponse>) => (
                <COSLink href={`/candidate?id=${row.original.id}`}>
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={{ my: 'auto', maxWidth: 60 }}>
                      <Badge
                        sx={{ marginRight: 0.5, padding: 0 }}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          row.original.userRelations?.length > 0 ? (
                            <Box
                              sx={{
                                display: 'flex'
                              }}
                            >
                              {row.original.userRelations
                                .slice(0, 2)
                                .map((relationTypeKey: string) => (
                                  <Box key={relationTypeKey}>
                                    {userRelationIcon[relationTypeKey as RelationTypeKey]}
                                  </Box>
                                ))}
                            </Box>
                          ) : undefined
                        }
                      >
                        <COSCandidateAvatar
                          id={row.original.id}
                          name={row.original.name}
                          imagePath={row.original.imagePath}
                          sx={{ width: 64, height: 64, fontSize: 30, borderRadius: 15 }}
                        />
                      </Badge>
                    </Box>
                    <Box sx={{ p: 1.5, mx: 0.75 }}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          color: 'rgba(0, 0, 0, 0.95) !important',
                          pb: 0.75
                        }}
                      >
                        {row.original.name}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: 'rgba(0, 0, 0, 0.67)', fontSize: 14, lineHeight: 1 }}
                      >
                        {row.original.headline}
                      </Typography>
                    </Box>
                  </Box>
                </COSLink>
              )
            },
            {
              accessorKey: 'email',
              header: 'Contact',
              size: 200,
              Cell: ({ row }: COSCellProps<CandidateResponse>) => (
                <Box sx={{ display: 'grid' }}>
                  <Tooltip title={`Send e-email to ${row.original.email}`}>
                    <COSLink href={`mailto:${row.original.email}`}>{row.original.email}</COSLink>
                  </Tooltip>
                  <Tooltip title={`Call ${row.original.phoneNumber}`}>
                    <COSLink href={`tel:${row.original.phoneNumber}`}>
                      {row.original.phoneNumber}
                    </COSLink>
                  </Tooltip>
                </Box>
              )
            },
            {
              accessorKey: 'mainTechnologies',
              header: 'Main technologies',
              size: 200,
              Cell: ({ row }: COSCellProps<CandidateResponse>) => {
                const matchingTechnologies = row.original.mainTechnologies
                  .filter((t) => mainTechnologiesValue.findIndex((tech) => tech.id === t.id) > -1)
                  .map((t) => t.name);
                const additionalTechnologies = row.original.mainTechnologies
                  .filter((t) => mainTechnologiesValue.findIndex((tech) => tech.id === t.id) === -1)
                  .map((t) => t.name);
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem'
                    }}
                  >
                    <Tooltip
                      title={[...matchingTechnologies, ...additionalTechnologies].join(',')}
                      arrow
                      placement="right"
                    >
                      <Typography>
                        <b>{matchingTechnologies.join(', ')}</b>
                        {matchingTechnologies.length > 0 ? ', ' : ''}
                        {additionalTechnologies.join(', ').slice(0, 100)}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              }
            },
            {
              accessorKey: 'desiredTechnologies',
              header: 'Desired',
              size: 100,
              Cell: ({ row }: COSCellProps<CandidateResponse>) => {
                const matchingTechnologies = row.original.desiredTechnologies
                  .filter(
                    (t) => desiredTechnologiesValue.findIndex((tech) => tech.id === t.id) > -1
                  )
                  .map((t) => t.name);
                const additionalTechnologies = row.original.desiredTechnologies
                  .filter(
                    (t) => desiredTechnologiesValue.findIndex((tech) => tech.id === t.id) === -1
                  )
                  .map((t) => t.name);
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem'
                    }}
                  >
                    <Tooltip
                      title={[...matchingTechnologies, ...additionalTechnologies].join(',')}
                      arrow
                      placement="right"
                    >
                      <Typography>
                        <b>{matchingTechnologies.join(', ')}</b>
                        {matchingTechnologies.length > 0 ? ', ' : ''}
                        {additionalTechnologies.join(', ').slice(0, 100)}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              }
            },
            {
              Cell: ({ row }) =>
                row.original.lastContactedDate
                  ? moment(row.original.lastContactedDate).format('DD/MM/YY')
                  : '-',
              accessorKey: 'lastContactedDate',
              header: 'Last contacted',
              size: 120,
              muiTableHeadCellFilterTextFieldProps: {
                type: 'date'
              },
              sortingFn: 'datetime'
            },
            {
              accessorKey: 'id',
              header: 'Actions',
              size: 80,
              Cell: ({ row }: COSRowActions<CandidateResponse>) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <COSIconButton
                    color="info"
                    onClick={() =>
                      window.open(row.original.urlLinkedin, '_blank', 'noopener,noreferrer')
                    }
                  >
                    <Tooltip arrow placement="top" title="LinkedIn profile">
                      <LinkOutlined />
                    </Tooltip>
                  </COSIconButton>
                  <COSIconButton
                    color="info"
                    disabled={!row.original.legacyCandidateId}
                    onClick={() =>
                      window.open(
                        `https://cylinder.cadabra.bg/index.php?m=candidates&a=show&candidateID=${row.original.legacyCandidateId}`,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    <Tooltip arrow placement="top" title="Cylinder profile">
                      <AutoFixHighOutlined />
                    </Tooltip>
                  </COSIconButton>
                </Box>
              )
            }
          ]}
          renderBottomToolbarCustomActions={() => (
            <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
              <IconButton onClick={() => setPage(page > 0 ? page - 1 : 0)} disabled={page === 0}>
                <ArrowBackIosOutlined />
              </IconButton>
              <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
                <Typography align="center" variant="h4" component="h5">
                  {page + 1}
                </Typography>
              </Box>
              <IconButton
                onClick={() => setPage(page + 1)}
                disabled={candidates.length < apiFetchSize}
              >
                <ArrowForwardIosOutlined />
              </IconButton>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default withErrorHandler(CandidatesComponent, Axios);

import {
  DEFAULT_LABELS,
  DEFAULT_WEEKDAY_LABELS,
  MIN_DISTANCE_MONTH_LABELS,
  NAMESPACE,
  generateEmptyData,
  getClassName,
  getMonthLabels,
  getTheme,
  groupByWeeks
} from './util';
import { Day, EventHandlerMap, Labels, ReactEvent, SVGRectEventHandler, Theme } from './types';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import tinycolor, { ColorInput } from 'tinycolor2';

import type { Day as WeekDay } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import styles from './styles.css';

export type CalendarData = Day[];

export interface Props {
  /**
   * List of calendar entries. Every `Day` object requires an ISO 8601 `date`
   * property in the format `yyyy-MM-dd`, a `count` property with the amount
   * of tracked data and finally a `level` property in the range `0 - 4` to
   * specify activity intensity.
   *
   * Example object:
   *
   * ```json
   * {
   *   date: "2021-02-20",
   *   count: 16,
   *   level: 3
   * }
   * ```
   */
  data: CalendarData;
  /**
   * Margin between blocks in pixels.
   */
  blockMargin?: number;
  /**
   * Border radius of blocks in pixels.
   */
  blockRadius?: number;
  /**
   * Block size in pixels.
   */
  blockSize?: number;
  /**
   * Pass `<ReactTooltip html />` as child to show tooltips.
   */
  children?: ReactNode;
  /**
   * Base color to compute graph intensity hues (the darkest color). Any valid CSS color is accepted
   */
  color?: ColorInput;
  /**
   * A date-fns/format compatible date string used in tooltips.
   */
  dateFormat?: string;
  /**
   * Event handlers to register for the SVG `<rect>` elements that are used to render the calendar days. Handler signature: `event => data => void`
   */
  eventHandlers?: EventHandlerMap;
  /**
   * Font size for text in pixels.
   */
  fontSize?: number;
  /**
   * Toggle to hide color legend below calendar.
   */
  hideColorLegend?: boolean;
  /**
   * Toggle to hide month labels above calendar.
   */
  hideMonthLabels?: boolean;
  /**
   * Toggle to hide total count below calendar.
   */
  hideTotalCount?: boolean;
  /**
   * Localization strings for all calendar labels.
   *
   * - `totalCount` supports the placeholders `{{count}}` and `{{year}}`.
   * - `tooltip` supports the placeholders `{{count}}` and `{{date}}`.
   */
  labels?: Labels;
  /**
   * Toggle for loading state. `data` property will be ignored if set.
   */
  loading?: boolean;
  /**
   * Toggle to show weekday labels left to the calendar.
   */
  showWeekdayLabels?: boolean;
  /**
   * Style object to pass to component container.
   */
  style?: CSSProperties;
  /**
   * An object specifying all theme colors explicitly`.
   */
  theme?: Theme;
  /**
   * Index of day to be used as start of week. 0 represents Sunday.
   */
  weekStart?: WeekDay;
}

const COSActivityCalendar: FunctionComponent<Props> = ({
  data,
  blockMargin = 2,
  blockRadius = 2,
  blockSize = 24,
  children,
  color = undefined,
  dateFormat = 'dd/MM/yy',
  eventHandlers = {},
  fontSize = 18,
  hideColorLegend = false,
  hideMonthLabels = false,
  hideTotalCount = false,
  labels: labelsProp,
  loading = false,
  showWeekdayLabels = false,
  style = {},
  theme: themeProp,
  weekStart = 0 // Sunday
}: Props) => {
  if (loading) {
    data = generateEmptyData();
  }

  if (data.length === 0) {
    return null;
  }

  const weeks = groupByWeeks(data, weekStart);
  const totalCount = data.reduce((sum, day) => sum + (day.count ?? 0), 0);
  const year = format(parseISO(data[0]?.date), dateFormat);

  const theme = getTheme(themeProp, color);
  const labels = Object.assign({}, DEFAULT_LABELS, labelsProp);
  const textHeight = hideMonthLabels ? 0 : fontSize + 2 * blockMargin;

  const getDimensions = () => {
    return {
      width: weeks.length * (blockSize + blockMargin) - blockMargin,
      height: textHeight + (blockSize + blockMargin) * 7 - blockMargin
    };
  };

  const getTooltipMessage = (contribution: Day) => {
    const date = format(parseISO(contribution.date), dateFormat);
    const tooltip = labels.tooltip ?? DEFAULT_LABELS.tooltip;

    return tooltip
      .replaceAll('{{count}}', String(contribution.count ?? 0))
      .replaceAll('{{date}}', date);
  };

  const getEventHandlers = (data: Day): SVGRectEventHandler => {
    return (
      Object.keys(eventHandlers) as Array<keyof SVGRectEventHandler>
    ).reduce<SVGRectEventHandler>(
      (handlers, key) => ({
        ...handlers,
        [key]: (event: ReactEvent<SVGRectElement>) => eventHandlers[key]?.(event)(data)
      }),
      {}
    );
  };

  const renderLabels = () => {
    const style = {
      fontSize
    };

    if (!showWeekdayLabels && hideMonthLabels) {
      return null;
    }

    return (
      <>
        {showWeekdayLabels && (
          <g className={getClassName('legend-weekday')} style={style}>
            {weeks[0].map((day, index) => {
              if (index % 2 === 0) {
                return null;
              }

              const dayIndex = (index + weekStart) % 7;
              const days = labels.weekdays[dayIndex] ?? DEFAULT_WEEKDAY_LABELS[dayIndex];

              return (
                <text
                  x={-2 * blockMargin}
                  y={textHeight + (fontSize / 2 + blockMargin) + (blockSize + blockMargin) * index}
                  textAnchor="end"
                  key={index}
                >
                  {days}
                </text>
              );
            })}
          </g>
        )}
        {!hideMonthLabels && (
          <g className={getClassName('legend-month')} style={style}>
            {getMonthLabels(weeks, labels.months).map(({ text, x }, index, labels) => {
              // Skip the first month label if there's not enough space to the next one
              if (index === 0 && labels[1] && labels[1].x - x <= MIN_DISTANCE_MONTH_LABELS) {
                return null;
              }

              return (
                <text x={(blockSize + blockMargin) * x} alignmentBaseline="hanging" key={x}>
                  {text}
                </text>
              );
            })}
          </g>
        )}
      </>
    );
  };

  const renderBlocks = () => {
    return weeks
      .map((week, weekIndex) =>
        week.map((day, dayIndex) => {
          if (!day) {
            return null;
          }
          let style;
          if (!loading) {
            style = {
              animation: `${styles.loadingAnimation} 1.5s ease-in-out infinite`,
              animationDelay: `${weekIndex * 20 + dayIndex * 20}ms`
            };
          }

          return (
            <svg key={day.date}>
              <rect
                {...getEventHandlers(day)}
                x={0}
                y={textHeight + (blockSize + blockMargin) * dayIndex}
                width={blockSize}
                height={blockSize}
                fill={theme[`level${day.level ?? 0}` as keyof Theme]}
                rx={blockRadius}
                ry={blockRadius}
                className={styles.block}
                data-date={day.date}
                data-tip={children ? getTooltipMessage(day) : undefined}
                style={style}
              />
            </svg>
          );
        })
      )
      .map((week, x) => (
        <g key={x} transform={`translate(${(blockSize + blockMargin) * x}, 0)`}>
          {week}
        </g>
      ));
  };

  const renderFooter = () => {
    if (hideTotalCount && hideColorLegend) {
      return null;
    }
    const text =
      labels.totalCount
        ?.replace('{{count}}', String(totalCount))
        .replace('{{year}}', String(year)) ?? `${totalCount} since ${year}`;

    return (
      <footer
        className={getClassName('footer', styles.footer)}
        style={{ marginTop: blockMargin, fontSize }}
      >
        {!loading && !hideTotalCount && <div className={getClassName('count')}>{text}</div>}

        {!loading && !hideColorLegend && (
          <div className={getClassName('legend-colors', styles.legendColors)}>
            <span style={{ marginRight: '0.4em' }}>{labels?.legend?.less ?? 'Less'}</span>
            {Array(4)
              .fill(undefined)
              .map((_, index) => (
                <svg width={blockSize} height={blockSize} key={index}>
                  <rect
                    width={blockSize}
                    height={blockSize}
                    fill={theme[`level${index}` as keyof Theme]}
                    rx={blockRadius}
                    ry={blockRadius}
                  />
                </svg>
              ))}
            <span style={{ marginLeft: '0.4em' }}>{labels?.legend?.more ?? 'More'}</span>
          </div>
        )}
      </footer>
    );
  };

  const { width, height } = getDimensions();
  const additionalStyles = {
    maxWidth: width,
    // Required for correct colors in CSS loading animation
    [`--${NAMESPACE}-loading`]: theme.level0,
    [`--${NAMESPACE}-loading-active`]: tinycolor(theme.level0).darken(8).toString()
  };

  return (
    <article className={NAMESPACE} style={{ ...style, ...additionalStyles }}>
      <svg
        width={width}
        height={height - blockSize * 4}
        viewBox={`0 0 ${width * 2} ${height}`}
        className={getClassName('calendar', styles.calendar)}
      >
        {!loading && renderLabels()}
        {renderBlocks()}
      </svg>
      {renderFooter()}
      {children}
    </article>
  );
};

export const Skeleton: FunctionComponent<Omit<Props, 'data'>> = (props: any) => (
  <COSActivityCalendar data={[]} {...props} />
);

export default COSActivityCalendar;

import { Alert, AlertColor, Slide, SlideProps, Snackbar } from '@mui/material';

import React from 'react';

interface COSSnackBarProps {
  open: boolean;
  onClose: (open: boolean) => void;
  severity?: AlertColor | undefined;
  message?: string;
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const COSSnackBar = ({
  open,
  onClose,
  severity = 'success',
  message = 'Data saved successfully'
}: COSSnackBarProps) => {
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      TransitionComponent={SlideTransition}
      message={message}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default COSSnackBar;

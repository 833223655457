import { Grid, Typography } from '@mui/material';

import React from 'react';

const NotFoundPage = () => {
  return (
    <Grid container alignItems="center">
      <Grid item lg={12}>
        <Typography variant="h1" component="h1">
          404
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Typography variant="h5" component="h1">
          Page not found,
          <br /> please use the navigation menu
        </Typography>
      </Grid>
    </Grid>
  );
};
export default NotFoundPage;

import React, { useEffect, useState } from 'react';

import { AxiosInstance } from 'axios';
import COSErrorDialog from '../../ui/COSErrorDialog/COSErrorDialog';
import { logout } from '../../../firebase';

const defaultTitle =
  'An error occurred while executing a request. Please forward this information to technical support';

const withErrorHandler = (WrappedComponent: any, axios: AxiosInstance) => {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const [showDialog, setShowDialog] = useState(false);
    const [title, setTitle] = useState(defaultTitle);
    const [message, setMessage] = useState('');

    const hideAuthorizationHeader = (error: any) => ({
      message: error.message,
      name: error.name,
      response: {
        data: error.response?.data,
        status: error.response?.status,
        statusText: error.response?.statusText
      },
      config: {
        ...error.config,
        headers: { ...(error.config?.headers ?? []), Authorization: '<hidden>' }
      }
    });

    useEffect(() => {
      axios.interceptors.request.use((req) => {
        setShowDialog(false);
        return req;
      });
      axios.interceptors.response.use(
        (res) => res,
        (error) => {
          let showErrorDialog = true;
          let supportTitle = defaultTitle;
          const supportMessage = btoa(
            unescape(encodeURIComponent(JSON.stringify(hideAuthorizationHeader(error))))
          );
          switch (error.response?.status) {
            case 401:
              void logout();
              break;
            case 403:
            case 404:
            case 409:
              setShowDialog(true);
              supportTitle = `Error ${error.response.status} when validating the request to the server. Please forward this information to technical support`;
              break;
            case undefined:
              showErrorDialog = false;
              supportTitle = 'No connection to server';
              break;
            default:
          }
          console.log(
            `%c${supportTitle}\n\n%c${supportMessage}`,
            'color: #ff4d53; font-size: 18px',
            'color: #ff4d53; font-size: 14px'
          );
          if (showErrorDialog) {
            setTitle(supportTitle);
            setMessage(supportMessage);
          }
        }
      );
    }, [title]);

    return (
      <div>
        <COSErrorDialog
          open={showDialog}
          title={title}
          message={message}
          onClose={() => setShowDialog(false)}
        />
        <WrappedComponent {...props} />
      </div>
    );
  };
};

export default withErrorHandler;

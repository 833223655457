import {
  Add,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AutoFixHighOutlined,
  CheckBox,
  CheckBoxOutlineBlank
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  COSButton,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSRowActions,
  COSTable,
  COSTextField
} from '../../helpers/ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import { ClientSimpleResponse } from '../../services/api';
import { parseQuery } from '../../helpers/url';
import useClients from '../../helpers/hooks/useClients';
import { useNavigate } from 'react-router-dom';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const apiFetchSize = 25;

const ClientsComponent = () => {
  const { selectedPage } = parseQuery(location.search);
  const { clients, loading, setParameters } = useClients(undefined, false, apiFetchSize, 0);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(selectedPage ? +selectedPage : 0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setParameters([search, showInactive, apiFetchSize, page * apiFetchSize]);
  }, [showInactive]);

  const onEnter = useCallback(() => {
    setPage(0);
    setParameters([search, showInactive, apiFetchSize, 0]);
    navigate('/clients');
  }, [search, showInactive]);

  useEffect(() => {
    setParameters([search, showInactive, apiFetchSize, page * apiFetchSize]);
    navigate(`/clients?selectedPage=${page}`);
  }, [page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [clients]);

  const handleAdd = useCallback(() => navigate('/client'), [navigate]);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Clients
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <COSTextField
              label="Search"
              value={search}
              onChange={(value: string) => setSearch(value)}
              onEnter={onEnter}
            />
          </Grid>
          <Grid item lg={3} sx={{ marginTop: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                    color="primary"
                  />
                }
                label="Show Inactive"
              />
              <COSButton text="NEW" variant="outlined" onClick={handleAdd} startIcon={<Add />} />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <COSTable<ClientSimpleResponse>
              isLoading={loading}
              data={clients}
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: { maxHeight: '60vh', minHeight: '300px' }
              }}
              columns={[
                {
                  accessorKey: 'id',
                  header: '#',
                  size: 50,
                  Cell: ({ row }: COSCellProps<ClientSimpleResponse>) =>
                    row.index + page * apiFetchSize + 1
                },
                {
                  accessorKey: 'companyName',
                  header: 'Name',
                  size: 160,
                  Cell: ({ row }: COSCellProps<ClientSimpleResponse>) => (
                    <COSLink href={`/client?id=${row.original.id}`}>
                      {row.original.companyName}
                    </COSLink>
                  )
                },
                {
                  accessorKey: 'country',
                  header: 'Country',
                  size: 100
                },
                {
                  accessorKey: 'city',
                  header: 'City',
                  size: 100
                },
                {
                  accessorKey: 'invoiceName',
                  header: 'Invoice name',
                  size: 160
                },
                {
                  accessorKey: 'vatnumber',
                  header: 'Vat number',
                  size: 150
                },
                {
                  accessorKey: 'feePercentage',
                  header: 'Fee percentage (%)',
                  size: 100
                },
                {
                  accessorKey: 'warrantyDays',
                  header: 'Warranty days',
                  size: 100
                },
                {
                  accessorKey: 'id',
                  header: 'Actions',
                  size: 80,
                  Cell: ({ row }: COSRowActions<ClientSimpleResponse>) =>
                    row.original.legacyId ? (
                      <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <COSIconButton
                          color="info"
                          onClick={() =>
                            window.open(
                              `https://cylinder.cadabra.bg/index.php?m=companies&a=show&companyID=${row.original.legacyId}`,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }
                        >
                          <Tooltip arrow placement="top" title="Cylinder profile">
                            <AutoFixHighOutlined />
                          </Tooltip>
                        </COSIconButton>
                      </Box>
                    ) : (
                      <Box></Box>
                    )
                }
              ]}
              renderBottomToolbarCustomActions={() => (
                <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                  <IconButton
                    onClick={() => setPage(page > 0 ? page - 1 : 0)}
                    disabled={page === 0}
                  >
                    <ArrowBackIosOutlined />
                  </IconButton>
                  <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
                    <Typography align="center" variant="h4" component="h5">
                      {page + 1}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => setPage(page + 1)}
                    disabled={clients.length < apiFetchSize}
                  >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(ClientsComponent, Axios);

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (id: number, name: string, sx: any) => {
  const clearName = name
    .replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '')
    .trim();
  return {
    sx: {
      ...sx,
      bgcolor: stringToColor(`${id} ${clearName}`)
    },
    children: clearName.includes(' ')
      ? `${clearName.split(' ')[0][0]}${clearName.split(' ')[1][0]}`
      : clearName[0]
  };
};

export { default as COSActivityCalendar } from './COSActivityCalendar/COSActivityCalendar';
export * from './COSActivityCalendar/COSActivityCalendar';

export { default as COSAsyncAutocomplete } from './COSAsyncAutocomplete/COSAsyncAutocomplete';
export * from './COSAsyncAutocomplete/COSAsyncAutocomplete';

export { default as COSAsyncMultipleAutocomplete } from './COSAsyncMultipleAutocomplete/COSAsyncMultipleAutocomplete';
export * from './COSAsyncMultipleAutocomplete/COSAsyncMultipleAutocomplete';

export { default as COSAutocomplete } from './COSAutocomplete/COSAutocomplete';
export * from './COSAutocomplete/COSAutocomplete';

export { default as COSUserAvatar } from './COSUserAvatar/COSUserAvatar';
export * from './COSUserAvatar/COSUserAvatar';

export { default as COSButton } from './COSButton/COSButton';
export * from './COSButton/COSButton';

export { default as COSCandidateAvatar } from './COSCandidateAvatar/COSCandidateAvatar';
export * from './COSCandidateAvatar/COSCandidateAvatar';

export { default as COSCountryAutocomplete } from './COSCountryAutocomplete/COSCountryAutocomplete';
export * from './COSCountryAutocomplete/COSCountryAutocomplete';

export { default as COSErrorDialog } from './COSErrorDialog/COSErrorDialog';
export * from './COSErrorDialog/COSErrorDialog';

export { default as COSGaugeChart } from './COSGaugeChart/COSGaugeChart';
export * from './COSGaugeChart/COSGaugeChart';

export { default as COSIconButton } from './COSIconButton/COSIconButton';
export * from './COSIconButton/COSIconButton';

export { default as COSLink } from './COSLink/COSLink';
export * from './COSLink/COSLink';

export { default as COSOutlinedDiv } from './COSOutlinedDiv/COSOutlinedDiv';
export * from './COSOutlinedDiv/COSOutlinedDiv';

export { default as COSSnackBar } from './COSSnackBar/COSSnackBar';
export * from './COSSnackBar/COSSnackBar';

export { default as COSStatsChart } from './COSStatsChart/COSStatsChart';
export * from './COSStatsChart/COSStatsChart';

export { default as COSTable } from './COSTable/COSTable';
export * from './COSTable/COSTable';

export { default as COSTabPanel } from './COSTabPanel/COSTabPanel';
export * from './COSTabPanel/COSTabPanel';

export { default as COSTextField } from './COSTextField/COSTextField';
export * from './COSTextField/COSTextField';

export { default as COSViewOnlyTextField } from './COSViewOnlyTextField/COSViewOnlyTextField';
export * from './COSViewOnlyTextField/COSViewOnlyTextField';

export type { default as Lookup } from '../../models/lookup';

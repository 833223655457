import { COSButton, COSCellProps, COSTable } from '../../../helpers/ui';
import { CandidateHistoryResponse, CreateCandidateHistoryRequest } from '../../../services/api';
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import CandidateWorkHistoryDialog from './CandidateWorkHistoryDialog';
import { cadabraService } from '../../../services/services';
import moment from 'moment';

interface CandidateWorkHistoryComponentProps {
  candidateId: number;
}

const CandidateWorkHistoryComponent = ({ candidateId }: CandidateWorkHistoryComponentProps) => {
  const [candidateHistory, setCandidateHistory] = useState<CandidateHistoryResponse[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  useEffect(() => {
    const loadData = async () => {
      if (candidateId) {
        const historyResp = await cadabraService.getCandidateHistory(candidateId);
        setCandidateHistory(historyResp.data);
      }
    };
    loadData();
  }, [candidateId]);

  return (
    <Box sx={{ marginBottom: 3 }}>
      <COSButton text="ADD WORK ENTRY" onClick={() => setOpenDialog(true)} />
      <COSTable<CandidateHistoryResponse>
        isLoading={false}
        data={candidateHistory}
        enablePagination={false}
        enableRowNumbers={true}
        columns={[
          {
            accessorKey: 'companyName',
            header: 'Company',
            size: 150
          },
          {
            accessorKey: 'positionName',
            header: 'Position',
            size: 150
          },
          {
            accessorKey: 'startDate',
            header: 'Start date',
            size: 150,
            Cell: ({ row }: COSCellProps<CandidateHistoryResponse>) =>
              moment(row.original.startDate).format('MMM-YY'),
            sortingFn: (row1, row2) =>
              moment(row1.original.startDate).isBefore(moment(row2.original.startDate)) ? -1 : 1
          },
          {
            accessorKey: 'endDate',
            header: 'End date',
            size: 150,
            Cell: ({ row }: COSCellProps<CandidateHistoryResponse>) =>
              row.original.endDate ? moment(row.original.endDate).format('MMM-YY') : '-',
            sortingFn: (row1, row2) =>
              moment(row1.original.endDate).isBefore(moment(row2.original.endDate)) ? -1 : 1
          }
        ]}
      />
      <CandidateWorkHistoryDialog
        candidateId={candidateId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={async (candidateHistoryRequest: CreateCandidateHistoryRequest) => {
          const resp = await cadabraService.createCandidateHistory(candidateHistoryRequest);
          if (resp) {
            setCandidateHistory([resp.data, ...candidateHistory]);
            setOpenDialog(false);
          }
        }}
      />
    </Box>
  );
};

export default CandidateWorkHistoryComponent;

import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AutoFixHighOutlined,
  LinkOutlined
} from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import {
  COSCandidateAvatar,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSRowActions,
  COSTable
} from '../../../../helpers/ui';
import React, { useEffect, useRef, useState } from 'react';
import { useProject, useProjectCandidateSuggestions, useQuery } from '../../../../helpers/hooks';

import { CandidateResponse } from '../../../../services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const apiFetchSize = 50;

interface ProjectCandidateSuggetionsComponentProps {
  projectId: number;
}

const ProjectCandidateSuggetionsComponent = ({
  projectId
}: ProjectCandidateSuggetionsComponentProps) => {
  const query = useQuery();
  const { candidates, loading, setParameters } = useProjectCandidateSuggestions(
    projectId,
    0,
    apiFetchSize
  );
  const { project } = useProject(projectId);
  const [page, setPage] = useState<number>(query ? +(query.get('selectedPage') ?? 0) : 0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setParameters([projectId, page * apiFetchSize, apiFetchSize]);
    navigate(`/project?id=${projectId}&tabId=3&selectedPage=${page}`);
  }, [projectId, page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [candidates]);

  return (
    <COSTable<CandidateResponse>
      isLoading={loading}
      data={candidates}
      enablePagination={false}
      enableStickyHeader={true}
      muiTableContainerProps={{
        ref: tableContainerRef,
        sx: { maxHeight: '60vh', minHeight: '300px' }
      }}
      columns={[
        {
          accessorKey: 'id',
          header: '#',
          size: 50,
          Cell: ({ row }: COSCellProps<CandidateResponse>) => row.index + page * apiFetchSize + 1
        },
        {
          accessorKey: 'name',
          header: 'Candidate',
          size: 240,
          Cell: ({ row }: COSCellProps<CandidateResponse>) => (
            <COSLink href={`/candidate?id=${row.original.id}`}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ my: 'auto', maxWidth: 60 }}>
                  <COSCandidateAvatar
                    id={row.original.id}
                    name={row.original.name}
                    imagePath={row.original.imagePath}
                    sx={{ width: 60, height: 60, fontSize: 30, borderRadius: 15 }}
                  />
                </Box>
                <Box sx={{ p: 1.5 }}>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: 'rgba(0, 0, 0, 0.95) !important',
                      pb: 0.75
                    }}
                  >
                    {row.original.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'rgba(0, 0, 0, 0.67)', fontSize: 14, lineHeight: 1 }}
                  >
                    {row.original.headline}
                  </Typography>
                </Box>
              </Box>
            </COSLink>
          )
        },
        {
          accessorKey: 'email',
          header: 'Contact',
          size: 200,
          Cell: ({ row }: COSCellProps<CandidateResponse>) => (
            <Box sx={{ display: 'grid' }}>
              <Tooltip title={`Send e-email to ${row.original.email}`}>
                <COSLink href={`mailto:${row.original.email}`}>{row.original.email}</COSLink>
              </Tooltip>
              <Tooltip title={`Call ${row.original.phoneNumber}`}>
                <COSLink href={`tel:${row.original.phoneNumber}`}>
                  {row.original.phoneNumber}
                </COSLink>
              </Tooltip>
            </Box>
          )
        },
        {
          accessorKey: 'technologies',
          header: 'Technologies',
          size: 300,
          Cell: ({ row }: COSCellProps<CandidateResponse>) => {
            const matchingTechnologies = project?.technologies.map((t) => t.name) ?? [];
            const additionalTechnologies = row.original.technologies
              .filter((t) => project?.technologies.findIndex((tech) => tech.id === t.id) === -1)
              .map((t) => t.name);
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem'
                }}
              >
                <Tooltip
                  title={[...matchingTechnologies, ...additionalTechnologies].join(',')}
                  arrow
                  placement="right"
                >
                  <Typography>
                    <b>{matchingTechnologies.join(', ')}</b>
                    {matchingTechnologies.length > 0 ? ', ' : ''}
                    {additionalTechnologies.join(', ').slice(0, 100)}
                  </Typography>
                </Tooltip>
              </Box>
            );
          }
        },
        {
          accessorKey: 'lastContactedDate',
          header: 'Last contacted',
          size: 120,
          Cell: ({ row }: COSCellProps<CandidateResponse>) => {
            const value = row.original.lastContactedDate
              ? moment(row.original.lastContactedDate).format('DD/MM/YY')
              : '-';
            return <Typography>{value}</Typography>;
          },
          muiTableHeadCellFilterTextFieldProps: {
            type: 'date'
          },
          sortingFn: 'datetime'
        },
        {
          accessorKey: 'id',
          header: 'Actions',
          size: 80,
          Cell: ({ row }: COSRowActions<CandidateResponse>) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <COSIconButton
                color="info"
                onClick={() =>
                  window.open(row.original.urlLinkedin, '_blank', 'noopener,noreferrer')
                }
              >
                <Tooltip arrow placement="top" title="LinkedIn profile">
                  <LinkOutlined />
                </Tooltip>
              </COSIconButton>
              <COSIconButton
                color="info"
                onClick={() =>
                  window.open(
                    `https://cylinder.cadabra.bg/index.php?m=candidates&a=show&candidateID=${row.original.legacyCandidateId}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                }
              >
                <Tooltip arrow placement="top" title="Cylinder profile">
                  <AutoFixHighOutlined />
                </Tooltip>
              </COSIconButton>
            </Box>
          )
        }
      ]}
      renderBottomToolbarCustomActions={() => (
        <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
          <IconButton onClick={() => setPage(page > 0 ? page - 1 : 0)} disabled={page === 0}>
            <ArrowBackIosOutlined />
          </IconButton>
          <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
            <Typography align="center" variant="h4" component="h5">
              {page + 1}
            </Typography>
          </Box>
          <IconButton onClick={() => setPage(page + 1)} disabled={candidates.length < apiFetchSize}>
            <ArrowForwardIosOutlined />
          </IconButton>
        </Box>
      )}
    />
  );
};

export default ProjectCandidateSuggetionsComponent;

import { AutoFixHighOutlined, LinkOutlined } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Container,
  Grid,
  InputAdornment,
  Paper,
  SxProps,
  Theme,
  Tooltip
} from '@mui/material';
import {
  COSAsyncMultipleAutocomplete,
  COSButton,
  COSCandidateAvatar,
  COSIconButton,
  COSLink,
  COSOutlinedDiv,
  COSTextField,
  COSViewOnlyTextField,
  Lookup
} from '../../../helpers/ui';
import {
  CandidateUserRelationResponse,
  CityResponse,
  CreateCandidateRequest,
  PatchCandidateRequest,
  TechnologyResponse
} from '../../../services/api';
import {
  InteractionTypeId,
  RelationTypeKey,
  getRelationTypesByKey,
  userRelationRank
} from '../../../services/cadabraService';
import React, { useEffect, useState } from 'react';
import { cadabraService, snackbarService } from '../../../services/services';
import moment, { Moment } from 'moment';
import { useCandidateImage, useEventLogs } from '../../../helpers/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import Axios from 'axios';
import CandidateTasksComponent from '../CandidateTasksComponent/CandidateTasksComponent';
import CandidateWorkHistoryComponent from '.././CandidateWorkHistory/CandidateWorkHistoryComponent';
import LinkIcon from '@mui/icons-material/Link';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Star from '@mui/icons-material/Star';
import { parseQuery } from '../../../helpers/url';
import { useDebounce } from '@react-hook/debounce';
import useTechnologies from '../../../helpers/hooks/useTechnologies';
import withErrorHandler from '../../../helpers/hoc/withErrorHandler/withErrorHandler';

const iconStyle: SxProps<Theme> = {
  fontSize: 16
};

const userRelationIcon = {
  [RelationTypeKey.FIRST_CONNECTION]: <LinkIcon sx={iconStyle} />,
  [RelationTypeKey.HAS_EMAIL_PERMISSION]: <MailOutlineIcon sx={iconStyle} />,
  [RelationTypeKey.HAS_PHONE_PERMISSION]: <LocalPhoneIcon sx={iconStyle} />,
  [RelationTypeKey.PERSONAL_FRIEND]: <Star sx={iconStyle} />
};

interface CandidateComponentProps {
  userId: number;
}

const CandidateComponent = ({ userId }: CandidateComponentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = parseQuery(location.search);

  const [name, setName] = useState<string>('');
  const [headline, setHeadline] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [urlLinkedin, setUrlLinkedin] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [legacyId, setLegacyId] = useState<number>();
  const [cities, setCities] = useState<CityResponse[]>([]);
  const [citiesValue, setCitiesValue] = useState<Lookup[]>([]);
  const [citiesFilter, setCitiesFilter] = useDebounce<string>('', 250);
  const generalTechnologies = useTechnologies();
  const [techsValue, setTechsValue] = useState<Lookup[]>([]);
  const [techsFilter, setTechsFilter] = useDebounce<string>('', 250);
  const desiredTechnologies = useTechnologies();
  const [desiredTechsValue, setDesiredTechsValue] = useState<Lookup[]>([]);
  const [desiredTechsFilter, setDesiredTechsFilter] = useDebounce<string>('', 250);
  const mainTechnologies = useTechnologies();
  const [mainTechsValue, setMainTechsValue] = useState<Lookup[]>([]);
  const [mainTechsFilter, setMainTechsFilter] = useDebounce<string>('', 250);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const candidateImage = useCandidateImage();
  const [startDate] = useState<Moment>(moment().subtract(6, 'months'));
  const [endDate] = useState<Moment>(moment().add(1, 'days'));
  const { eventLogs } = useEventLogs(
    [id],
    [],
    [],
    [InteractionTypeId.PLACED],
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    1,
    0
  );
  const [candidateUserRelation, setCandidateUserRelation] = useState<any>({});
  const [candidateUserRelationRank, setCandidateUserRelationRank] = useState<any>({});
  useEffect(() => {
    const loadData = async () => {
      if (id) {
        const candidateUserRelationsResp = await cadabraService.getCandidateUserRelation(id);
        setCandidateUserRelation(
          candidateUserRelationsResp.data?.reduce(
            (res: any, relation: CandidateUserRelationResponse) => {
              if (!res[relation.userId]) {
                res[relation.userId] = relation.userName;
              }
              res[relation.userId] = `${res[relation.userId]}, ${
                getRelationTypesByKey(relation.relationType as RelationTypeKey).label
              }`;
              return res;
            },
            {}
          )
        );
        setCandidateUserRelationRank(
          candidateUserRelationsResp.data?.reduce(
            (res: any, relation: CandidateUserRelationResponse) => {
              if (!res[relation.userId]) {
                res[relation.userId] = relation.relationType;
              }
              if (
                userRelationRank[relation.relationType as RelationTypeKey] >
                userRelationRank[res[relation.userId] as RelationTypeKey]
              ) {
                res[relation.userId] = relation.relationType;
              }
              return res;
            },
            {}
          )
        );
      }
    };
    loadData();
  }, [id]);

  useEffect(() => {
    generalTechnologies.setParameters([techsFilter]);
  }, [techsFilter]);

  useEffect(() => {
    desiredTechnologies.setParameters([desiredTechsFilter]);
  }, [desiredTechsFilter]);

  useEffect(() => {
    mainTechnologies.setParameters([mainTechsFilter]);
  }, [mainTechsFilter]);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await cadabraService.getCities(citiesFilter);
      setCities(citiesData);
      setLoading(false);
    };
    fetchCities().catch(console.error);
    setLoading(true);
  }, [citiesFilter]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const candidateResp = await cadabraService.getCandidate(id);
        const candidate = candidateResp.data;
        setName(candidate.name);
        setHeadline(candidate.headline ?? '');
        setEmail(candidate.email ?? '');
        setPhoneNumber(candidate.phoneNumber ?? '');
        setUrlLinkedin(candidate.urlLinkedin ?? '');
        setComment(candidate.comment ?? '');
        setTechsValue(
          candidate.technologies.map((technology: TechnologyResponse) => ({
            id: technology.id,
            value: technology.name
          }))
        );
        setDesiredTechsValue(
          candidate.desiredTechnologies.map((technology: TechnologyResponse) => ({
            id: technology.id,
            value: technology.name
          }))
        );
        setMainTechsValue(
          candidate.mainTechnologies.map((technology: TechnologyResponse) => ({
            id: technology.id,
            value: technology.name
          }))
        );
        setCitiesValue(
          candidate.cities.map((city: CityResponse) => ({
            id: city.id,
            value: city.name
          }))
        );
        setLegacyId(candidate.legacyCandidateId);
        candidateImage.setImagePath(candidate.imagePath);
      } else {
        setName('');
        setHeadline('');
        setEmail('');
        setPhoneNumber('');
        setUrlLinkedin('');
        setComment('');
        setTechsValue([]);
        setDesiredTechsValue([]);
        setMainTechsValue([]);
        setCitiesValue([]);
        candidateImage.setImagePath(undefined);
      }
    };
    fetchData().catch(console.error);
  }, [id]);
  const handleOnClick = async () => {
    setLoadingAction(true);
    if (id) {
      const patchRequest: PatchCandidateRequest = {
        name,
        headline,
        email,
        phoneNumber,
        urlLinkedin,
        comment,
        technologyIds: techsValue.map((value: Lookup) => value.id),
        desiredTechnologyIds: desiredTechsValue.map((value: Lookup) => value.id),
        mainTechnologyIds: mainTechsValue.map((value: Lookup) => value.id),
        cityIds: citiesValue.map((value: Lookup) => value.id)
      };
      await cadabraService.patchCandidate(id, patchRequest);
      snackbarService.setSnackbarOpen(true, 'Saved!', 'success');
    } else {
      const createRequest: CreateCandidateRequest = {
        name,
        headline,
        email,
        phoneNumber,
        urlLinkedin,
        comment,
        technologyIds: techsValue.map((value: Lookup) => value.id),
        desiredTechnologyIds: desiredTechsValue.map((value: Lookup) => value.id),
        mainTechnologyIds: mainTechsValue.map((value: Lookup) => value.id),
        cityIds: citiesValue.map((value: Lookup) => value.id)
      };
      const resp = await cadabraService.createCandidate(createRequest);
      if (resp) {
        navigate(`/candidate?id=${resp.data.id}`);
        snackbarService.setSnackbarOpen(true, 'Saved!', 'success');
      }
    }
    setLoadingAction(false);
  };

  return (
    <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Container maxWidth="md">
          <Paper elevation={2}>
            <COSOutlinedDiv label={id ? name : 'New candidate'}>
              <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
                <Grid container item lg={4} md={12} sm={12} xs={12} justifyContent="center">
                  {candidateImage.loading ? (
                    <CircularProgress />
                  ) : candidateImage.data ? (
                    <Box
                      component="img"
                      sx={{
                        margin: 1,
                        width: 150,
                        height: 150,
                        borderRadius: 75,
                        border: id && eventLogs?.length > 0 ? '5px solid #3EB489' : undefined
                      }}
                      src={candidateImage.data}
                    />
                  ) : (
                    <COSCandidateAvatar
                      id={id}
                      name={name}
                      sx={{
                        margin: 1,
                        width: 150,
                        height: 150,
                        borderRadius: 75,
                        fontSize: 48,
                        border: id && eventLogs?.length > 0 ? '5px solid #3EB489' : undefined
                      }}
                    />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: -20
                    }}
                  >
                    {Object.keys(candidateUserRelation).map((userId) => (
                      <COSLink key={userId} href={`/user?id=${userId}`}>
                        <Tooltip title={candidateUserRelation[userId]}>
                          <Badge
                            sx={{ marginRight: 0.5 }}
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            color="secondary"
                            badgeContent={
                              userRelationIcon[candidateUserRelationRank[userId] as RelationTypeKey]
                            }
                          >
                            <Avatar
                              src={`https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/users/${userId}.jpeg`}
                              sx={{ width: 36, height: 36, fontSize: 18 }}
                            />
                          </Badge>
                        </Tooltip>
                      </COSLink>
                    ))}
                  </div>
                </Grid>
                <Grid item lg={8} md={12} sm={12} xs={12}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {!legacyId && <COSViewOnlyTextField label="ID" value={id} />}
                    {legacyId && (
                      <COSViewOnlyTextField
                        label="ID"
                        value={`${id} (${legacyId})`}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <COSIconButton
                                color="info"
                                onClick={() =>
                                  window.open(
                                    `https://cylinder.cadabra.bg/index.php?m=candidates&a=show&candidateID=${legacyId}`,
                                    '_blank',
                                    'noopener,noreferrer'
                                  )
                                }
                              >
                                <Tooltip arrow placement="top" title="Cylinder profile">
                                  <AutoFixHighOutlined />
                                </Tooltip>
                              </COSIconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSTextField label="Name" value={name} onChange={(value) => setName(value)} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSTextField
                      label="Headline"
                      value={headline}
                      onChange={(value) => setHeadline(value)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSTextField
                      label="Email"
                      value={email}
                      onChange={(value) => setEmail(value)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSTextField
                      label="Phone"
                      value={phoneNumber}
                      onChange={(value) => setPhoneNumber(value)}
                    />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="URL LinkedIn"
                    value={urlLinkedin}
                    onChange={(value) => setUrlLinkedin(value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <COSIconButton
                            color="info"
                            onClick={() =>
                              window.open(urlLinkedin, '_blank', 'noopener,noreferrer')
                            }
                          >
                            <Tooltip arrow placement="top" title="LinkedIn profile">
                              <LinkOutlined />
                            </Tooltip>
                          </COSIconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Main technologies"
                    value={mainTechsValue}
                    options={mainTechnologies.technologies.map(
                      (technology: TechnologyResponse) => ({
                        id: technology.id,
                        value: technology.name
                      })
                    )}
                    loading={loading}
                    limitTags={7}
                    onChange={(value: Lookup[]) => setMainTechsValue(value)}
                    onEnter={async (option) => {
                      setMainTechsFilter('');
                      if (option.id > 0 && !mainTechsValue.includes(option)) {
                        setMainTechsValue([...mainTechsValue, option]);
                        return;
                      }
                      if (option.id > 0 && mainTechsValue.includes(option)) {
                        setMainTechsValue(mainTechsValue.filter((o) => o.id !== option.id));
                        return;
                      }
                      const technologyResp = await cadabraService.createTechnology({
                        name: option.value
                      });
                      mainTechnologies.setTechnologies([
                        ...mainTechnologies.technologies,
                        technologyResp.data
                      ]);
                      setMainTechsValue([
                        ...mainTechsValue,
                        {
                          id: technologyResp.data.id,
                          value: technologyResp.data.name
                        }
                      ]);
                    }}
                    minChar={0}
                    filter={mainTechsFilter}
                    onChangeFilter={(mainTechsFilter: string) =>
                      setMainTechsFilter(mainTechsFilter)
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Desired technologies"
                    value={desiredTechsValue}
                    options={desiredTechnologies.technologies.map(
                      (technology: TechnologyResponse) => ({
                        id: technology.id,
                        value: technology.name
                      })
                    )}
                    loading={loading}
                    limitTags={7}
                    onChange={(value: Lookup[]) => setDesiredTechsValue(value)}
                    onEnter={async (option) => {
                      setDesiredTechsFilter('');
                      if (option.id > 0 && !desiredTechsValue.includes(option)) {
                        setDesiredTechsValue([...desiredTechsValue, option]);
                        return;
                      }
                      if (option.id > 0 && desiredTechsValue.includes(option)) {
                        setDesiredTechsValue(desiredTechsValue.filter((o) => o.id !== option.id));
                        return;
                      }
                      const technologyResp = await cadabraService.createTechnology({
                        name: option.value
                      });
                      desiredTechnologies.setTechnologies([
                        ...desiredTechnologies.technologies,
                        technologyResp.data
                      ]);
                      setDesiredTechsValue([
                        ...desiredTechsValue,
                        {
                          id: technologyResp.data.id,
                          value: technologyResp.data.name
                        }
                      ]);
                    }}
                    minChar={0}
                    filter={desiredTechsFilter}
                    onChangeFilter={(desiredTechsFilter: string) =>
                      setDesiredTechsFilter(desiredTechsFilter)
                    }
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Technologies"
                    value={techsValue}
                    options={generalTechnologies.technologies.map(
                      (technology: TechnologyResponse) => ({
                        id: technology.id,
                        value: technology.name
                      })
                    )}
                    loading={loading}
                    limitTags={7}
                    onChange={(value: Lookup[]) => setTechsValue(value)}
                    onEnter={async (option) => {
                      setTechsFilter('');
                      if (option.id > 0 && !techsValue.includes(option)) {
                        setTechsValue([...techsValue, option]);
                        return;
                      }
                      if (option.id > 0 && techsValue.includes(option)) {
                        setTechsValue(techsValue.filter((o) => o.id !== option.id));
                        return;
                      }
                      const technologyResp = await cadabraService.createTechnology({
                        name: option.value
                      });
                      generalTechnologies.setTechnologies([
                        ...generalTechnologies.technologies,
                        technologyResp.data
                      ]);
                      setTechsValue([
                        ...techsValue,
                        {
                          id: technologyResp.data.id,
                          value: technologyResp.data.name
                        }
                      ]);
                    }}
                    minChar={0}
                    filter={techsFilter}
                    onChangeFilter={(techsFilter: string) => setTechsFilter(techsFilter)}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Cities"
                    value={citiesValue}
                    options={cities.map((city: CityResponse) => ({
                      id: city.id,
                      value: city.name
                    }))}
                    loading={loading}
                    canAddNew={true}
                    onChange={(value: Lookup[]) => setCitiesValue(value)}
                    onEnter={async (option) => {
                      setCitiesFilter('');
                      if (option.id > 0 && !citiesValue.includes(option)) {
                        setCitiesValue([...citiesValue, option]);
                        return;
                      }
                      if (option.id > 0 && citiesValue.includes(option)) {
                        setCitiesValue(citiesValue.filter((o) => o.id !== option.id));
                        return;
                      }
                      const cityResp = await cadabraService.createCity({
                        name: option.value
                      });
                      setCities([...cities, cityResp.data]);
                      setCitiesValue([
                        ...citiesValue,
                        {
                          id: cityResp.data.id,
                          value: cityResp.data.name
                        }
                      ]);
                    }}
                    minChar={0}
                    filter={citiesFilter}
                    onChangeFilter={(citiesFilter: string) => setCitiesFilter(citiesFilter)}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Comment"
                    value={comment}
                    onChange={(value) => setComment(value)}
                    multiline={true}
                    rows={4}
                  />
                </Grid>
                <Grid container item lg={12} justifyContent="center">
                  <COSButton
                    text="Save"
                    variant="contained"
                    sx={{
                      width: '20%'
                    }}
                    onClick={handleOnClick}
                    disabled={loadingAction}
                    loading={loadingAction}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </COSOutlinedDiv>
          </Paper>
        </Container>
      </Grid>
      {id && (
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <CandidateWorkHistoryComponent candidateId={id} />
          <CandidateTasksComponent candidateId={id} />
        </Grid>
      )}
    </Grid>
  );
};

export default withErrorHandler(CandidateComponent, Axios);

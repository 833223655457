import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Checkbox,
  Chip,
  FilterOptionsState,
  ListItem,
  TextField
} from '@mui/material';
import React, { useMemo } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Lookup } from '..';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface COSAsyncMultipleAutocompleteProps<T extends Lookup> {
  label?: string;
  options: T[];
  value: T[];
  loading: boolean;
  onChange: (option: T[]) => void;
  filter?: string;
  onChangeFilter?: (filter: string) => void;
  onEnter?: (option: T) => void;
  canAddNew?: boolean;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  limitTags?: number;
  getOptionLabel?: (option: any) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const COSAsyncMultipleAutocomplete = <T extends Lookup>({
  label = '',
  options = [],
  value = [],
  filter,
  loading = false,
  onChange,
  onChangeFilter,
  onEnter,
  canAddNew = false,
  disabled = false,
  required = false,
  minChar = 3,
  limitTags = 3,
  getOptionLabel = (option: T) => `${option.value}`,
  renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => {
    return option.id > 0 ? (
      <ListItem {...props}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={state.selected} color="primary" />
        {option.value}
      </ListItem>
    ) : (
      <ListItem {...props}>{`Adding '${option.value}'`}</ListItem>
    );
  },
  renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      margin="dense"
      variant="outlined"
      placeholder={minChar > 0 ? `Minimum ${minChar} characters` : ''}
      fullWidth
      size="small"
      required={required}
      disabled={disabled}
      onChange={(event) => {
        if (onChangeFilter != null && event.target.value.length >= minChar) {
          onChangeFilter(event.target.value);
        }
      }}
      // onKeyDown={(e) => {
      //   if (e.code !== 'Enter') return;
      //   onEnter?.(
      //     options.find((option) => option.value === filter) ??
      //       // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      //       ({ id: 0, value: filter ?? '' } as T)
      //   );
      // }}
    />
  )
}: COSAsyncMultipleAutocompleteProps<T>) => {
  const hasExactMatch = useMemo(
    () => options.findIndex((option) => option.value === filter) >= 0,
    [options, filter]
  );
  const opts = useMemo(() => {
    if (hasExactMatch || !canAddNew || filter === '') {
      return options;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return [{ id: 0, value: filter ?? '' } as T, ...options];
  }, [hasExactMatch, canAddNew, options, filter]);
  return (
    <Autocomplete<T, true, false, false>
      options={opts}
      loading={loading}
      value={value}
      filterOptions={(opts: T[], state: FilterOptionsState<T>) => opts}
      onChange={(
        event: any,
        options: T[],
        reason: string,
        details: AutocompleteChangeDetails<T> | undefined
      ) => {
        if (details?.option.id === 0) {
          onEnter?.(details?.option);
        }
        onChange(options.filter((option: Lookup) => option.id > 0));
      }}
      getOptionLabel={getOptionLabel}
      fullWidth
      disabled={disabled}
      renderOption={renderOption}
      renderInput={renderInput}
      renderTags={(items, getTagProps, ownerState) =>
        items.map((item) => (
          <Chip
            key={item.id}
            label={item.value}
            size="small"
            variant={item.id > 0 ? 'filled' : 'outlined'}
            onDelete={
              !disabled
                ? () => {
                    onChange(items.filter((i: Lookup) => i.id !== item.id));
                  }
                : undefined
            }
            sx={{ margin: 0.25 }}
          />
        ))
      }
      isOptionEqualToValue={(option: T, value: any) => {
        return option.id === value.id;
      }}
      noOptionsText="No results were found"
      loadingText="Loading..."
      limitTags={limitTags}
      multiple
      size="small"
      disableCloseOnSelect
    />
  );
};

export default COSAsyncMultipleAutocomplete;

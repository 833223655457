import { COSCandidateAvatar, COSViewOnlyTextField } from '../../helpers/ui';

import { CandidateResponse } from '../../services/api';
import { Grid } from '@mui/material';
import React from 'react';

interface ViewCandidateComponentProps {
  candidate: CandidateResponse;
}

const ViewCandidateComponent = ({ candidate }: ViewCandidateComponentProps) => {
  return (
    <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
      <Grid item lg={4} md={12} sx={{ p: 2 }}>
        <COSCandidateAvatar
          id={candidate.id}
          name={candidate.name}
          imagePath={candidate.imagePath}
          sx={{
            width: '100%',
            height: '100%',
            fontSize: '4.5rem'
          }}
        />
      </Grid>
      <Grid item lg={8} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <COSViewOnlyTextField label="Name" value={candidate.name} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <COSViewOnlyTextField label="Headline" value={candidate.headline ?? ''} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <COSViewOnlyTextField label="Email" value={candidate.email ?? ''} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <COSViewOnlyTextField label="Phone" value={candidate.phoneNumber ?? ''} />
        </Grid>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField label="URL LinkedIn" value={candidate.urlLinkedin ?? ''} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField
          label="Main technologies"
          value={candidate.mainTechnologies.map((c) => c.name).join(',')}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField
          label="Desired technologies"
          value={candidate.desiredTechnologies.map((c) => c.name).join(',')}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField
          label="Technologies"
          value={candidate.technologies.map((c) => c.name).join(',')}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField
          label="Cities"
          value={candidate.cities.map((c) => c.name).join(',')}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <COSViewOnlyTextField
          label="Comment"
          value={candidate.comment ?? ''}
          multiline={true}
          rows={4}
        />
      </Grid>
    </Grid>
  );
};

export default ViewCandidateComponent;

import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  ListItem,
  TextField
} from '@mui/material';
import React, { SyntheticEvent } from 'react';

import { Lookup } from '..';

interface COSAutocompleteProps<T extends Lookup> {
  label?: string;
  options: T[];
  value: T | null;
  loading: boolean;
  onChange: (option: T | null) => void;
  canAddNew?: boolean;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  limitTags?: number;
  getOptionLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const COSAutocomplete = <T extends Lookup>({
  label = '',
  options = [],
  value = null,
  loading = false,
  onChange,
  canAddNew = false,
  disabled = false,
  required = false,
  minChar = 3,
  getOptionLabel = (option: T) => `${option.value}`,
  renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => {
    return <ListItem {...props}>{option.value}</ListItem>;
  },
  renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      margin="dense"
      variant="outlined"
      placeholder={minChar > 0 ? `Minimum ${minChar} characters` : ''}
      fullWidth
      required={required}
      disabled={disabled}
    />
  )
}: COSAutocompleteProps<T>) => {
  return (
    <Autocomplete<T, false, false, false>
      options={options}
      loading={loading}
      value={value}
      onChange={(event: SyntheticEvent, option: T | null) => onChange(option)}
      getOptionLabel={getOptionLabel}
      fullWidth
      disabled={disabled}
      renderOption={renderOption}
      renderInput={renderInput}
      isOptionEqualToValue={(option: T, value: T) => {
        return option.id === value.id;
      }}
      noOptionsText="No results were found"
      loadingText="Loading..."
    />
  );
};

export default COSAutocomplete;

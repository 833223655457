import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ClientSimpleResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IClients<T> extends IAsyncType<T> {
  clients: T;
  setClients: Dispatch<SetStateAction<T>>;
}

const useClients = (
  search?: string,
  showInactive: boolean = false,
  limit: number = 100,
  skip = 0
): IClients<ClientSimpleResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
  ClientSimpleResponse[]
  >(cadabraService.getClients, [], [search, showInactive, limit, skip]);
  return {
    clients: data,
    setClients: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useClients;

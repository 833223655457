import {
  Box,
  Container,
  Grid,
  InputAdornment,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';
import {
  COSAsyncMultipleAutocomplete,
  COSButton,
  COSCellProps,
  COSOutlinedDiv,
  COSTable,
  COSTextField,
  COSViewOnlyTextField,
  Lookup
} from '../../helpers/ui';
import {
  CandidateResponse,
  CandidateWorkHistoryScrapedInfo,
  CreateCandidateFromImportRequest,
  TechnologyResponse
} from '../../services/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCandidateHistory,
  useCandidateImport,
  useCandidateImportSuggestions
} from '../../helpers/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import CandidateCardComponent from './CandidateCardComponent/CandidateCardComponent';
import { Check } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { cadabraService } from '../../services/services';
import moment from 'moment';
import { parseQuery } from '../../helpers/url';
import useTechnologies from '../../helpers/hooks/useTechnologies';

class CandidateWorkHistory {
  id?: number;
  companyName?: string;
  positionName?: string;
  startDate?: string;
  endDate?: string;
}

const CandidateImportComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = parseQuery(location.search);
  const { candidateImport } = useCandidateImport(id);
  const candidateHistoryData = useCandidateHistory();
  const candidateSuggestionsData = useCandidateImportSuggestions(null);
  const [selectedCandidate, setSelectedCandidate] = useState<CandidateResponse | null>(null);
  const importHeadline = useMemo(
    () => candidateImport?.scrapedInfo.headline ?? '',
    [candidateImport]
  );
  const importEmail = useMemo(() => candidateImport?.scrapedInfo.email ?? '', [candidateImport]);
  const importPhoneNumber = useMemo(
    () => candidateImport?.scrapedInfo.phoneNumber ?? '',
    [candidateImport]
  );
  const importSkills = useMemo(() => candidateImport?.scrapedInfo.skills ?? [], [candidateImport]);
  const importWorkHistory = useMemo(
    () => candidateImport?.scrapedInfo.workHistory ?? [],
    [candidateImport]
  );
  const existingHeadline = useMemo(() => selectedCandidate?.headline ?? '', [selectedCandidate]);
  const existingEmail = useMemo(() => selectedCandidate?.email ?? '', [selectedCandidate]);
  const existingPhoneNumber = useMemo(
    () => selectedCandidate?.phoneNumber ?? '',
    [selectedCandidate]
  );

  useEffect(() => {
    candidateSuggestionsData.setCandidateImport(candidateImport);
  }, [candidateImport]);

  useEffect(() => {
    if (candidateSuggestionsData.candidateSuggestions?.length > 0) {
      setSelectedCandidate(candidateSuggestionsData.candidateSuggestions[0]);
    }
  }, [candidateSuggestionsData.candidateSuggestions]);

  const [selectedHeadline, setSelectedHeadline] = useState<
    'importedCandidate' | 'existingCandidate'
  >('importedCandidate');
  const [selectedEmail, setSelectedEmail] = useState<'importedCandidate' | 'existingCandidate'>(
    'importedCandidate'
  );
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<
    'importedCandidate' | 'existingCandidate'
  >('importedCandidate');
  const technologiesData = useTechnologies();
  const [techValue, setTechValue] = useState<Lookup[]>([]);
  const [name, setName] = useState<string>('');
  const [headline, setHeadline] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [url, setUrl] = useState<string>('');
  const [imgUrl, setImgUrl] = useState<string>('');
  const [candidateWorkHistory, setCandidateWorkHistory] = useState<CandidateWorkHistory[]>([]);

  useEffect(() => {
    if (candidateImport) {
      const { url, imageBase64 } = candidateImport;
      const { name, headline, email, phoneNumber } = candidateImport.scrapedInfo;
      setName(name);
      setEmail(email);
      setHeadline(headline);
      setPhoneNumber(phoneNumber);
      setUrl(url);
      setImgUrl(`data:image/jpeg;base64,${imageBase64}`);
    }
  }, [candidateImport]);

  // Trigger technologies search
  useEffect(() => {
    if ((candidateImport?.scrapedInfo.skills.length ?? 0) > 0) {
      technologiesData.setParameters([undefined, false, importSkills]);
    }
  }, [importSkills]);

  useEffect(() => {
    let techValues = (importSkills.length > 0 ? technologiesData.technologies ?? [] : []).map(
      (t) => ({
        id: t.id,
        value: t.name
      })
    );
    let index = -1;
    for (const skill of importSkills) {
      if (
        technologiesData.technologies.findIndex((t: TechnologyResponse) => t.name === skill) === -1
      ) {
        techValues = [...techValues, { id: index--, value: skill }];
      }
    }
    setTechValue(techValues);
  }, [technologiesData.technologies, importSkills]);

  // Trigger candidate history search and update candidate info
  useEffect(() => {
    if (selectedCandidate) {
      candidateHistoryData.setParameters([selectedCandidate.id]);
      if (selectedHeadline === 'existingCandidate') {
        setHeadline(selectedCandidate.headline);
      }
      if (selectedEmail === 'existingCandidate') {
        setEmail(selectedCandidate.email);
      }
      if (selectedPhoneNumber === 'existingCandidate') {
        setPhoneNumber(selectedCandidate.phoneNumber);
      }
    } else {
      candidateHistoryData.setCandidateHistory([]);
      setHeadline(importHeadline);
      setEmail(importEmail);
      setPhoneNumber(importPhoneNumber);
    }
  }, [selectedCandidate]);

  // Fill candidate work history with scraped info and selected candidate history
  useEffect(() => {
    setCandidateWorkHistory([
      ...importWorkHistory,
      ...candidateHistoryData.candidateHistory.filter(
        (ch) =>
          !importWorkHistory.some((wh: CandidateWorkHistoryScrapedInfo) => {
            return (
              wh.companyName.trim() === ch.companyName.trim() &&
              wh.positionName.trim() === ch.positionName.trim()
            );
          })
      )
    ]);
  }, [importWorkHistory, candidateHistoryData.candidateHistory]);

  const handleChangeHeadline = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: 'importedCandidate' | 'existingCandidate') => {
      if (value) {
        setSelectedHeadline(value);
        if (value === 'importedCandidate') {
          setHeadline(importHeadline);
        } else {
          setHeadline(existingHeadline);
        }
      }
    },
    [selectedCandidate, candidateImport, importHeadline, existingHeadline]
  );

  const handleChangeEmail = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: 'importedCandidate' | 'existingCandidate') => {
      if (value) {
        setSelectedEmail(value);
        if (value === 'importedCandidate') {
          setEmail(importEmail);
        } else {
          setEmail(existingEmail);
        }
      }
    },
    [selectedCandidate, candidateImport, importHeadline, existingHeadline]
  );

  const handleChangePhoneNumber = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: 'importedCandidate' | 'existingCandidate') => {
      if (value) {
        setSelectedPhoneNumber(value);
        if (value === 'importedCandidate') {
          setPhoneNumber(importPhoneNumber);
        } else {
          setPhoneNumber(existingPhoneNumber);
        }
      }
    },
    [selectedCandidate, candidateImport, importPhoneNumber, existingPhoneNumber]
  );

  return (
    <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <Container maxWidth="md">
              <Paper elevation={2}>
                <COSOutlinedDiv label={id ? name : 'New candidate'}>
                  <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
                    <Grid container item lg={4} md={12} sm={12} xs={12}>
                      <Box
                        component="img"
                        sx={{ width: '75%', height: 'auto', borderRadius: 50, margin: 'auto' }}
                        src={imgUrl}
                      />
                    </Grid>
                    <Grid item lg={8} md={12} sm={12} xs={12}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <COSTextField
                          label="Name"
                          value={name}
                          onChange={(value) => setName(value)}
                          InputProps={
                            name === candidateSuggestionsData.search
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Check />
                                    </InputAdornment>
                                  )
                                }
                              : undefined
                          }
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <COSViewOnlyTextField
                          label="Headline"
                          value={headline ?? ''}
                          InputProps={
                            headline === candidateSuggestionsData.search
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Check />
                                    </InputAdornment>
                                  )
                                }
                              : undefined
                          }
                        />
                        {selectedCandidate ? (
                          <ToggleButtonGroup
                            size="small"
                            value={selectedHeadline}
                            onChange={handleChangeHeadline}
                            exclusive={true}
                            aria-label="Small sizes"
                            sx={{ marginTop: 1, marginLeft: 1, marginBottom: 0.5 }}
                          >
                            <ToggleButton key={importHeadline} value="importedCandidate">
                              <Tooltip arrow placement="left" title="From import">
                                <CloudDownloadIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                            <ToggleButton key={existingHeadline} value="existingCandidate">
                              <Tooltip arrow placement="right" title="From existing">
                                <PersonSearchIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        ) : null}
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <COSViewOnlyTextField
                          label="Email"
                          value={email ?? ''}
                          InputProps={
                            email === candidateSuggestionsData.search
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Check />
                                    </InputAdornment>
                                  )
                                }
                              : undefined
                          }
                        />

                        {selectedCandidate ? (
                          <ToggleButtonGroup
                            size="small"
                            value={selectedEmail}
                            onChange={handleChangeEmail}
                            exclusive={true}
                            aria-label="Small sizes"
                            sx={{ marginTop: 1, marginLeft: 1, marginBottom: 0.5 }}
                          >
                            <ToggleButton key={importEmail} value="importedCandidate">
                              <Tooltip arrow placement="left" title="From import">
                                <CloudDownloadIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                            <ToggleButton key={existingEmail} value="existingCandidate">
                              <Tooltip arrow placement="right" title="From existing">
                                <PersonSearchIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        ) : null}
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <COSViewOnlyTextField
                          label="Phone"
                          value={phoneNumber ?? ''}
                          InputProps={
                            phoneNumber === candidateSuggestionsData.search
                              ? {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Check />
                                    </InputAdornment>
                                  )
                                }
                              : undefined
                          }
                        />

                        {selectedCandidate ? (
                          <ToggleButtonGroup
                            size="small"
                            value={selectedPhoneNumber}
                            onChange={handleChangePhoneNumber}
                            exclusive={true}
                            aria-label="Small sizes"
                            sx={{ marginTop: 1, marginLeft: 1, marginBottom: 0.5 }}
                          >
                            <ToggleButton key={importPhoneNumber} value="importedCandidate">
                              <Tooltip arrow placement="left" title="From import">
                                <CloudDownloadIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                            <ToggleButton key={existingPhoneNumber} value="existingCandidate">
                              <Tooltip arrow placement="right" title="From existing">
                                <PersonSearchIcon sx={{ color: 'inherit' }} />
                              </Tooltip>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <COSViewOnlyTextField
                        label="URL LinkedIn"
                        value={url}
                        multiline={true}
                        rows={3}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <COSAsyncMultipleAutocomplete<Lookup>
                        label="Technologies"
                        value={techValue}
                        options={technologiesData.technologies.map(
                          (technology: TechnologyResponse) => ({
                            id: technology.id,
                            value: technology.name
                          })
                        )}
                        loading={technologiesData.loading}
                        limitTags={25}
                        minChar={0}
                        disabled={true}
                        onChange={(value: Lookup[]) => setTechValue(value)}
                      />
                    </Grid>
                    <Grid container item lg={12} justifyContent="center">
                      <COSButton
                        text={selectedCandidate ? 'Update' : 'Create'}
                        variant="contained"
                        sx={{
                          width: '20%'
                        }}
                        onClick={async () => {
                          const createRequest: CreateCandidateFromImportRequest = {
                            candidateImportId: id,
                            candidateId: selectedCandidate?.id,
                            urlLinkedin: url,
                            name,
                            headline,
                            email,
                            phoneNumber,
                            technologies: techValue
                              .filter((value: Lookup) => value.id > 0)
                              .map((value: Lookup) => value.id),
                            newTechnologies: techValue
                              .filter((value: Lookup) => value.id < 0)
                              .map((value: Lookup) => value.value),
                            workHistory: candidateWorkHistory
                              .filter((wh) => wh.id)
                              .map((wh) => wh.id ?? 0),
                            newWorkHistory: candidateWorkHistory
                              .filter((wh) => !wh.id)
                              .map((wh) => ({
                                companyName: wh.companyName ?? '',
                                positionName: wh.positionName ?? '',
                                startDate: moment(wh.startDate).format('YYYY-MM-DD'),
                                endDate: wh.endDate
                                  ? moment(wh.endDate).format('YYYY-MM-DD')
                                  : undefined
                              }))
                          };
                          const candidateResp =
                            await cadabraService.createCandidateFromImportRequest(createRequest);
                          navigate(`/candidate?id=${candidateResp.data.id}`);
                        }}
                        disabled={false}
                        loading={false}
                        fullWidth={true}
                      />
                    </Grid>
                  </Grid>
                </COSOutlinedDiv>
              </Paper>
            </Container>
          </Grid>
          <Grid item lg={12}>
            <Box sx={{ px: 3, py: 1 }}>
              <COSTable<CandidateWorkHistory>
                isLoading={false}
                data={candidateWorkHistory}
                columns={[
                  {
                    accessorKey: 'id',
                    header: '',
                    size: 30,
                    Cell: ({ row }: COSCellProps<CandidateWorkHistory>) => {
                      const value = row.original.id ? 'O' : 'N';
                      return <Typography>{value}</Typography>;
                    }
                  },
                  {
                    accessorKey: 'companyName',
                    header: 'Company',
                    size: 200
                  },
                  {
                    accessorKey: 'positionName',
                    header: 'Position',
                    size: 200
                  },
                  {
                    accessorKey: 'startDate',
                    header: 'From',
                    size: 85,
                    Cell: ({ row }: COSCellProps<CandidateWorkHistory>) =>
                      row.original.startDate ? moment(row.original.startDate).format('MMM-YY') : '-'
                  },
                  {
                    accessorKey: 'endDate',
                    header: 'To',
                    size: 85,
                    Cell: ({ row }: COSCellProps<CandidateWorkHistory>) =>
                      row.original.endDate ? moment(row.original.endDate).format('MMM-YY') : '-'
                  }
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid lg={6}>
        <Container maxWidth="md">
          <COSTextField
            label="Search"
            value={candidateSuggestionsData.search ?? ''}
            onEnter={() => {
              candidateSuggestionsData.fetchCandidateSuggestions(
                candidateSuggestionsData.search ?? undefined
              );
            }}
            onChange={(value) => candidateSuggestionsData.setSearch(value)}
            sx={{ marginBottom: 2 }}
          />
          {candidateSuggestionsData.candidateSuggestions.map((cand: CandidateResponse) => (
            <CandidateCardComponent
              key={cand.id}
              candidate={cand}
              selected={selectedCandidate?.id === cand.id}
              onClick={
                candidateSuggestionsData.search !== null
                  ? () => {
                      setSelectedCandidate(cand.id === selectedCandidate?.id ? null : cand);
                    }
                  : undefined
              }
            />
          ))}
        </Container>
      </Grid>
    </Grid>
  );
};

export default CandidateImportComponent;

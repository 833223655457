import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { COSButton, COSStatsChart } from '../../helpers/ui';
import { CheckBox, CheckBoxOutlineBlank, Search } from '@mui/icons-material';
import React, { useCallback, useMemo, useState } from 'react';
import {
  TimePeriod,
  useFlag,
  useInProcessInteractions,
  useInProcessInteractionsByUser
} from '../../helpers/hooks';
import moment, { Moment } from 'moment';

import Axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const InProcessReportComponent = () => {
  const [timePeriod, setTimePeriod] = useState<TimePeriod>('DAY');
  const [fromDate, setFromDate] = useState<Moment | null>(moment());
  const [toDate, setToDate] = useState<Moment | null>(moment());
  const [byUser, setByUser] = useFlag(false);
  const companyStats = useInProcessInteractions();
  const userStats = useInProcessInteractionsByUser();
  const [searchByUser, setSearchByUser] = useFlag(false);

  const chartStats = useMemo(() => {
    return searchByUser ? userStats : companyStats;
  }, [companyStats, userStats]);

  const chartLabel = useMemo(() => (searchByUser ? 'User' : 'Company'), [searchByUser]);

  const onSearch = useCallback(() => {
    setSearchByUser(byUser);
    if (byUser) {
      userStats.setParameters(
        timePeriod,
        [],
        fromDate?.format('YYYY-MM-DD'),
        toDate?.format('YYYY-MM-DD')
      );
    } else {
      companyStats.setParameters(
        timePeriod,
        fromDate?.format('YYYY-MM-DD'),
        toDate?.format('YYYY-MM-DD')
      );
    }
  }, [timePeriod, fromDate, toDate, byUser]);

  const handleChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, timePeriod: TimePeriod) => {
      if (timePeriod) {
        setTimePeriod(timePeriod);
      }
    },
    []
  );

  const control = useMemo(
    () => ({
      value: timePeriod,
      onChange: handleChange,
      exclusive: true
    }),
    [timePeriod]
  );

  const chartView = useMemo(
    () => (
      <>
        {(chartStats?.chartData ?? []).map((data: any, index: number) => (
          <COSStatsChart
            key={index}
            title={data.title ?? ''}
            labels={data.labels}
            datasets={data.datasets}
            legend={data.legend}
            tooltip={data.tooltip}
          />
        ))}
      </>
    ),
    [chartStats, chartLabel]
  );

  return (
    <Grid container spacing={2}>
      <Grid item lg={2} md={12} sm={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > :not(style) + :not(style)': { mt: 2 }
          }}
        >
          <ToggleButtonGroup
            size="small"
            {...control}
            aria-label="Small sizes"
            sx={{ marginTop: 1 }}
          >
            <ToggleButton value="DAY" key="DAY">
              <Typography>D</Typography>
            </ToggleButton>
            <ToggleButton value="WEEK" key="WEEK">
              <Typography>W</Typography>
            </ToggleButton>
            <ToggleButton value="MONTH" key="MONTH">
              <Typography>M</Typography>
            </ToggleButton>
            <ToggleButton value="QUARTER" key="QUARTER">
              <Typography>Q</Typography>
            </ToggleButton>
            <ToggleButton value="HALF_YEAR" key="HALF_YEAR">
              <Typography>H</Typography>
            </ToggleButton>
            <ToggleButton value="YEAR" key="YEAR">
              <Typography>Y</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      <Grid item lg={3} md={6} sm={12}>
        <DatePicker<Moment>
          views={['year', 'month', 'day']}
          label="From Date"
          inputFormat="DD/MM/YY"
          mask="__/__/__"
          disableMaskedInput={false}
          value={fromDate}
          onChange={setFromDate}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={null}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item lg={3} md={6} sm={12}>
        <DatePicker<Moment>
          views={['year', 'month', 'day']}
          label="To Date"
          inputFormat="DD/MM/YY"
          mask="__/__/__"
          disableMaskedInput={false}
          value={toDate}
          onChange={setToDate}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={null}
              fullWidth
              margin="dense"
              variant="outlined"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item lg={2} md={6} sm={6} xs={12} sx={{ marginTop: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBox />}
                checked={byUser}
                onChange={() => setByUser(!byUser)}
                color="primary"
              />
            }
            label="By User"
          />
        </Box>
      </Grid>
      <Grid item lg={2} md={6} sm={6} xs={12}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            paddingTop: 1,
            justifyContent: 'space-between'
          }}
        >
          <COSButton text="SEARCH" variant="contained" onClick={onSearch} startIcon={<Search />} />
        </Box>
      </Grid>
      <Grid item lg={12} sx={{ width: '100%', height: '500px' }}>
        {chartView}
      </Grid>
    </Grid>
  );
};
export default withErrorHandler(InProcessReportComponent, Axios);

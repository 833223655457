import { OutlinedInputProps, SxProps, TextField, Theme } from '@mui/material';
import React, { KeyboardEvent } from 'react';

interface COSTextFieldProps {
  label: string;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  value: string | undefined;
  onChange: (value: string) => void;
  onEnter?: (event: KeyboardEvent<HTMLDivElement>) => void;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  name?: string;
  type?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  InputProps?: Partial<OutlinedInputProps> | undefined;
  sx?: SxProps<Theme> | undefined;
}

const COSTextField = ({
  label,
  color,
  value,
  onChange,
  onEnter,
  required,
  multiline = false,
  rows,
  name,
  type,
  autoComplete,
  autoFocus,
  InputProps,
  sx
}: COSTextFieldProps) => {
  return (
    <TextField
      sx={sx}
      label={label}
      color={color}
      margin="dense"
      variant="outlined"
      size="small"
      multiline={multiline}
      rows={rows}
      fullWidth
      name={name}
      type={type}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      required={required}
      value={value}
      InputProps={InputProps}
      onChange={(event) => onChange(event?.target.value)}
      onKeyPress={
        onEnter
          ? (ev) => {
              if (ev.key === 'Enter') {
                onEnter(ev);
              }
            }
          : undefined
      }
    />
  );
};

export default COSTextField;

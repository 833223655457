import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ICandidates<T> extends IAsyncType<T> {
  candidates: T;
  setCandidates: Dispatch<SetStateAction<T>>;
}

const useCandidates = (
  search?: string,
  linkedinUrl?: string,
  technologies?: number[],
  desiredTechnologies?: number[],
  mainTechnologies?: number[],
  positions?: number[],
  cities?: number[],
  weeksSinceLastContact?: number,
  limit = 100,
  skip = 0
): ICandidates<CandidateResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateResponse[]
  >(
    cadabraService.getCandidates,
    [],
    [search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact]
  );
  return {
    candidates: data,
    setCandidates: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCandidates;

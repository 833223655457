import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React, { useMemo } from 'react';

import { CandidateProjectEventLogResponse } from '../../../../services/api';
import { Pie } from 'react-chartjs-2';
import { stringToColor } from '../../../../helpers/ui';
import { useProjectEventLogs } from '../../../../helpers/hooks';

ChartJS.register(ArcElement, Tooltip, Legend);

interface UserDistributionProps {
  projectId: number;
}

const UserDistribution = ({ projectId }: UserDistributionProps) => {
  const { eventLogs } = useProjectEventLogs(projectId);

  const userDistribution = useMemo(
    () =>
      eventLogs.reduce((res: any, log: CandidateProjectEventLogResponse) => {
        const key = `${log.userId} ${log.userName}`;
        if (!res[key]) {
          res[key] = 0;
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        res[key] = res[key] + 1;
        return res;
      }, {}),
    [eventLogs]
  );
  return (
    <Pie
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: 'User distribution'
          }
        }
      }}
      data={{
        labels: Object.keys(userDistribution).map((key: string) => {
          const index = key.indexOf(' ');
          return key.substring(index + 1);
        }),
        datasets: [
          {
            label: 'User distribution',
            data: Object.values(userDistribution),
            backgroundColor: Object.keys(userDistribution).map((key) => stringToColor(key))
          }
        ]
      }}
    />
  );
};

export default UserDistribution;

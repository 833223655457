import { CadabraApiFactory, Configuration } from '../services/api';

import { auth } from '../firebase';

export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;

const configWithToken = async () => {
  const idToken: string | undefined = await auth.currentUser?.getIdToken(false);
  return new Configuration({
    accessToken: `Bearer ${idToken}`,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    }
  });
};

export const cadabraApi = async () => {
  const config = await configWithToken();
  return CadabraApiFactory(config, backendApiUrl);
};

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';

import Annotation from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import DataLabel from 'chartjs-plugin-datalabels';
import React from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  DataLabel,
  Annotation
);

interface COSStatsChartProps {
  title: string;
  labels: string[];
  datasets: any[];
  legend?: any;
  tooltip?: any;
  annotations?: any[];
}

const COSStatsChart = ({
  title,
  labels,
  datasets,
  legend,
  tooltip,
  annotations = []
}: COSStatsChartProps) => {
  return (
    <Bar
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
            align: 'center',
            position: 'top'
          },
          legend,
          tooltip,
          annotation: {
            annotations: [
              {
                type: 'line',
                borderColor: '#B63876',
                click: ({ chart, element }: any) => {
                  console.log('Line annotation clicked');
                },
                label: {
                  backgroundColor: '#B63876',
                  content: 'Target',
                  display: true,
                  padding: {
                    top: 2,
                    bottom: 2,
                    left: 4,
                    right: 4
                  }
                },
                font: { size: 10 },
                borderWidth: 2,
                scaleID: 'y',
                value: 0
              },
              ...annotations
            ]
          },
          datalabels: {
            anchor: 'end',
            align: 'bottom',
            formatter: Math.round,
            font: {
              weight: 'bold',
              family: 'Arial'
            },
            display: (context: any) => context.datasetIndex % 2 === 0,
            color: (context: any) => '#6A6A6A'
          }
        } as any,
        responsive: true,
        interaction: {
          mode: 'index' as const,
          intersect: true
        },
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true
          }
        },
        maintainAspectRatio: false
      }}
      data={{
        labels,
        datasets
      }}
    />
  );
};

export default COSStatsChart;

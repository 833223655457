import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { COSButton, COSOutlinedDiv, COSTextField } from '../../../helpers/ui';
import React, { useState } from 'react';
import { cadabraService, snackbarService } from '../../../services/services';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { CreateUserRequest } from '../../../services/api';
import { useFlag } from '../../../helpers/hooks';
import { useNavigate } from 'react-router-dom';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

const NewUserForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [loading, setLoading] = useFlag(false);
  const handleOnClick = async () => {
    setLoading(true);
    const createUser: CreateUserRequest = {
      name: name ?? '',
      email: email ?? '',
      isActive,
      isAdmin
    };
    const resp = await cadabraService.createUser(createUser);
    if (resp) {
      navigate(`/user?id=${resp.data.id}`);
    }
    setLoading(false);
    snackbarService.setSnackbarOpen(true, 'Data seved successfully', 'success');
  };
  return (
    <COSOutlinedDiv label={'New user'}>
      <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
        <Grid item lg={6}>
          <Box
            component="img"
            sx={{
              width: '75%',
              height: 'auto',
              maxWidth: 300,
              maxHeight: 300,
              borderRadius: 50,
              margin: 'auto',
              padding: 10,
              border: '2px solid #ae2367'
            }}
            src="https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/users/29.jpeg"
          />
        </Grid>
        <Grid item lg={6}>
          <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
            <Grid item lg={12}>
              <COSTextField label="Name" value={name} onChange={(value) => setName(value)} />
            </Grid>
            <Grid item lg={12}>
              <COSTextField label="Email" value={email} onChange={(value) => setEmail(value)} />
            </Grid>
            <Grid item lg={6} alignContent="center" justifyContent="center" container>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={isAdmin}
                    onChange={() => setIsAdmin(!isAdmin)}
                    color="primary"
                  />
                }
                label="Admin"
              />
            </Grid>
            <Grid item lg={6} alignContent="center" justifyContent="center" container>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent="center">
          <COSButton
            text="Save"
            variant="contained"
            sx={{
              width: '20%'
            }}
            onClick={handleOnClick}
            disabled={loading}
            loading={loading}
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </COSOutlinedDiv>
  );
};

export default NewUserForm;

import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  CheckBox,
  CheckBoxOutlineBlank
} from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';
import { COSCellProps, COSTable, COSTextField } from '../../helpers/ui';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import { PositionResponse } from '../../services/api';
import { parseQuery } from '../../helpers/url';
import { useNavigate } from 'react-router-dom';
import usePositions from '../../helpers/hooks/usePositions';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const apiFetchSize = 25;

const PositionsComponent = () => {
  const { selectedPage } = parseQuery(location.search);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { positions, loading, setParameters } = usePositions(undefined, true, apiFetchSize, 0);
  const [page, setPage] = useState<number>(selectedPage ? +selectedPage : 0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setParameters([search, showInactive, apiFetchSize, page * apiFetchSize]);
  }, [showInactive]);

  const onEnter = useCallback(() => {
    setPage(0);
    setParameters([search, showInactive, apiFetchSize, 0]);
    navigate('/positions');
  }, [search, showInactive]);

  useEffect(() => {
    setParameters([search, showInactive, apiFetchSize, page * apiFetchSize]);
    navigate(`/positions?selectedPage=${page}`);
  }, [page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [positions]);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Positions
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <COSTextField
              label="Search"
              value={search}
              onChange={(value: string) => setSearch(value)}
              onEnter={onEnter}
            />
          </Grid>
          <Grid item lg={3} sx={{ marginTop: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                    color="primary"
                  />
                }
                label="Show Inactive"
              />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <COSTable<PositionResponse>
              isLoading={loading}
              data={positions}
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: { maxHeight: '60vh', minHeight: '300px' }
              }}
              columns={[
                {
                  accessorKey: 'id',
                  header: '#',
                  size: 50,
                  Cell: ({ row }: COSCellProps<PositionResponse>) =>
                    row.index + page * apiFetchSize + 1
                },
                {
                  accessorKey: 'name',
                  header: 'Name',
                  enableColumnOrdering: true,
                  enableEditing: false,
                  enableSorting: true,
                  size: 950
                }
              ]}
              renderBottomToolbarCustomActions={() => (
                <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                  <IconButton
                    onClick={() => setPage(page > 0 ? page - 1 : 0)}
                    disabled={page === 0}
                  >
                    <ArrowBackIosOutlined />
                  </IconButton>
                  <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
                    <Typography align="center" variant="h4" component="h5">
                      {page + 1}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => setPage(page + 1)}
                    disabled={positions.length < apiFetchSize}
                  >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(PositionsComponent, Axios);

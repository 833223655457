import { CandidateImportWithScrapedInfoResponse, CandidateResponse } from '../../services/api';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { cadabraService } from '../../services/services';

export interface CandidateImportSuggestions {
  candidateImport: CandidateImportWithScrapedInfoResponse | null;
  setCandidateImport: Dispatch<SetStateAction<CandidateImportWithScrapedInfoResponse | null>>;
  candidateSuggestions: CandidateResponse[];
  setCandidateSuggestions: Dispatch<SetStateAction<CandidateResponse[]>>;
  fetchCandidateSuggestions: (search?: string) => Promise<void>;
  search: string | null;
  setSearch: Dispatch<SetStateAction<string | null>>;
}

const useCandidateImportSuggestions = (
  ci: CandidateImportWithScrapedInfoResponse | null
): CandidateImportSuggestions => {
  const [candidateImport, setCandidateImport] = useState<CandidateImportWithScrapedInfoResponse | null>(ci);
  const [search, setSearch] = useState<string | null>('');
  const [candidateSuggestions, setCandidateSuggestions] = useState<CandidateResponse[]>([]);
  const fetchAsync = useCallback(async (asyncCall: (...args: any[]) => Promise<AxiosResponse<CandidateResponse[]>>, filter?: string) => {
    return await asyncCall(filter);
  }, []);
  const fetchCandidateSuggestions = useCallback(async (search?: string) => {
    if (search) {
      const searchCandidatesResp = await fetchAsync(cadabraService.getCandidates, search);
      setCandidateSuggestions(searchCandidatesResp.data);
      return;
    }
    if (!candidateImport) {
      return;
    }
    const linkedInCandidatesResp = await fetchAsync(cadabraService.getCandidatesByLinkedInUrl, candidateImport.url);
    if (linkedInCandidatesResp?.data.length > 0) {
      setCandidateSuggestions(linkedInCandidatesResp.data);
      setSearch(null);
      return;
    }
    if (candidateImport.scrapedInfo.email) {
      const emailCandidatesResp = await fetchAsync(cadabraService.getCandidates, candidateImport.scrapedInfo.email);
      if (emailCandidatesResp?.data.length > 0) {
        setCandidateSuggestions(emailCandidatesResp.data);
        setSearch(candidateImport.scrapedInfo.email ?? null);
        return;
      }
    }
    if (candidateImport.scrapedInfo.phoneNumber) {
      const phoneNumberCandidatesResp = await fetchAsync(cadabraService.getCandidates, candidateImport.scrapedInfo.phoneNumber);
      if (phoneNumberCandidatesResp?.data.length > 0) {
        setCandidateSuggestions(phoneNumberCandidatesResp.data);
        setSearch(candidateImport.scrapedInfo.phoneNumber ?? null);
        return;
      }
    }
    const nameCandidatesResp = await fetchAsync(cadabraService.getCandidates, candidateImport.scrapedInfo.name);
    if (nameCandidatesResp?.data.length > 0) {
      setCandidateSuggestions(nameCandidatesResp.data);
      setSearch(candidateImport.scrapedInfo.name ?? null);
    }
  }, [fetchAsync, setSearch, setCandidateSuggestions, candidateImport]);

  useEffect(() => {
    fetchCandidateSuggestions();
  }, [fetchCandidateSuggestions]);

  return {
    candidateImport,
    setCandidateImport,
    candidateSuggestions,
    setCandidateSuggestions,
    fetchCandidateSuggestions,
    search,
    setSearch
  };
};

export default useCandidateImportSuggestions;

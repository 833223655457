import {
  AutocompleteRenderOptionState,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem
} from '@mui/material';
import { COSAutocomplete, COSButton, Lookup } from '../../../helpers/ui';
import React, { useCallback, useMemo, useState } from 'react';
import { RelationTypeKey, getRelationTypesByKey } from '../../../services/cadabraService';

import { CreateCandidateUserRelationRequest } from '../../../services/api';

export interface CandidateUserRelationDialogProps {
  candidateId: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateCandidateUserRelationRequest) => void;
}

const CandidateUserRelationDialog = ({
  candidateId,
  open,
  onClose,
  onSave
}: CandidateUserRelationDialogProps) => {
  const [relationType, setRelationType] = useState<Lookup | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const types = useMemo(() => {
    const options = [
      { id: 'FIRST_CONNECTION', value: 'FIRST_CONNECTION' },
      { id: 'HAS_PHONE_PERMISSION', value: 'HAS_PHONE_PERMISSION' },
      { id: 'HAS_EMAIL_PERMISSION', value: 'HAS_EMAIL_PERMISSION' },
      { id: 'PERSONAL_FRIEND', value: 'PERSONAL_FRIEND' }
    ];
    setRelationType(options[0]);
    setLoading(false);
    return options;
  }, []);

  const isFormValid = useMemo(() => relationType, [relationType]);

  const handleOnClose = useCallback(() => {
    setRelationType(null);
    onClose();
  }, [onClose]);

  const handleOnSave = useCallback(() => {
    onSave({
      relationType: relationType?.id,
      candidateId
    });
    handleOnClose();
  }, [relationType, candidateId, onSave, handleOnClose]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Candidate User Relation</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <COSAutocomplete<Lookup>
              label="Type"
              value={relationType}
              options={types}
              loading={loading}
              onChange={(value: Lookup | null) => setRelationType(value)}
              getOptionLabel={(option: Lookup) =>
                `${getRelationTypesByKey(option.value as RelationTypeKey).label}`
              }
              renderOption={(
                props: React.HTMLAttributes<HTMLLIElement>,
                option: Lookup,
                state: AutocompleteRenderOptionState
              ) => {
                return (
                  <ListItem {...props}>
                    {getRelationTypesByKey(option.value as RelationTypeKey).label}
                  </ListItem>
                );
              }}
              minChar={0}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <COSButton
          text="Save"
          onClick={handleOnSave}
          color="primary"
          variant="contained"
          disabled={!isFormValid}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CandidateUserRelationDialog;

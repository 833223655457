import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';
import { COSCellProps, COSLink, COSRowActions, COSTable } from '../../helpers/ui';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { PatchTaskRequest, TaskResponse } from '../../services/api';
import React, { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Task, { compareTasks, toTask } from '../../models/task';
import { cadabraService, snackbarService } from '../../services/services';
import moment, { Moment } from 'moment';

import Axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers';
import { useTasks } from '../../helpers/hooks';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const fetchSize = 25;

const TasksComponent = () => {
  const [tasksData, setTasksData] = useState<Task[]>([]);
  const [date, setDate] = useState<Moment | null>(moment());
  const { tasks, setTasks, loading, setLoading, setParameters } = useTasks(
    undefined,
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString()
  );
  const [page, setPage] = useState<number>(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const startDate = date?.startOf('day').toISOString();
    const endDate = date?.endOf('day').toISOString();
    setParameters([undefined, startDate, endDate, true]);
  }, [date]);

  useEffect(() => {
    setTasksData(tasks.map(toTask).sort(compareTasks));
  }, [tasks]);

  const visibleTasks = useMemo(
    () => tasksData.slice(0, (page + 1) * fetchSize),

    [tasksData, page]
  );

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 600 &&
          visibleTasks.length < tasksData.length
        ) {
          setPage(page + 1);
        }
      }
    },

    [tasksData, page, visibleTasks]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const onChange = useCallback(
    async (task: Task) => {
      const patchRequest: PatchTaskRequest = {
        isActive: !task.isActive
      };
      setLoading(true);
      const resp = await cadabraService.patchTask(task.id, patchRequest);
      if (resp.data) {
        snackbarService.setSnackbarOpen(true, 'Data seved successfully', 'success');
        setTasks(
          tasks.reduce((res: TaskResponse[], t: TaskResponse) => {
            if (t.id === task.id) {
              res.push(resp.data);
            } else {
              res.push(t);
            }
            return res;
          }, [])
        );
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
      setLoading(false);
    },
    [tasks]
  );

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Tasks
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <DatePicker<Moment>
              views={['year', 'month', 'day']}
              label="Date"
              inputFormat="DD/MM/YY"
              mask="__/__/__"
              disableMaskedInput={false}
              value={date}
              onChange={setDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <COSTable<Task>
              isLoading={loading}
              data={visibleTasks}
              enablePagination={false}
              enableStickyHeader={true}
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: { maxHeight: '600px' },
                onScroll: (event: UIEvent<HTMLDivElement>) =>
                  fetchMoreOnBottomReached(event.target as HTMLDivElement)
              }}
              columns={[
                {
                  accessorKey: 'reminderDate',
                  header: 'Date',
                  size: 160,
                  Cell: ({ row }: COSCellProps<Task>) => {
                    const value = moment(row.original.reminderDate ?? '2020-01-01T00:00:00').format(
                      'DD/MM/YY'
                    );
                    return (
                      <Typography
                        sx={!row.original.isActive ? { textDecoration: 'line-through' } : undefined}
                      >
                        {value}
                        {row.original.isActive && !row.original.pending ? ' (overdue)' : ''}
                      </Typography>
                    );
                  },
                  sortingFn: (row1, row2) =>
                    moment(row1.original.reminderDate).isBefore(moment(row2.original.reminderDate))
                      ? -1
                      : 1
                },
                {
                  accessorKey: 'comment',
                  header: 'Comment',
                  size: 250,
                  Cell: ({ row }: COSCellProps<Task>) => {
                    return (
                      <Typography
                        sx={!row.original.isActive ? { textDecoration: 'line-through' } : undefined}
                      >
                        {row.original.comment}
                      </Typography>
                    );
                  }
                },
                {
                  accessorKey: 'candidateName',
                  header: 'Candidate',
                  size: 150,
                  Cell: ({ row }: COSCellProps<Task>) => (
                    <COSLink
                      sx={
                        !row.original.isActive
                          ? {
                              '&:hover': {
                                cursor: 'pointer',
                                textDecoration: 'underline'
                              },
                              color: 'inherit',
                              backgroundColor: 'transparent',
                              textDecoration: 'line-through'
                            }
                          : undefined
                      }
                      href={`/candidate?id=${row.original.candidateId}`}
                    >
                      {row.original.candidateName}
                    </COSLink>
                  )
                },
                {
                  accessorKey: 'isActive',
                  header: 'Actions',
                  size: 100,
                  Cell: ({ row }: COSRowActions<Task>) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                        checked={!row.original.isActive}
                        onChange={async () => await onChange(row.original)}
                        color="default"
                      />
                    </Box>
                  )
                }
              ]}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(TasksComponent, Axios);

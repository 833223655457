import { Box, Divider, IconButton, Typography } from '@mui/material';
import {
  MenuItem,
  administrationMenuItems,
  deprecatedMenuItems,
  mainMenuItems,
  reportMenuItems
} from './listItems';

import { AccountCircle } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItemContainer from './MenuItemContainer/MenuItemContainer';
import React from 'react';
import UserComponent from '../../../components/UserComponent/UserComponent';
import { useMe } from '../../../helpers/hooks';

interface MenuHeaderProps {
  title: string;
}

interface MenuContainerProps {
  open: boolean;
  toolbarIcon: string;
  handleDrawerToggle: () => void;
}

const MenuHeader = ({ title }: MenuHeaderProps) => {
  return (
    <Typography variant="h6" sx={{ marginLeft: 1, color: '#717171' }}>
      {title}
    </Typography>
  );
};

const MenuContainer = (props: MenuContainerProps) => {
  const { open, handleDrawerToggle } = props;
  const { user } = useMe();

  return (
    <Box>
      <MenuHeader title="Main" />
      {mainMenuItems.map((menuItem: MenuItem) => (
        <MenuItemContainer key={menuItem.route} menuItem={menuItem} small={!open} />
      ))}
      {user && (
        <MenuItemContainer
          key={`/user?id=${user.id}`}
          menuItem={{
            label: 'My profile',
            component: UserComponent,
            route: `/user?id=${user.id}`,
            icon: <AccountCircle className="icon-style" />
          }}
          small={!open}
        />
      )}
      <Divider />
      <MenuHeader title="Stats" />
      {reportMenuItems.map((menuItem: MenuItem) => (
        <MenuItemContainer key={menuItem.route} menuItem={menuItem} small={!open} />
      ))}
      <Divider />
      <MenuHeader title="Admin" />
      {administrationMenuItems.map((menuItem: MenuItem) => (
        <MenuItemContainer key={menuItem.route} menuItem={menuItem} small={!open} />
      ))}
      <Divider />
      <MenuHeader title="Deprecated" />
      {deprecatedMenuItems.map((menuItem: MenuItem) => (
        <MenuItemContainer key={menuItem.route} menuItem={menuItem} small={!open} />
      ))}
      <Divider />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '0 4px'
        }}
      >
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default MenuContainer;

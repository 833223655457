import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

import React from 'react';

export interface COSErrorDialogProps {
  open: boolean;
  onClose?: () => void;
  title?: string;
  message?: string;
}

const COSErrorDialog = ({
  open,
  onClose,
  title,
  message
}: COSErrorDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <TextField
          variant="outlined"
          fullWidth
          multiline
          rows={10}
          margin="dense"
          value={message}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default COSErrorDialog;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateProjectEventLogResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IProjectEventLogs<T> extends IAsyncType<T> {
  eventLogs: T;
  setEventLogs: Dispatch<SetStateAction<T>>;
}

const useProjectEventLogs = (
  projectId: number
): IProjectEventLogs<CandidateProjectEventLogResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateProjectEventLogResponse[]
  >(cadabraService.getCandidateProjectEventLogs, [], [[], [projectId], []]);
  return {
    eventLogs: data,
    setEventLogs: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProjectEventLogs;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateImportWithScrapedInfoResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ICandidateImport<T> extends IAsyncType<T> {
  candidateImport: T;
  setCandidateImport: Dispatch<SetStateAction<T>>;
}

const useCandidateImport = (
  id: number
): ICandidateImport<CandidateImportWithScrapedInfoResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateImportWithScrapedInfoResponse | null
  >(cadabraService.getCandidateImport, null, [id]);
  return {
    candidateImport: data,
    setCandidateImport: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCandidateImport;

import { IAsyncType, useUsers } from '.';
import { useEffect, useState } from 'react';

import { UserResponse } from '../../services/api';
import { auth } from '../../firebase';

export interface IMe<T> extends IAsyncType<T> {
  user: T;
}

const useMe = (): IMe<UserResponse | null> => {
  const { users, loading, setLoading, parameters, setParameters } = useUsers();
  const [user, setUser] = useState<UserResponse | null>(null);
  useEffect(() => {
    const currentUser = users.find((u) => u.email === auth.currentUser?.email);
    setUser(currentUser ?? null);
  }, [users]);

  return {
    user,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useMe;

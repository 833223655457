import { Box, InputAdornment, MenuItem, Popover, Typography } from '@mui/material';
import { COSCandidateAvatar, COSTextField } from '../../../../helpers/ui';
import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { CandidateResponse } from '../../../../services/api';
import { Search } from '@mui/icons-material';
import useCandidates from '../../../../helpers/hooks/useCandidates';
import { useFlag } from '../../../../helpers/hooks';
import { useNavigate } from 'react-router-dom';

const SearchBarComponent = () => {
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useFlag(false);
  const { candidates, setCandidates, setParameters } = useCandidates();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const onEnter = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
      if (search?.length > 0) {
        setParameters([search]);
      }
    },
    [search]
  );

  useEffect(() => {
    if (candidates?.length === 1) {
      handleRedirect(candidates[0]?.id);
    }
    if (search?.length > 0 && candidates.length !== 1) {
      setOpen(true);
    }
  }, [candidates, navigate]);

  const handleRedirect = (candidateId: number) => {
    setSearch('');
    setCandidates([]);
    setOpen(false);
    navigate(`/candidate?id=${candidateId}`);
  };

  const handleClose = () => {
    setOpen(false);
    setSearch('');
    setCandidates([]);
  };

  return (
    <Box>
      <COSTextField
        label="Search"
        value={search}
        onChange={(value) => setSearch(value)}
        onEnter={onEnter}
        sx={{
          '& label.Mui-focused': {
            color: '#FAFAFA'
          },
          '& label': {
            color: '#FAFAFA'
          },
          input: { color: '#FAFAFA' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#FAFAFA'
            },
            '&:hover fieldset': {
              borderColor: '#FAFAFA'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#FAFAFA'
            }
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ color: '#FAFAFA' }}>
              <Search />
            </InputAdornment>
          )
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        {candidates.map((candidate: CandidateResponse) => (
          <MenuItem
            key={candidate.id}
            value={candidate.id}
            onClick={() => handleRedirect(candidate.id)}
          >
            <Box>
              <Box sx={{ display: 'flex' }}>
                <COSCandidateAvatar
                  id={candidate.id}
                  name={candidate.name}
                  imagePath={candidate.imagePath}
                  tooltipPlacement="left"
                />
                <Typography sx={{ marginLeft: 1, marginY: 'auto' }}>{candidate.name}</Typography>
              </Box>
              <Box>{candidate.headline}</Box>
            </Box>
          </MenuItem>
        ))}
        {search?.length > 0 && candidates.length === 0 && (
          <MenuItem disabled={true}>No results</MenuItem>
        )}
      </Popover>
    </Box>
  );
};
export default SearchBarComponent;

import { TaskResponse } from '../services/api';
import moment from 'moment';

export default interface Task extends TaskResponse {
  pending: boolean;
}

export const toTask = (task: TaskResponse): Task => {
  const now = moment().subtract(1, 'days');
  return {
    ...task,
    pending: moment(task.reminderDate).isAfter(now),
    comment: task.comment?.replace('<span style="color: #ff6c00;">', '').replace('</span>', '')
  };
};

export const compareTasks = (task1: Task, task2: Task) => {
  if (task1.isActive && !task2.isActive) {
    return -1;
  }
  if (!task1.isActive && task2.isActive) {
    return 1;
  }
  return moment(task1.reminderDate).isAfter(task2.reminderDate) ? 1 : -1;
};

import { COSActivityCalendar, CalendarData } from '../../../helpers/ui';
import React, { useMemo } from 'react';

import { CandidateProjectEventLogResponse } from '../../../services/api';
import { CircularProgress } from '@mui/material';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

interface UserActivityCalendarComponentProps {
  eventLogs: CandidateProjectEventLogResponse[];
  loading: boolean;
  hideTotalCount?: boolean;
  hideColorLegend?: boolean;
}

const UserActivityCalendarComponent = ({
  eventLogs,
  loading,
  hideTotalCount = false,
  hideColorLegend = true
}: UserActivityCalendarComponentProps) => {
  const activities = useMemo(() => {
    const startDate = moment().subtract(2, 'month');
    const endDate = moment();
    const date: any = {};

    for (let m = moment(startDate); m.isBefore(endDate); m.add(1, 'days')) {
      date[m.format('YYYY-MM-DD')] = {
        date: m.format('YYYY-MM-DD'),
        count: 0,
        level: 0
      };
    }
    eventLogs.forEach((value: CandidateProjectEventLogResponse) => {
      const key = value.createdAt.substring(0, 10);
      if (date[key]) {
        const newCount = +date[key].count + 1;
        date[key] = { ...date[key], date: key, count: newCount, level: 0 };
      }
    });
    return Object.values(date).map((day: any) => ({ ...day, level: calculateLevel(day.count) }));
  }, [eventLogs]);

  return loading ? (
    <CircularProgress />
  ) : (
    <COSActivityCalendar
      data={activities as CalendarData}
      weekStart={1}
      fontSize={20}
      eventHandlers={{
        onClick: (event) => (data) => console.log(data)
      }}
      hideTotalCount={hideTotalCount}
      hideColorLegend={hideColorLegend}
    >
      <ReactTooltip html />
    </COSActivityCalendar>
  );
};

export default UserActivityCalendarComponent;

export const calculateLevel = (count: number) => {
  if (count === 0) {
    return 0;
  }
  if (count < 50) {
    return 1;
  }
  if (count < 60) {
    return 2;
  }
  return 3;
};

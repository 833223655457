import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateProjectEventLogResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IEventLogs<T> extends IAsyncType<T> {
  eventLogs: T;
  setEventLogs: Dispatch<SetStateAction<T>>;
}

const useEventLogs = (
  candidates?: number[],
  projects?: number[],
  users?: number[],
  types?: number[],
  fromDate?: string,
  toDate?: string,
  limit: number = 200,
  skip: number = 0
): IEventLogs<CandidateProjectEventLogResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateProjectEventLogResponse[]
  >(
    cadabraService.getCandidateProjectEventLogs,
    [],
    [candidates, projects, users, types, fromDate, toDate, limit, skip]
  );
  return {
    eventLogs: data,
    setEventLogs: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useEventLogs;

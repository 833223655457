import {
  AutocompleteRenderOptionState,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem
} from '@mui/material';
import { COSAsyncAutocomplete, COSAutocomplete, COSButton, Lookup } from '../../../helpers/ui';
import { CandidateResponse, CreateCandidateUserRelationRequest } from '../../../services/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RelationTypeKey, getRelationTypesByKey } from '../../../services/cadabraService';

import useCandidates from '../../../helpers/hooks/useCandidates';
import { useDebounce } from '@react-hook/debounce';

export interface UserCandidateRelationDialogProps {
  userId: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateCandidateUserRelationRequest) => void;
}

const UserCandidateRelationDialog = ({
  userId,
  open,
  onClose,
  onSave
}: UserCandidateRelationDialogProps) => {
  const [relationType, setRelationType] = useState<Lookup | null>(null);
  const [candidateValue, setCandidateValue] = useState<Lookup | null>(null);
  const [candidatesFilter, setCandidatesFilter] = useDebounce<string>('', 250);
  const { candidates, loading, setLoading, setParameters } = useCandidates();

  const types = useMemo(() => {
    const options = [
      { id: 'FIRST_CONNECTION', value: 'FIRST_CONNECTION' },
      { id: 'HAS_PHONE_PERMISSION', value: 'HAS_PHONE_PERMISSION' },
      { id: 'HAS_EMAIL_PERMISSION', value: 'HAS_EMAIL_PERMISSION' },
      { id: 'PERSONAL_FRIEND', value: 'PERSONAL_FRIEND' }
    ];
    setRelationType(options[0]);
    setLoading(false);
    return options;
  }, []);

  useEffect(() => {
    setParameters([candidatesFilter]);
  }, [candidatesFilter]);

  const isFormValid = useMemo(() => relationType && candidateValue, [relationType, candidateValue]);

  const handleOnClose = useCallback(() => {
    setRelationType(null);
    setCandidateValue(null);
    onClose();
  }, [onClose]);

  const handleOnSave = useCallback(() => {
    onSave({
      relationType: relationType?.id,
      candidateId: candidateValue?.id
    });
    handleOnClose();
  }, [relationType, candidateValue, onSave, handleOnClose]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">User Candidate Relation</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <COSAsyncAutocomplete<Lookup>
              label="Candidate"
              value={candidateValue}
              options={candidates.map((candidate: CandidateResponse) => ({
                id: candidate.id,
                value: candidate.name
              }))}
              loading={loading}
              onChange={(value: Lookup | null) => setCandidateValue(value)}
              minChar={0}
              filter={candidatesFilter}
              onChangeFilter={(candidatesFilter: string) => setCandidatesFilter(candidatesFilter)}
            />
          </Grid>
          <Grid item lg={12}>
            <COSAutocomplete<Lookup>
              label="Type"
              value={relationType}
              options={types}
              loading={loading}
              onChange={(value: Lookup | null) => setRelationType(value)}
              getOptionLabel={(option: Lookup) =>
                `${getRelationTypesByKey(option.value as RelationTypeKey).label}`
              }
              renderOption={(
                props: React.HTMLAttributes<HTMLLIElement>,
                option: Lookup,
                state: AutocompleteRenderOptionState
              ) => {
                return (
                  <ListItem {...props}>
                    {getRelationTypesByKey(option.value as RelationTypeKey).label}
                  </ListItem>
                );
              }}
              minChar={0}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <COSButton
          text="Save"
          onClick={handleOnSave}
          color="primary"
          variant="contained"
          disabled={!isFormValid}
        />
      </DialogActions>
    </Dialog>
  );
};

export default UserCandidateRelationDialog;

/* tslint:disable */
/* eslint-disable */
/**
 * Cadabra
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CandidateHistoryResponse
 */
export interface CandidateHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateHistoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateHistoryResponse
     */
    'candidateId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateHistoryResponse
     */
    'candidateName': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateHistoryResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateHistoryResponse
     */
    'companyName': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateHistoryResponse
     */
    'positionId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateHistoryResponse
     */
    'positionName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateHistoryResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateHistoryResponse
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface CandidateImportResponse
 */
export interface CandidateImportResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateImportResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateImportResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportResponse
     */
    'path': string;
}
/**
 * 
 * @export
 * @interface CandidateImportWithHistoryResponse
 */
export interface CandidateImportWithHistoryResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateImportWithHistoryResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateImportWithHistoryResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithHistoryResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithHistoryResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithHistoryResponse
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateImportWithHistoryResponse
     */
    'lastCreatedById'?: number;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithHistoryResponse
     */
    'lastCreatedAt'?: string;
}
/**
 * 
 * @export
 * @interface CandidateImportWithScrapedInfoResponse
 */
export interface CandidateImportWithScrapedInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'createdById': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'imageBase64': string;
    /**
     * 
     * @type {CandidateScrapedInfoResponse}
     * @memberof CandidateImportWithScrapedInfoResponse
     */
    'scrapedInfo': CandidateScrapedInfoResponse;
}
/**
 * 
 * @export
 * @interface CandidateInteractionsByUserResponse
 */
export interface CandidateInteractionsByUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateInteractionsByUserResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateInteractionsByUserResponse
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateInteractionsByUserResponse
     */
    'simpleInteractionType': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateInteractionsByUserResponse
     */
    'bestInteractionsCount': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateInteractionsByUserResponse
     */
    'targetInteractionsCount': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateInteractionsByUserResponse
     */
    'timePeriodType': string;
    /**
     * 
     * @type {Array<InteractionsDataResponse>}
     * @memberof CandidateInteractionsByUserResponse
     */
    'interactionsData': Array<InteractionsDataResponse>;
}
/**
 * 
 * @export
 * @interface CandidateInteractionsResponse
 */
export interface CandidateInteractionsResponse {
    /**
     * 
     * @type {string}
     * @memberof CandidateInteractionsResponse
     */
    'simpleInteractionType': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateInteractionsResponse
     */
    'bestInteractionsCount': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateInteractionsResponse
     */
    'targetInteractionsCount': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateInteractionsResponse
     */
    'timePeriodType': string;
    /**
     * 
     * @type {Array<InteractionsDataResponse>}
     * @memberof CandidateInteractionsResponse
     */
    'interactionsData': Array<InteractionsDataResponse>;
}
/**
 * 
 * @export
 * @interface CandidateProjectEventLogResponse
 */
export interface CandidateProjectEventLogResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateProjectEventLogResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateProjectEventLogResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'userName': string;
    /**
     * 
     * @type {number}
     * @memberof CandidateProjectEventLogResponse
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateProjectEventLogResponse
     */
    'candidateId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'candidateName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'positionName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectEventLogResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateProjectEventLogResponse
     */
    'allowedSubsequentInteractions': Array<string>;
}
/**
 * 
 * @export
 * @interface CandidateProjectResponse
 */
export interface CandidateProjectResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateProjectResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectResponse
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectResponse
     */
    'latestInteractionType': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateProjectResponse
     */
    'latestInteractionCreatedAt': string;
    /**
     * 
     * @type {Array<UserInteractionResponse>}
     * @memberof CandidateProjectResponse
     */
    'latestUserInteractions': Array<UserInteractionResponse>;
}
/**
 * 
 * @export
 * @interface CandidateResponse
 */
export interface CandidateResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'urlLinkedin'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'headline'?: string;
    /**
     * 
     * @type {Array<TechnologyResponse>}
     * @memberof CandidateResponse
     */
    'technologies': Array<TechnologyResponse>;
    /**
     * 
     * @type {Array<TechnologyResponse>}
     * @memberof CandidateResponse
     */
    'mainTechnologies': Array<TechnologyResponse>;
    /**
     * 
     * @type {Array<TechnologyResponse>}
     * @memberof CandidateResponse
     */
    'desiredTechnologies': Array<TechnologyResponse>;
    /**
     * 
     * @type {Array<CityResponse>}
     * @memberof CandidateResponse
     */
    'cities': Array<CityResponse>;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'imagePath'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'lastContactedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidateResponse
     */
    'legacyCandidateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CandidateResponse
     */
    'legacyOwnerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'legacyWorkInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'legacyKeySkills'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'legacyDateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateResponse
     */
    'legacyDateModified'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateResponse
     */
    'userRelations': Array<string>;
}
/**
 * 
 * @export
 * @interface CandidateScrapedInfoResponse
 */
export interface CandidateScrapedInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof CandidateScrapedInfoResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateScrapedInfoResponse
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateScrapedInfoResponse
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateScrapedInfoResponse
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateScrapedInfoResponse
     */
    'skills': Array<string>;
    /**
     * 
     * @type {Array<CandidateWorkHistoryScrapedInfo>}
     * @memberof CandidateScrapedInfoResponse
     */
    'workHistory': Array<CandidateWorkHistoryScrapedInfo>;
}
/**
 * 
 * @export
 * @interface CandidateUserRelationResponse
 */
export interface CandidateUserRelationResponse {
    /**
     * 
     * @type {number}
     * @memberof CandidateUserRelationResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CandidateUserRelationResponse
     */
    'candidateId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateUserRelationResponse
     */
    'candidateName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateUserRelationResponse
     */
    'candidateImagePath'?: string;
    /**
     * 
     * @type {number}
     * @memberof CandidateUserRelationResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof CandidateUserRelationResponse
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateUserRelationResponse
     */
    'relationType': string;
}
/**
 * 
 * @export
 * @interface CandidateWorkHistoryScrapedInfo
 */
export interface CandidateWorkHistoryScrapedInfo {
    /**
     * 
     * @type {string}
     * @memberof CandidateWorkHistoryScrapedInfo
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateWorkHistoryScrapedInfo
     */
    'positionName': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateWorkHistoryScrapedInfo
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CandidateWorkHistoryScrapedInfo
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface CityResponse
 */
export interface CityResponse {
    /**
     * 
     * @type {number}
     * @memberof CityResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CityResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ClientResponse
 */
export interface ClientResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'vatnumber': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'feePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'warrantyDays': number;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'invoiceName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ClientResponse
     */
    'legacyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'legacyNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'legacyContact'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'legacyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ClientSimpleResponse
 */
export interface ClientSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof ClientSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ClientSimpleResponse
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'vatnumber': string;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof ClientSimpleResponse
     */
    'feePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof ClientSimpleResponse
     */
    'warrantyDays': number;
    /**
     * 
     * @type {string}
     * @memberof ClientSimpleResponse
     */
    'invoiceName': string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientSimpleResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ClientSimpleResponse
     */
    'legacyId'?: number;
}
/**
 * 
 * @export
 * @interface CompanyResponse
 */
export interface CompanyResponse {
    /**
     * 
     * @type {number}
     * @memberof CompanyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateCandidateFromImportRequest
 */
export interface CreateCandidateFromImportRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateFromImportRequest
     */
    'candidateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateFromImportRequest
     */
    'candidateImportId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateFromImportRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateFromImportRequest
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateFromImportRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateFromImportRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateFromImportRequest
     */
    'urlLinkedin': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateFromImportRequest
     */
    'technologies': Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCandidateFromImportRequest
     */
    'newTechnologies': Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateFromImportRequest
     */
    'workHistory': Array<number>;
    /**
     * 
     * @type {Array<CreateCandidateHistoryFromImportRequest>}
     * @memberof CreateCandidateFromImportRequest
     */
    'newWorkHistory': Array<CreateCandidateHistoryFromImportRequest>;
}
/**
 * 
 * @export
 * @interface CreateCandidateHistoryFromImportRequest
 */
export interface CreateCandidateHistoryFromImportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryFromImportRequest
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryFromImportRequest
     */
    'positionName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryFromImportRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryFromImportRequest
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface CreateCandidateHistoryRequest
 */
export interface CreateCandidateHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateHistoryRequest
     */
    'candidateId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateHistoryRequest
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateHistoryRequest
     */
    'positionId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateHistoryRequest
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface CreateCandidateImportRequest
 */
export interface CreateCandidateImportRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateImportRequest
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateImportRequest
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateImportRequest
     */
    'imageBase64': string;
}
/**
 * 
 * @export
 * @interface CreateCandidateProjectEventLogRequest
 */
export interface CreateCandidateProjectEventLogRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateProjectEventLogRequest
     */
    'projectId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateProjectEventLogRequest
     */
    'candidateId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateProjectEventLogRequest
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateProjectEventLogRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface CreateCandidateRequest
 */
export interface CreateCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'urlLinkedin'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateRequest
     */
    'technologyIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateRequest
     */
    'mainTechnologyIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateRequest
     */
    'desiredTechnologyIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateCandidateRequest
     */
    'cityIds': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateRequest
     */
    'legacyCandidateId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateRequest
     */
    'legacyOwnerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'legacyWorkInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'legacyKeySkills'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'legacyDateCreated'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateRequest
     */
    'legacyDateModified'?: string;
}
/**
 * 
 * @export
 * @interface CreateCandidateUserRelationRequest
 */
export interface CreateCandidateUserRelationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCandidateUserRelationRequest
     */
    'relationType': string;
    /**
     * 
     * @type {number}
     * @memberof CreateCandidateUserRelationRequest
     */
    'candidateId': number;
}
/**
 * 
 * @export
 * @interface CreateCityRequest
 */
export interface CreateCityRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCityRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateClientRequest
 */
export interface CreateClientRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'vatnumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'address': string;
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'feePercentage': number;
    /**
     * 
     * @type {number}
     * @memberof CreateClientRequest
     */
    'warrantyDays': number;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'invoiceName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateClientRequest
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateClientRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateCompanyRequest
 */
export interface CreateCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreatePositionRequest
 */
export interface CreatePositionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePositionRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequest
     */
    'clientId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequest
     */
    'positionId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProjectRequest
     */
    'technologyIds': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateProjectRequest
     */
    'userIds': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequest
     */
    'openPositions': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequest
     */
    'isActive': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateTaskRequest
 */
export interface CreateTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof CreateTaskRequest
     */
    'candidateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'reminderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskRequest
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface CreateTechnologyRequest
 */
export interface CreateTechnologyRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateTechnologyRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateUserRequest
 */
export interface CreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequest
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserRequest
     */
    'isAdmin': boolean;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InteractionsDataResponse
 */
export interface InteractionsDataResponse {
    /**
     * 
     * @type {number}
     * @memberof InteractionsDataResponse
     */
    'interactionsCount': number;
    /**
     * 
     * @type {string}
     * @memberof InteractionsDataResponse
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof InteractionsDataResponse
     */
    'toDate': string;
}
/**
 * 
 * @export
 * @interface PatchCandidateHistoryRequest
 */
export interface PatchCandidateHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchCandidateHistoryRequest
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchCandidateHistoryRequest
     */
    'positionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateHistoryRequest
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateHistoryRequest
     */
    'endDate'?: string;
}
/**
 * 
 * @export
 * @interface PatchCandidateRequest
 */
export interface PatchCandidateRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'headline'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'urlLinkedin'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchCandidateRequest
     */
    'technologyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchCandidateRequest
     */
    'mainTechnologyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchCandidateRequest
     */
    'desiredTechnologyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchCandidateRequest
     */
    'cityIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PatchCandidateRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface PatchCityRequest
 */
export interface PatchCityRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchCityRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PatchClientRequest
 */
export interface PatchClientRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'vatnumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof PatchClientRequest
     */
    'feePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchClientRequest
     */
    'warrantyDays'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'invoiceName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchClientRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchClientRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PatchProjectRequest
 */
export interface PatchProjectRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchProjectRequest
     */
    'technologyIds'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchProjectRequest
     */
    'userIds'?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchProjectRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchProjectRequest
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PatchTaskRequest
 */
export interface PatchTaskRequest {
    /**
     * 
     * @type {number}
     * @memberof PatchTaskRequest
     */
    'candidateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchTaskRequest
     */
    'reminderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchTaskRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchTaskRequest
     */
    'isActive'?: boolean;
}
/**
 * 
 * @export
 * @interface PatchTechnologyRequest
 */
export interface PatchTechnologyRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchTechnologyRequest
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface PatchUserRequest
 */
export interface PatchUserRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUserRequest
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchUserRequest
     */
    'isAdmin'?: boolean;
}
/**
 * 
 * @export
 * @interface PositionResponse
 */
export interface PositionResponse {
    /**
     * 
     * @type {number}
     * @memberof PositionResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PositionResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'clientId': number;
    /**
     * 
     * @type {ClientResponse}
     * @memberof ProjectResponse
     */
    'client': ClientResponse;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'positionId': number;
    /**
     * 
     * @type {PositionResponse}
     * @memberof ProjectResponse
     */
    'position': PositionResponse;
    /**
     * 
     * @type {Array<TechnologyResponse>}
     * @memberof ProjectResponse
     */
    'technologies': Array<TechnologyResponse>;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof ProjectResponse
     */
    'users': Array<UserResponse>;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'openPositions': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectResponse
     */
    'legacyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'legacyNotes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface ProjectSimpleResponse
 */
export interface ProjectSimpleResponse {
    /**
     * 
     * @type {number}
     * @memberof ProjectSimpleResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSimpleResponse
     */
    'clientId': number;
    /**
     * 
     * @type {ClientResponse}
     * @memberof ProjectSimpleResponse
     */
    'client': ClientResponse;
    /**
     * 
     * @type {number}
     * @memberof ProjectSimpleResponse
     */
    'positionId': number;
    /**
     * 
     * @type {PositionResponse}
     * @memberof ProjectSimpleResponse
     */
    'position': PositionResponse;
    /**
     * 
     * @type {Array<TechnologyResponse>}
     * @memberof ProjectSimpleResponse
     */
    'technologies': Array<TechnologyResponse>;
    /**
     * 
     * @type {Array<UserResponse>}
     * @memberof ProjectSimpleResponse
     */
    'users': Array<UserResponse>;
    /**
     * 
     * @type {number}
     * @memberof ProjectSimpleResponse
     */
    'openPositions': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSimpleResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectSimpleResponse
     */
    'legacyId'?: number;
}
/**
 * 
 * @export
 * @interface TaskResponse
 */
export interface TaskResponse {
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof TaskResponse
     */
    'candidateId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    'candidateName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    'reminderDate': string;
    /**
     * 
     * @type {string}
     * @memberof TaskResponse
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskResponse
     */
    'isActive': boolean;
}
/**
 * 
 * @export
 * @interface TechnologyResponse
 */
export interface TechnologyResponse {
    /**
     * 
     * @type {number}
     * @memberof TechnologyResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof TechnologyResponse
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UserInteractionResponse
 */
export interface UserInteractionResponse {
    /**
     * 
     * @type {number}
     * @memberof UserInteractionResponse
     */
    'userId': number;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionResponse
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof UserInteractionResponse
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isActive': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserResponse
     */
    'isAdmin': boolean;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    'legacyId'?: number;
}
/**
 * 
 * @export
 * @interface UserStatsResponse
 */
export interface UserStatsResponse {
    /**
     * 
     * @type {string}
     * @memberof UserStatsResponse
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof UserStatsResponse
     */
    'count': number;
    /**
     * 
     * @type {number}
     * @memberof UserStatsResponse
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    'loc': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface WarrantyResponse
 */
export interface WarrantyResponse {
    /**
     * 
     * @type {number}
     * @memberof WarrantyResponse
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyResponse
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof WarrantyResponse
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof WarrantyResponse
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyResponse
     */
    'expirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyResponse
     */
    'warrantyStatus': string;
}

/**
 * CadabraApi - axios parameter creator
 * @export
 */
export const CadabraApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWarrantyV1WarrantyIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelWarrantyV1WarrantyIdDelete', 'id', id)
            const localVarPath = `/v1/warranty/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateFromImportV1CandidateExternalPost: async (createCandidateFromImportRequest: CreateCandidateFromImportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateFromImportRequest' is not null or undefined
            assertParamExists('createCandidateFromImportV1CandidateExternalPost', 'createCandidateFromImportRequest', createCandidateFromImportRequest)
            const localVarPath = `/v1/candidate/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateFromImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateHistoryV1CandidateHistoryPost: async (createCandidateHistoryRequest: CreateCandidateHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateHistoryRequest' is not null or undefined
            assertParamExists('createCandidateHistoryV1CandidateHistoryPost', 'createCandidateHistoryRequest', createCandidateHistoryRequest)
            const localVarPath = `/v1/candidate/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateImportV1CandidateImportPost: async (createCandidateImportRequest: CreateCandidateImportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateImportRequest' is not null or undefined
            assertParamExists('createCandidateImportV1CandidateImportPost', 'createCandidateImportRequest', createCandidateImportRequest)
            const localVarPath = `/v1/candidate-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateProjectEventLogV1CandidateProjectEventLogPost: async (createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateProjectEventLogRequest' is not null or undefined
            assertParamExists('createCandidateProjectEventLogV1CandidateProjectEventLogPost', 'createCandidateProjectEventLogRequest', createCandidateProjectEventLogRequest)
            const localVarPath = `/v1/candidate/project_event_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateProjectEventLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUserRelationV1CandidateRelationPost: async (createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateUserRelationRequest' is not null or undefined
            assertParamExists('createCandidateUserRelationV1CandidateRelationPost', 'createCandidateUserRelationRequest', createCandidateUserRelationRequest)
            const localVarPath = `/v1/candidate-relation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateUserRelationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateV1CandidatePost: async (createCandidateRequest: CreateCandidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateRequest' is not null or undefined
            assertParamExists('createCandidateV1CandidatePost', 'createCandidateRequest', createCandidateRequest)
            const localVarPath = `/v1/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityV1CityPost: async (createCityRequest: CreateCityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCityRequest' is not null or undefined
            assertParamExists('createCityV1CityPost', 'createCityRequest', createCityRequest)
            const localVarPath = `/v1/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientV1ClientPost: async (createClientRequest: CreateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequest' is not null or undefined
            assertParamExists('createClientV1ClientPost', 'createClientRequest', createClientRequest)
            const localVarPath = `/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyV1CompanyPost: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompanyV1CompanyPost', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPositionV1PositionPost: async (createPositionRequest: CreatePositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPositionRequest' is not null or undefined
            assertParamExists('createPositionV1PositionPost', 'createPositionRequest', createPositionRequest)
            const localVarPath = `/v1/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1ProjectPost: async (createProjectRequest: CreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('createProjectV1ProjectPost', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskV1TaskPost: async (createTaskRequest: CreateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskRequest' is not null or undefined
            assertParamExists('createTaskV1TaskPost', 'createTaskRequest', createTaskRequest)
            const localVarPath = `/v1/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTechnologyV1TechnologyPost: async (createTechnologyRequest: CreateTechnologyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTechnologyRequest' is not null or undefined
            assertParamExists('createTechnologyV1TechnologyPost', 'createTechnologyRequest', createTechnologyRequest)
            const localVarPath = `/v1/technology`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTechnologyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1UserPost: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUserV1UserPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateClientV1ClientIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateClientV1ClientIdDelete', 'id', id)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePositionV1PositionIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivatePositionV1PositionIdDelete', 'id', id)
            const localVarPath = `/v1/position/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTaskV1TaskIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateTaskV1TaskIdDelete', 'id', id)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateHistoryV1CandidateHistoryIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateHistoryV1CandidateHistoryIdDelete', 'id', id)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete', 'id', id)
            const localVarPath = `/v1/candidate/project_event_log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUserRelationV1CandidateRelationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateUserRelationV1CandidateRelationIdDelete', 'id', id)
            const localVarPath = `/v1/candidate-relation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCityV1CityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCityV1CityIdDelete', 'id', id)
            const localVarPath = `/v1/city/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportV1CandidateImportIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImportV1CandidateImportIdDelete', 'id', id)
            const localVarPath = `/v1/candidate-import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTechnologyV1TechnologyIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTechnologyV1TechnologyIdDelete', 'id', id)
            const localVarPath = `/v1/technology/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsByUserV1StatsUserIdGet: async (userId: number, startDate?: string, endDate?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCandidateInteractionsStatsByUserV1StatsUserIdGet', 'userId', userId)
            const localVarPath = `/v1/stats/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsV1StatsGet: async (startDate?: string, endDate?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet: async (projectId: number, createdDate?: string, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet', 'projectId', projectId)
            const localVarPath = `/v1/project/{project_id}/contacted`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (createdDate !== undefined) {
                localVarQueryParameter['created_date'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString().substr(0,10) :
                    createdDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet', 'projectId', projectId)
            const localVarPath = `/v1/project/{project_id}/in-process`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventLogsV1EventLogModelIdGet: async (model: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('getEventLogsV1EventLogModelIdGet', 'model', model)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEventLogsV1EventLogModelIdGet', 'id', id)
            const localVarPath = `/v1/event-log/{model}/{id}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateHistoryV1CandidateHistoryIdPatch: async (id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCandidateHistoryV1CandidateHistoryIdPatch', 'id', id)
            // verify required parameter 'patchCandidateHistoryRequest' is not null or undefined
            assertParamExists('patchCandidateHistoryV1CandidateHistoryIdPatch', 'patchCandidateHistoryRequest', patchCandidateHistoryRequest)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCandidateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateV1CandidateIdPatch: async (id: number, patchCandidateRequest: PatchCandidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCandidateV1CandidateIdPatch', 'id', id)
            // verify required parameter 'patchCandidateRequest' is not null or undefined
            assertParamExists('patchCandidateV1CandidateIdPatch', 'patchCandidateRequest', patchCandidateRequest)
            const localVarPath = `/v1/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCityV1CityIdPatch: async (id: number, patchCityRequest: PatchCityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCityV1CityIdPatch', 'id', id)
            // verify required parameter 'patchCityRequest' is not null or undefined
            assertParamExists('patchCityV1CityIdPatch', 'patchCityRequest', patchCityRequest)
            const localVarPath = `/v1/city/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectV1ProjectIdPatch: async (id: number, patchProjectRequest: PatchProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchProjectV1ProjectIdPatch', 'id', id)
            // verify required parameter 'patchProjectRequest' is not null or undefined
            assertParamExists('patchProjectV1ProjectIdPatch', 'patchProjectRequest', patchProjectRequest)
            const localVarPath = `/v1/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTechnologyV1TechnologyIdPatch: async (id: number, patchTechnologyRequest: PatchTechnologyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchTechnologyV1TechnologyIdPatch', 'id', id)
            // verify required parameter 'patchTechnologyRequest' is not null or undefined
            assertParamExists('patchTechnologyV1TechnologyIdPatch', 'patchTechnologyRequest', patchTechnologyRequest)
            const localVarPath = `/v1/technology/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTechnologyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateHistoryV1CandidateHistoryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateHistoryV1CandidateHistoryIdGet', 'id', id)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportV1CandidateImportIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateImportV1CandidateImportIdGet', 'id', id)
            const localVarPath = `/v1/candidate-import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportsV1CandidateImportGet: async (createdDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (createdDate !== undefined) {
                localVarQueryParameter['created_date'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString().substr(0,10) :
                    createdDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet: async (timePeriod: string, users?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/in-process-interactions-by-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet: async (timePeriod: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/in-process-interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateProjectEventLogsV1CandidateProjectEventLogGet: async (candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate/project_event_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (candidates !== undefined) {
                localVarQueryParameter['candidates'] = candidates;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet: async (timePeriod: string, users?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/search-interactions-by-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsV1StatsSearchInteractionsGet: async (timePeriod: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateSearchInteractionsV1StatsSearchInteractionsGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/search-interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateUserRelationsV1CandidateCandidateIdRelationsGet: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('readCandidateUserRelationsV1CandidateCandidateIdRelationsGet', 'candidateId', candidateId)
            const localVarPath = `/v1/candidate/{candidate_id}/relations`
                .replace(`{${"candidate_id"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateV1CandidateIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateV1CandidateIdGet', 'id', id)
            const localVarPath = `/v1/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesHistoryV1CandidateHistoryGet: async (limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesV1CandidateGet: async (search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (linkedinUrl !== undefined) {
                localVarQueryParameter['linkedin_url'] = linkedinUrl;
            }

            if (technologies !== undefined) {
                localVarQueryParameter['technologies'] = technologies;
            }

            if (desiredTechnologies !== undefined) {
                localVarQueryParameter['desired_technologies'] = desiredTechnologies;
            }

            if (mainTechnologies !== undefined) {
                localVarQueryParameter['main_technologies'] = mainTechnologies;
            }

            if (positions !== undefined) {
                localVarQueryParameter['positions'] = positions;
            }

            if (cities !== undefined) {
                localVarQueryParameter['cities'] = cities;
            }

            if (weeksSinceLastContact !== undefined) {
                localVarQueryParameter['weeks_since_last_contact'] = weeksSinceLastContact;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCitiesV1CityGet: async (showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientV1ClientIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientV1ClientIdGet', 'id', id)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsV1ClientGet: async (search?: string, showInactive?: boolean, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesV1CompanyGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readImageV1ImageImagePathGet: async (imagePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagePath' is not null or undefined
            assertParamExists('readImageV1ImageImagePathGet', 'imagePath', imagePath)
            const localVarPath = `/v1/image/{image_path}`
                .replace(`{${"image_path"}}`, encodeURIComponent(String(imagePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPositionsV1PositionGet: async (showInactive?: boolean, search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectV1ProjectIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readProjectV1ProjectIdGet', 'id', id)
            const localVarPath = `/v1/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectsV1ProjectGet: async (search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (optionalTechnologyIds !== undefined) {
                localVarQueryParameter['optional_technology_ids'] = optionalTechnologyIds;
            }

            if (requiredTechnologyIds !== undefined) {
                localVarQueryParameter['required_technology_ids'] = requiredTechnologyIds;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaskV1TaskIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readTaskV1TaskIdGet', 'id', id)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTasksV1TaskGet: async (candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (candidateId !== undefined) {
                localVarQueryParameter['candidate_id'] = candidateId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTechnologiesV1TechnologyGet: async (showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/technology`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (names !== undefined) {
                localVarQueryParameter['names'] = names;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCandidateRelationsV1UserUserIdRelationsGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('readUserCandidateRelationsV1UserUserIdRelationsGet', 'userId', userId)
            const localVarPath = `/v1/user/{user_id}/relations`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserV1UserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readUserV1UserIdGet', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersV1UserGet: async (showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (isAdmin !== undefined) {
                localVarQueryParameter['is_admin'] = isAdmin;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantiesV1WarrantyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/warranty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantyV1WarrantyIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readWarrantyV1WarrantyIdGet', 'id', id)
            const localVarPath = `/v1/warranty/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientV1ClientIdPatch: async (id: number, patchClientRequest: PatchClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientV1ClientIdPatch', 'id', id)
            // verify required parameter 'patchClientRequest' is not null or undefined
            assertParamExists('updateClientV1ClientIdPatch', 'patchClientRequest', patchClientRequest)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskV1TaskIdPatch: async (id: number, patchTaskRequest: PatchTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskV1TaskIdPatch', 'id', id)
            // verify required parameter 'patchTaskRequest' is not null or undefined
            assertParamExists('updateTaskV1TaskIdPatch', 'patchTaskRequest', patchTaskRequest)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1UserIdPatch: async (id: number, patchUserRequest: PatchUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserV1UserIdPatch', 'id', id)
            // verify required parameter 'patchUserRequest' is not null or undefined
            assertParamExists('updateUserV1UserIdPatch', 'patchUserRequest', patchUserRequest)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CadabraApi - functional programming interface
 * @export
 */
export const CadabraApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CadabraApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWarrantyV1WarrantyIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelWarrantyV1WarrantyIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportWithHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateProjectEventLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUserRelationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateV1CandidatePost(createCandidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCityV1CityPost(createCityRequest: CreateCityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCityV1CityPost(createCityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientV1ClientPost(createClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyV1CompanyPost(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPositionV1PositionPost(createPositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectV1ProjectPost(createProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskV1TaskPost(createTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTechnologyV1TechnologyPost(createTechnologyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserV1UserPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserV1UserPost(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateClientV1ClientIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateClientV1ClientIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePositionV1PositionIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePositionV1PositionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateTaskV1TaskIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateTaskV1TaskIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateProjectEventLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUserRelationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCityV1CityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCityV1CityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImportV1CandidateImportIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImportV1CandidateImportIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTechnologyV1TechnologyIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTechnologyV1TechnologyIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserStatsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserStatsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventLogsV1EventLogModelIdGet(model, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCityV1CityIdPatch(id, patchCityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectV1ProjectIdPatch(id, patchProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateHistoryV1CandidateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateImportV1CandidateImportIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportWithScrapedInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateImportV1CandidateImportIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateImportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateImportsV1CandidateImportGet(createdDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsByUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectEventLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsByUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUserRelationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateV1CandidateIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateV1CandidateIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCitiesV1CityGet(showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCitiesV1CityGet(showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientV1ClientIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientV1ClientIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientsV1ClientGet(search, showInactive, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompaniesV1CompanyGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readImageV1ImageImagePathGet(imagePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readImageV1ImageImagePathGet(imagePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPositionsV1PositionGet(showInactive, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readProjectV1ProjectIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readProjectV1ProjectIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTaskV1TaskIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTaskV1TaskIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnologyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUserRelationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserV1UserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserV1UserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarrantiesV1WarrantyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarrantiesV1WarrantyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarrantyV1WarrantyIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarrantyV1WarrantyIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientV1ClientIdPatch(id, patchClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskV1TaskIdPatch(id, patchTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserV1UserIdPatch(id, patchUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CadabraApi - factory interface
 * @export
 */
export const CadabraApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CadabraApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWarrantyV1WarrantyIdDelete(id: number, options?: any): AxiosPromise<WarrantyResponse> {
            return localVarFp.cancelWarrantyV1WarrantyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: any): AxiosPromise<CandidateImportWithHistoryResponse> {
            return localVarFp.createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: any): AxiosPromise<CandidateProjectEventLogResponse> {
            return localVarFp.createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: any): AxiosPromise<CandidateUserRelationResponse> {
            return localVarFp.createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.createCandidateV1CandidatePost(createCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityV1CityPost(createCityRequest: CreateCityRequest, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.createCityV1CityPost(createCityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.createClientV1ClientPost(createClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompanyV1CompanyPost(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: any): AxiosPromise<PositionResponse> {
            return localVarFp.createPositionV1PositionPost(createPositionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.createProjectV1ProjectPost(createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.createTaskV1TaskPost(createTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.createTechnologyV1TechnologyPost(createTechnologyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1UserPost(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserV1UserPost(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateClientV1ClientIdDelete(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.deactivateClientV1ClientIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePositionV1PositionIdDelete(id: number, options?: any): AxiosPromise<PositionResponse> {
            return localVarFp.deactivatePositionV1PositionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTaskV1TaskIdDelete(id: number, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.deactivateTaskV1TaskIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: any): AxiosPromise<CandidateProjectEventLogResponse> {
            return localVarFp.deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: any): AxiosPromise<CandidateUserRelationResponse> {
            return localVarFp.deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCityV1CityIdDelete(id: number, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.deleteCityV1CityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportV1CandidateImportIdDelete(id: number, options?: any): AxiosPromise<CandidateImportResponse> {
            return localVarFp.deleteImportV1CandidateImportIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTechnologyV1TechnologyIdDelete(id: number, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.deleteTechnologyV1TechnologyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: any): AxiosPromise<Array<UserStatsResponse>> {
            return localVarFp.getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: any): AxiosPromise<Array<UserStatsResponse>> {
            return localVarFp.getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateProjectResponse>> {
            return localVarFp.getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: any): AxiosPromise<Array<CandidateProjectResponse>> {
            return localVarFp.getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getEventLogsV1EventLogModelIdGet(model, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.patchCityV1CityIdPatch(id, patchCityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.patchProjectV1ProjectIdPatch(id, patchProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: any): AxiosPromise<Array<CandidateHistoryResponse>> {
            return localVarFp.readCandidateHistoryV1CandidateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportV1CandidateImportIdGet(id: number, options?: any): AxiosPromise<CandidateImportWithScrapedInfoResponse> {
            return localVarFp.readCandidateImportV1CandidateImportIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: any): AxiosPromise<Array<CandidateImportResponse>> {
            return localVarFp.readCandidateImportsV1CandidateImportGet(createdDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsByUserResponse>> {
            return localVarFp.readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsResponse>> {
            return localVarFp.readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateProjectEventLogResponse>> {
            return localVarFp.readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsByUserResponse>> {
            return localVarFp.readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsResponse>> {
            return localVarFp.readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: any): AxiosPromise<Array<CandidateUserRelationResponse>> {
            return localVarFp.readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateV1CandidateIdGet(id: number, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.readCandidateV1CandidateIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateHistoryResponse>> {
            return localVarFp.readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateResponse>> {
            return localVarFp.readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCitiesV1CityGet(showInactive?: boolean, options?: any): AxiosPromise<Array<CityResponse>> {
            return localVarFp.readCitiesV1CityGet(showInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientV1ClientIdGet(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.readClientV1ClientIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<Array<ClientSimpleResponse>> {
            return localVarFp.readClientsV1ClientGet(search, showInactive, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyResponse>> {
            return localVarFp.readCompaniesV1CompanyGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readImageV1ImageImagePathGet(imagePath: string, options?: any): AxiosPromise<any> {
            return localVarFp.readImageV1ImageImagePathGet(imagePath, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PositionResponse>> {
            return localVarFp.readPositionsV1PositionGet(showInactive, search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectV1ProjectIdGet(id: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.readProjectV1ProjectIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<Array<ProjectSimpleResponse>> {
            return localVarFp.readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaskV1TaskIdGet(id: number, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.readTaskV1TaskIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: any): AxiosPromise<Array<TaskResponse>> {
            return localVarFp.readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<TechnologyResponse>> {
            return localVarFp.readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: any): AxiosPromise<Array<CandidateUserRelationResponse>> {
            return localVarFp.readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserV1UserIdGet(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.readUserV1UserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantiesV1WarrantyGet(options?: any): AxiosPromise<Array<WarrantyResponse>> {
            return localVarFp.readWarrantiesV1WarrantyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantyV1WarrantyIdGet(id: number, options?: any): AxiosPromise<WarrantyResponse> {
            return localVarFp.readWarrantyV1WarrantyIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.updateClientV1ClientIdPatch(id, patchClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.updateTaskV1TaskIdPatch(id, patchTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserV1UserIdPatch(id, patchUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CadabraApi - object-oriented interface
 * @export
 * @class CadabraApi
 * @extends {BaseAPI}
 */
export class CadabraApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Warranty
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public cancelWarrantyV1WarrantyIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).cancelWarrantyV1WarrantyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate From Import
     * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate History
     * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate Import
     * @param {CreateCandidateImportRequest} createCandidateImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate Project Event Log
     * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate User Relation
     * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate
     * @param {CreateCandidateRequest} createCandidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCandidateV1CandidatePost(createCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create City
     * @param {CreateCityRequest} createCityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCityV1CityPost(createCityRequest: CreateCityRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCityV1CityPost(createCityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Client
     * @param {CreateClientRequest} createClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createClientV1ClientPost(createClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Company
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createCompanyV1CompanyPost(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Position
     * @param {CreatePositionRequest} createPositionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createPositionV1PositionPost(createPositionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Project
     * @param {CreateProjectRequest} createProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createProjectV1ProjectPost(createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Task
     * @param {CreateTaskRequest} createTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createTaskV1TaskPost(createTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Technology
     * @param {CreateTechnologyRequest} createTechnologyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createTechnologyV1TechnologyPost(createTechnologyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public createUserV1UserPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).createUserV1UserPost(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deactivateClientV1ClientIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deactivateClientV1ClientIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Position
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deactivatePositionV1PositionIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deactivatePositionV1PositionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deactivateTaskV1TaskIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deactivateTaskV1TaskIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate Project Event Log
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate User Relation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete City
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteCityV1CityIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteCityV1CityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Import
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteImportV1CandidateImportIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteImportV1CandidateImportIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Technology
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public deleteTechnologyV1TechnologyIdDelete(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).deleteTechnologyV1TechnologyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidate Interactions Stats By User
     * @param {number} userId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidate Interactions Stats
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidates By Project And Last Interaction Contacted
     * @param {number} projectId 
     * @param {string} [createdDate] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidates By Project And Last Interaction In Process
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Event Logs
     * @param {string} model 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).getEventLogsV1EventLogModelIdGet(model, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Candidate History
     * @param {number} id 
     * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Candidate
     * @param {number} id 
     * @param {PatchCandidateRequest} patchCandidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch City
     * @param {number} id 
     * @param {PatchCityRequest} patchCityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).patchCityV1CityIdPatch(id, patchCityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Project
     * @param {number} id 
     * @param {PatchProjectRequest} patchProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).patchProjectV1ProjectIdPatch(id, patchProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Technology
     * @param {number} id 
     * @param {PatchTechnologyRequest} patchTechnologyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateHistoryV1CandidateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Import
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateImportV1CandidateImportIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateImportV1CandidateImportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Imports
     * @param {string} [createdDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateImportsV1CandidateImportGet(createdDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate In Process Interactions By User
     * @param {string} timePeriod 
     * @param {string} [users] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate In Process Interactions
     * @param {string} timePeriod 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Project Event Logs
     * @param {string} [candidates] 
     * @param {string} [projects] 
     * @param {string} [users] 
     * @param {string} [types] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Search Interactions By Users
     * @param {string} timePeriod 
     * @param {string} [users] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Search Interactions
     * @param {string} timePeriod 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate User Relations
     * @param {number} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidateV1CandidateIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidateV1CandidateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidates History
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidates
     * @param {string} [search] 
     * @param {string} [linkedinUrl] 
     * @param {string} [technologies] 
     * @param {string} [desiredTechnologies] 
     * @param {string} [mainTechnologies] 
     * @param {string} [positions] 
     * @param {string} [cities] 
     * @param {number} [weeksSinceLastContact] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Cities
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCitiesV1CityGet(showInactive?: boolean, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCitiesV1CityGet(showInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readClientV1ClientIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readClientV1ClientIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Clients
     * @param {string} [search] 
     * @param {boolean} [showInactive] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readClientsV1ClientGet(search, showInactive, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Companies
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readCompaniesV1CompanyGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Image
     * @param {string} imagePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readImageV1ImageImagePathGet(imagePath: string, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readImageV1ImageImagePathGet(imagePath, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Positions
     * @param {boolean} [showInactive] 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readPositionsV1PositionGet(showInactive, search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readProjectV1ProjectIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readProjectV1ProjectIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Projects
     * @param {string} [search] 
     * @param {string} [optionalTechnologyIds] 
     * @param {string} [requiredTechnologyIds] 
     * @param {boolean} [showInactive] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readTaskV1TaskIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readTaskV1TaskIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Tasks
     * @param {number} [candidateId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Technologies
     * @param {boolean} [showInactive] 
     * @param {string} [search] 
     * @param {string} [names] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User Candidate Relations
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readUserV1UserIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readUserV1UserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users
     * @param {boolean} [showInactive] 
     * @param {boolean} [isAdmin] 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warranties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readWarrantiesV1WarrantyGet(options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readWarrantiesV1WarrantyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warranty
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public readWarrantyV1WarrantyIdGet(id: number, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).readWarrantyV1WarrantyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Client
     * @param {number} id 
     * @param {PatchClientRequest} patchClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).updateClientV1ClientIdPatch(id, patchClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Task
     * @param {number} id 
     * @param {PatchTaskRequest} patchTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).updateTaskV1TaskIdPatch(id, patchTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {number} id 
     * @param {PatchUserRequest} patchUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CadabraApi
     */
    public updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: AxiosRequestConfig) {
        return CadabraApiFp(this.configuration).updateUserV1UserIdPatch(id, patchUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidateApi - axios parameter creator
 * @export
 */
export const CandidateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateFromImportV1CandidateExternalPost: async (createCandidateFromImportRequest: CreateCandidateFromImportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateFromImportRequest' is not null or undefined
            assertParamExists('createCandidateFromImportV1CandidateExternalPost', 'createCandidateFromImportRequest', createCandidateFromImportRequest)
            const localVarPath = `/v1/candidate/external`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateFromImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateHistoryV1CandidateHistoryPost: async (createCandidateHistoryRequest: CreateCandidateHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateHistoryRequest' is not null or undefined
            assertParamExists('createCandidateHistoryV1CandidateHistoryPost', 'createCandidateHistoryRequest', createCandidateHistoryRequest)
            const localVarPath = `/v1/candidate/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateProjectEventLogV1CandidateProjectEventLogPost: async (createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateProjectEventLogRequest' is not null or undefined
            assertParamExists('createCandidateProjectEventLogV1CandidateProjectEventLogPost', 'createCandidateProjectEventLogRequest', createCandidateProjectEventLogRequest)
            const localVarPath = `/v1/candidate/project_event_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateProjectEventLogRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateV1CandidatePost: async (createCandidateRequest: CreateCandidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateRequest' is not null or undefined
            assertParamExists('createCandidateV1CandidatePost', 'createCandidateRequest', createCandidateRequest)
            const localVarPath = `/v1/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateHistoryV1CandidateHistoryIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateHistoryV1CandidateHistoryIdDelete', 'id', id)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete', 'id', id)
            const localVarPath = `/v1/candidate/project_event_log/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateHistoryV1CandidateHistoryIdPatch: async (id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCandidateHistoryV1CandidateHistoryIdPatch', 'id', id)
            // verify required parameter 'patchCandidateHistoryRequest' is not null or undefined
            assertParamExists('patchCandidateHistoryV1CandidateHistoryIdPatch', 'patchCandidateHistoryRequest', patchCandidateHistoryRequest)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCandidateHistoryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateV1CandidateIdPatch: async (id: number, patchCandidateRequest: PatchCandidateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCandidateV1CandidateIdPatch', 'id', id)
            // verify required parameter 'patchCandidateRequest' is not null or undefined
            assertParamExists('patchCandidateV1CandidateIdPatch', 'patchCandidateRequest', patchCandidateRequest)
            const localVarPath = `/v1/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCandidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateHistoryV1CandidateHistoryIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateHistoryV1CandidateHistoryIdGet', 'id', id)
            const localVarPath = `/v1/candidate/history/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateProjectEventLogsV1CandidateProjectEventLogGet: async (candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate/project_event_log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (candidates !== undefined) {
                localVarQueryParameter['candidates'] = candidates;
            }

            if (projects !== undefined) {
                localVarQueryParameter['projects'] = projects;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (types !== undefined) {
                localVarQueryParameter['types'] = types;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateUserRelationsV1CandidateCandidateIdRelationsGet: async (candidateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'candidateId' is not null or undefined
            assertParamExists('readCandidateUserRelationsV1CandidateCandidateIdRelationsGet', 'candidateId', candidateId)
            const localVarPath = `/v1/candidate/{candidate_id}/relations`
                .replace(`{${"candidate_id"}}`, encodeURIComponent(String(candidateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateV1CandidateIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateV1CandidateIdGet', 'id', id)
            const localVarPath = `/v1/candidate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesHistoryV1CandidateHistoryGet: async (limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate/history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesV1CandidateGet: async (search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (linkedinUrl !== undefined) {
                localVarQueryParameter['linkedin_url'] = linkedinUrl;
            }

            if (technologies !== undefined) {
                localVarQueryParameter['technologies'] = technologies;
            }

            if (desiredTechnologies !== undefined) {
                localVarQueryParameter['desired_technologies'] = desiredTechnologies;
            }

            if (mainTechnologies !== undefined) {
                localVarQueryParameter['main_technologies'] = mainTechnologies;
            }

            if (positions !== undefined) {
                localVarQueryParameter['positions'] = positions;
            }

            if (cities !== undefined) {
                localVarQueryParameter['cities'] = cities;
            }

            if (weeksSinceLastContact !== undefined) {
                localVarQueryParameter['weeks_since_last_contact'] = weeksSinceLastContact;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readImageV1ImageImagePathGet: async (imagePath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imagePath' is not null or undefined
            assertParamExists('readImageV1ImageImagePathGet', 'imagePath', imagePath)
            const localVarPath = `/v1/image/{image_path}`
                .replace(`{${"image_path"}}`, encodeURIComponent(String(imagePath)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateApi - functional programming interface
 * @export
 */
export const CandidateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateProjectEventLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateV1CandidatePost(createCandidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateProjectEventLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateHistoryV1CandidateHistoryIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectEventLogResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUserRelationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateV1CandidateIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateV1CandidateIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateHistoryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readImageV1ImageImagePathGet(imagePath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readImageV1ImageImagePathGet(imagePath, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidateApi - factory interface
 * @export
 */
export const CandidateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidateApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Candidate From Import
         * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate History
         * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate Project Event Log
         * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: any): AxiosPromise<CandidateProjectEventLogResponse> {
            return localVarFp.createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Candidate
         * @param {CreateCandidateRequest} createCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.createCandidateV1CandidatePost(createCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate Project Event Log
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: any): AxiosPromise<CandidateProjectEventLogResponse> {
            return localVarFp.deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Candidate History
         * @param {number} id 
         * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: any): AxiosPromise<CandidateHistoryResponse> {
            return localVarFp.patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Candidate
         * @param {number} id 
         * @param {PatchCandidateRequest} patchCandidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate History
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: any): AxiosPromise<Array<CandidateHistoryResponse>> {
            return localVarFp.readCandidateHistoryV1CandidateHistoryIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Project Event Logs
         * @param {string} [candidates] 
         * @param {string} [projects] 
         * @param {string} [users] 
         * @param {string} [types] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateProjectEventLogResponse>> {
            return localVarFp.readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate User Relations
         * @param {number} candidateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: any): AxiosPromise<Array<CandidateUserRelationResponse>> {
            return localVarFp.readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateV1CandidateIdGet(id: number, options?: any): AxiosPromise<CandidateResponse> {
            return localVarFp.readCandidateV1CandidateIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidates History
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateHistoryResponse>> {
            return localVarFp.readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidates
         * @param {string} [search] 
         * @param {string} [linkedinUrl] 
         * @param {string} [technologies] 
         * @param {string} [desiredTechnologies] 
         * @param {string} [mainTechnologies] 
         * @param {string} [positions] 
         * @param {string} [cities] 
         * @param {number} [weeksSinceLastContact] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateResponse>> {
            return localVarFp.readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Image
         * @param {string} imagePath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readImageV1ImageImagePathGet(imagePath: string, options?: any): AxiosPromise<any> {
            return localVarFp.readImageV1ImageImagePathGet(imagePath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidateApi - object-oriented interface
 * @export
 * @class CandidateApi
 * @extends {BaseAPI}
 */
export class CandidateApi extends BaseAPI {
    /**
     * 
     * @summary Create Candidate From Import
     * @param {CreateCandidateFromImportRequest} createCandidateFromImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest: CreateCandidateFromImportRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).createCandidateFromImportV1CandidateExternalPost(createCandidateFromImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate History
     * @param {CreateCandidateHistoryRequest} createCandidateHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest: CreateCandidateHistoryRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate Project Event Log
     * @param {CreateCandidateProjectEventLogRequest} createCandidateProjectEventLogRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).createCandidateProjectEventLogV1CandidateProjectEventLogPost(createCandidateProjectEventLogRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Candidate
     * @param {CreateCandidateRequest} createCandidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public createCandidateV1CandidatePost(createCandidateRequest: CreateCandidateRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).createCandidateV1CandidatePost(createCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public deleteCandidateHistoryV1CandidateHistoryIdDelete(id: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).deleteCandidateHistoryV1CandidateHistoryIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate Project Event Log
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Candidate History
     * @param {number} id 
     * @param {PatchCandidateHistoryRequest} patchCandidateHistoryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public patchCandidateHistoryV1CandidateHistoryIdPatch(id: number, patchCandidateHistoryRequest: PatchCandidateHistoryRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).patchCandidateHistoryV1CandidateHistoryIdPatch(id, patchCandidateHistoryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Candidate
     * @param {number} id 
     * @param {PatchCandidateRequest} patchCandidateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public patchCandidateV1CandidateIdPatch(id: number, patchCandidateRequest: PatchCandidateRequest, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).patchCandidateV1CandidateIdPatch(id, patchCandidateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate History
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidateHistoryV1CandidateHistoryIdGet(id: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidateHistoryV1CandidateHistoryIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Project Event Logs
     * @param {string} [candidates] 
     * @param {string} [projects] 
     * @param {string} [users] 
     * @param {string} [types] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates?: string, projects?: string, users?: string, types?: string, fromDate?: string, toDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidateProjectEventLogsV1CandidateProjectEventLogGet(candidates, projects, users, types, fromDate, toDate, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate User Relations
     * @param {number} candidateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(candidateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidateV1CandidateIdGet(id: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidateV1CandidateIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidates History
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidatesHistoryV1CandidateHistoryGet(limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidatesHistoryV1CandidateHistoryGet(limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidates
     * @param {string} [search] 
     * @param {string} [linkedinUrl] 
     * @param {string} [technologies] 
     * @param {string} [desiredTechnologies] 
     * @param {string} [mainTechnologies] 
     * @param {string} [positions] 
     * @param {string} [cities] 
     * @param {number} [weeksSinceLastContact] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readCandidatesV1CandidateGet(search?: string, linkedinUrl?: string, technologies?: string, desiredTechnologies?: string, mainTechnologies?: string, positions?: string, cities?: string, weeksSinceLastContact?: number, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readCandidatesV1CandidateGet(search, linkedinUrl, technologies, desiredTechnologies, mainTechnologies, positions, cities, weeksSinceLastContact, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Image
     * @param {string} imagePath 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateApi
     */
    public readImageV1ImageImagePathGet(imagePath: string, options?: AxiosRequestConfig) {
        return CandidateApiFp(this.configuration).readImageV1ImageImagePathGet(imagePath, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidateImportApi - axios parameter creator
 * @export
 */
export const CandidateImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateImportV1CandidateImportPost: async (createCandidateImportRequest: CreateCandidateImportRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateImportRequest' is not null or undefined
            assertParamExists('createCandidateImportV1CandidateImportPost', 'createCandidateImportRequest', createCandidateImportRequest)
            const localVarPath = `/v1/candidate-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateImportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportV1CandidateImportIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImportV1CandidateImportIdDelete', 'id', id)
            const localVarPath = `/v1/candidate-import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportV1CandidateImportIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readCandidateImportV1CandidateImportIdGet', 'id', id)
            const localVarPath = `/v1/candidate-import/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportsV1CandidateImportGet: async (createdDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/candidate-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (createdDate !== undefined) {
                localVarQueryParameter['created_date'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString().substr(0,10) :
                    createdDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateImportApi - functional programming interface
 * @export
 */
export const CandidateImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidateImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportWithHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImportV1CandidateImportIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImportV1CandidateImportIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateImportV1CandidateImportIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateImportWithScrapedInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateImportV1CandidateImportIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateImportResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateImportsV1CandidateImportGet(createdDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidateImportApi - factory interface
 * @export
 */
export const CandidateImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidateImportApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Candidate Import
         * @param {CreateCandidateImportRequest} createCandidateImportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: any): AxiosPromise<CandidateImportWithHistoryResponse> {
            return localVarFp.createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImportV1CandidateImportIdDelete(id: number, options?: any): AxiosPromise<CandidateImportResponse> {
            return localVarFp.deleteImportV1CandidateImportIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Import
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportV1CandidateImportIdGet(id: number, options?: any): AxiosPromise<CandidateImportWithScrapedInfoResponse> {
            return localVarFp.readCandidateImportV1CandidateImportIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Imports
         * @param {string} [createdDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: any): AxiosPromise<Array<CandidateImportResponse>> {
            return localVarFp.readCandidateImportsV1CandidateImportGet(createdDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidateImportApi - object-oriented interface
 * @export
 * @class CandidateImportApi
 * @extends {BaseAPI}
 */
export class CandidateImportApi extends BaseAPI {
    /**
     * 
     * @summary Create Candidate Import
     * @param {CreateCandidateImportRequest} createCandidateImportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateImportApi
     */
    public createCandidateImportV1CandidateImportPost(createCandidateImportRequest: CreateCandidateImportRequest, options?: AxiosRequestConfig) {
        return CandidateImportApiFp(this.configuration).createCandidateImportV1CandidateImportPost(createCandidateImportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Import
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateImportApi
     */
    public deleteImportV1CandidateImportIdDelete(id: number, options?: AxiosRequestConfig) {
        return CandidateImportApiFp(this.configuration).deleteImportV1CandidateImportIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Import
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateImportApi
     */
    public readCandidateImportV1CandidateImportIdGet(id: number, options?: AxiosRequestConfig) {
        return CandidateImportApiFp(this.configuration).readCandidateImportV1CandidateImportIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Imports
     * @param {string} [createdDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateImportApi
     */
    public readCandidateImportsV1CandidateImportGet(createdDate?: string, options?: AxiosRequestConfig) {
        return CandidateImportApiFp(this.configuration).readCandidateImportsV1CandidateImportGet(createdDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CandidateRelationApi - axios parameter creator
 * @export
 */
export const CandidateRelationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUserRelationV1CandidateRelationPost: async (createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCandidateUserRelationRequest' is not null or undefined
            assertParamExists('createCandidateUserRelationV1CandidateRelationPost', 'createCandidateUserRelationRequest', createCandidateUserRelationRequest)
            const localVarPath = `/v1/candidate-relation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCandidateUserRelationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUserRelationV1CandidateRelationIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCandidateUserRelationV1CandidateRelationIdDelete', 'id', id)
            const localVarPath = `/v1/candidate-relation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CandidateRelationApi - functional programming interface
 * @export
 */
export const CandidateRelationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CandidateRelationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUserRelationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CandidateUserRelationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CandidateRelationApi - factory interface
 * @export
 */
export const CandidateRelationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CandidateRelationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Candidate User Relation
         * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: any): AxiosPromise<CandidateUserRelationResponse> {
            return localVarFp.createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Candidate User Relation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: any): AxiosPromise<CandidateUserRelationResponse> {
            return localVarFp.deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CandidateRelationApi - object-oriented interface
 * @export
 * @class CandidateRelationApi
 * @extends {BaseAPI}
 */
export class CandidateRelationApi extends BaseAPI {
    /**
     * 
     * @summary Create Candidate User Relation
     * @param {CreateCandidateUserRelationRequest} createCandidateUserRelationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateRelationApi
     */
    public createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest: CreateCandidateUserRelationRequest, options?: AxiosRequestConfig) {
        return CandidateRelationApiFp(this.configuration).createCandidateUserRelationV1CandidateRelationPost(createCandidateUserRelationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Candidate User Relation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CandidateRelationApi
     */
    public deleteCandidateUserRelationV1CandidateRelationIdDelete(id: number, options?: AxiosRequestConfig) {
        return CandidateRelationApiFp(this.configuration).deleteCandidateUserRelationV1CandidateRelationIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityV1CityPost: async (createCityRequest: CreateCityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCityRequest' is not null or undefined
            assertParamExists('createCityV1CityPost', 'createCityRequest', createCityRequest)
            const localVarPath = `/v1/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCityV1CityIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCityV1CityIdDelete', 'id', id)
            const localVarPath = `/v1/city/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCityV1CityIdPatch: async (id: number, patchCityRequest: PatchCityRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchCityV1CityIdPatch', 'id', id)
            // verify required parameter 'patchCityRequest' is not null or undefined
            assertParamExists('patchCityV1CityIdPatch', 'patchCityRequest', patchCityRequest)
            const localVarPath = `/v1/city/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCitiesV1CityGet: async (showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/city`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCityV1CityPost(createCityRequest: CreateCityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCityV1CityPost(createCityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCityV1CityIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCityV1CityIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCityV1CityIdPatch(id, patchCityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCitiesV1CityGet(showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCitiesV1CityGet(showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * 
         * @summary Create City
         * @param {CreateCityRequest} createCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityV1CityPost(createCityRequest: CreateCityRequest, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.createCityV1CityPost(createCityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete City
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCityV1CityIdDelete(id: number, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.deleteCityV1CityIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch City
         * @param {number} id 
         * @param {PatchCityRequest} patchCityRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: any): AxiosPromise<CityResponse> {
            return localVarFp.patchCityV1CityIdPatch(id, patchCityRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Cities
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCitiesV1CityGet(showInactive?: boolean, options?: any): AxiosPromise<Array<CityResponse>> {
            return localVarFp.readCitiesV1CityGet(showInactive, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
    /**
     * 
     * @summary Create City
     * @param {CreateCityRequest} createCityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public createCityV1CityPost(createCityRequest: CreateCityRequest, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).createCityV1CityPost(createCityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete City
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public deleteCityV1CityIdDelete(id: number, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).deleteCityV1CityIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch City
     * @param {number} id 
     * @param {PatchCityRequest} patchCityRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public patchCityV1CityIdPatch(id: number, patchCityRequest: PatchCityRequest, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).patchCityV1CityIdPatch(id, patchCityRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Cities
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public readCitiesV1CityGet(showInactive?: boolean, options?: AxiosRequestConfig) {
        return CityApiFp(this.configuration).readCitiesV1CityGet(showInactive, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientV1ClientPost: async (createClientRequest: CreateClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createClientRequest' is not null or undefined
            assertParamExists('createClientV1ClientPost', 'createClientRequest', createClientRequest)
            const localVarPath = `/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateClientV1ClientIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateClientV1ClientIdDelete', 'id', id)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientV1ClientIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readClientV1ClientIdGet', 'id', id)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsV1ClientGet: async (search?: string, showInactive?: boolean, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientV1ClientIdPatch: async (id: number, patchClientRequest: PatchClientRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateClientV1ClientIdPatch', 'id', id)
            // verify required parameter 'patchClientRequest' is not null or undefined
            assertParamExists('updateClientV1ClientIdPatch', 'patchClientRequest', patchClientRequest)
            const localVarPath = `/v1/client/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchClientRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientV1ClientPost(createClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateClientV1ClientIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateClientV1ClientIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientV1ClientIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientV1ClientIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readClientsV1ClientGet(search, showInactive, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientV1ClientIdPatch(id, patchClientRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Client
         * @param {CreateClientRequest} createClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.createClientV1ClientPost(createClientRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateClientV1ClientIdDelete(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.deactivateClientV1ClientIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Client
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientV1ClientIdGet(id: number, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.readClientV1ClientIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Clients
         * @param {string} [search] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<Array<ClientSimpleResponse>> {
            return localVarFp.readClientsV1ClientGet(search, showInactive, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Client
         * @param {number} id 
         * @param {PatchClientRequest} patchClientRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.updateClientV1ClientIdPatch(id, patchClientRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * 
     * @summary Create Client
     * @param {CreateClientRequest} createClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public createClientV1ClientPost(createClientRequest: CreateClientRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).createClientV1ClientPost(createClientRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public deactivateClientV1ClientIdDelete(id: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).deactivateClientV1ClientIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Client
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public readClientV1ClientIdGet(id: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).readClientV1ClientIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Clients
     * @param {string} [search] 
     * @param {boolean} [showInactive] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public readClientsV1ClientGet(search?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).readClientsV1ClientGet(search, showInactive, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Client
     * @param {number} id 
     * @param {PatchClientRequest} patchClientRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public updateClientV1ClientIdPatch(id: number, patchClientRequest: PatchClientRequest, options?: AxiosRequestConfig) {
        return ClientApiFp(this.configuration).updateClientV1ClientIdPatch(id, patchClientRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventLogsV1EventLogModelIdGet: async (model: string, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'model' is not null or undefined
            assertParamExists('getEventLogsV1EventLogModelIdGet', 'model', model)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getEventLogsV1EventLogModelIdGet', 'id', id)
            const localVarPath = `/v1/event-log/{model}/{id}`
                .replace(`{${"model"}}`, encodeURIComponent(String(model)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventLogsV1EventLogModelIdGet(model, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckV1HealthGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckV1HealthGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Event Logs
         * @param {string} model 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getEventLogsV1EventLogModelIdGet(model, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Health Check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckV1HealthGet(options?: any): AxiosPromise<any> {
            return localVarFp.healthCheckV1HealthGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @summary Get Event Logs
     * @param {string} model 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public getEventLogsV1EventLogModelIdGet(model: string, id: number, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).getEventLogsV1EventLogModelIdGet(model, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Health Check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public healthCheckV1HealthGet(options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).healthCheckV1HealthGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyV1CompanyPost: async (createCompanyRequest: CreateCompanyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyRequest' is not null or undefined
            assertParamExists('createCompanyV1CompanyPost', 'createCompanyRequest', createCompanyRequest)
            const localVarPath = `/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesV1CompanyGet: async (search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCompanyV1CompanyPost(createCompanyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCompaniesV1CompanyGet(search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Company
         * @param {CreateCompanyRequest} createCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: any): AxiosPromise<CompanyResponse> {
            return localVarFp.createCompanyV1CompanyPost(createCompanyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Companies
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<CompanyResponse>> {
            return localVarFp.readCompaniesV1CompanyGet(search, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Create Company
     * @param {CreateCompanyRequest} createCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public createCompanyV1CompanyPost(createCompanyRequest: CreateCompanyRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).createCompanyV1CompanyPost(createCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Companies
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public readCompaniesV1CompanyGet(search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).readCompaniesV1CompanyGet(search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginTokenPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginTokenPost', 'password', password)
            const localVarPath = `/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<any> {
            return localVarFp.loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public loginTokenPost(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).loginTokenPost(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionApi - axios parameter creator
 * @export
 */
export const PositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPositionV1PositionPost: async (createPositionRequest: CreatePositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPositionRequest' is not null or undefined
            assertParamExists('createPositionV1PositionPost', 'createPositionRequest', createPositionRequest)
            const localVarPath = `/v1/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePositionV1PositionIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivatePositionV1PositionIdDelete', 'id', id)
            const localVarPath = `/v1/position/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPositionsV1PositionGet: async (showInactive?: boolean, search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/position`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionApi - functional programming interface
 * @export
 */
export const PositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPositionV1PositionPost(createPositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivatePositionV1PositionIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PositionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivatePositionV1PositionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PositionResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readPositionsV1PositionGet(showInactive, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionApi - factory interface
 * @export
 */
export const PositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Position
         * @param {CreatePositionRequest} createPositionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: any): AxiosPromise<PositionResponse> {
            return localVarFp.createPositionV1PositionPost(createPositionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Position
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivatePositionV1PositionIdDelete(id: number, options?: any): AxiosPromise<PositionResponse> {
            return localVarFp.deactivatePositionV1PositionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Positions
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<PositionResponse>> {
            return localVarFp.readPositionsV1PositionGet(showInactive, search, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PositionApi - object-oriented interface
 * @export
 * @class PositionApi
 * @extends {BaseAPI}
 */
export class PositionApi extends BaseAPI {
    /**
     * 
     * @summary Create Position
     * @param {CreatePositionRequest} createPositionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public createPositionV1PositionPost(createPositionRequest: CreatePositionRequest, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).createPositionV1PositionPost(createPositionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Position
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public deactivatePositionV1PositionIdDelete(id: number, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).deactivatePositionV1PositionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Positions
     * @param {boolean} [showInactive] 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public readPositionsV1PositionGet(showInactive?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return PositionApiFp(this.configuration).readPositionsV1PositionGet(showInactive, search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1ProjectPost: async (createProjectRequest: CreateProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRequest' is not null or undefined
            assertParamExists('createProjectV1ProjectPost', 'createProjectRequest', createProjectRequest)
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet: async (projectId: number, createdDate?: string, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet', 'projectId', projectId)
            const localVarPath = `/v1/project/{project_id}/contacted`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (createdDate !== undefined) {
                localVarQueryParameter['created_date'] = (createdDate as any instanceof Date) ?
                    (createdDate as any).toISOString().substr(0,10) :
                    createdDate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet', 'projectId', projectId)
            const localVarPath = `/v1/project/{project_id}/in-process`
                .replace(`{${"project_id"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectV1ProjectIdPatch: async (id: number, patchProjectRequest: PatchProjectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchProjectV1ProjectIdPatch', 'id', id)
            // verify required parameter 'patchProjectRequest' is not null or undefined
            assertParamExists('patchProjectV1ProjectIdPatch', 'patchProjectRequest', patchProjectRequest)
            const localVarPath = `/v1/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectV1ProjectIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readProjectV1ProjectIdGet', 'id', id)
            const localVarPath = `/v1/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectsV1ProjectGet: async (search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (optionalTechnologyIds !== undefined) {
                localVarQueryParameter['optional_technology_ids'] = optionalTechnologyIds;
            }

            if (requiredTechnologyIds !== undefined) {
                localVarQueryParameter['required_technology_ids'] = requiredTechnologyIds;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectV1ProjectPost(createProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectV1ProjectIdPatch(id, patchProjectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readProjectV1ProjectIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readProjectV1ProjectIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectSimpleResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Project
         * @param {CreateProjectRequest} createProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.createProjectV1ProjectPost(createProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction Contacted
         * @param {number} projectId 
         * @param {string} [createdDate] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: any): AxiosPromise<Array<CandidateProjectResponse>> {
            return localVarFp.getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidates By Project And Last Interaction In Process
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: any): AxiosPromise<Array<CandidateProjectResponse>> {
            return localVarFp.getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Project
         * @param {number} id 
         * @param {PatchProjectRequest} patchProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.patchProjectV1ProjectIdPatch(id, patchProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Project
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectV1ProjectIdGet(id: number, options?: any): AxiosPromise<ProjectResponse> {
            return localVarFp.readProjectV1ProjectIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Projects
         * @param {string} [search] 
         * @param {string} [optionalTechnologyIds] 
         * @param {string} [requiredTechnologyIds] 
         * @param {boolean} [showInactive] 
         * @param {number} [limit] 
         * @param {number} [skip] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: any): AxiosPromise<Array<ProjectSimpleResponse>> {
            return localVarFp.readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Create Project
     * @param {CreateProjectRequest} createProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public createProjectV1ProjectPost(createProjectRequest: CreateProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).createProjectV1ProjectPost(createProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidates By Project And Last Interaction Contacted
     * @param {number} projectId 
     * @param {string} [createdDate] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId: number, createdDate?: string, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(projectId, createdDate, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidates By Project And Last Interaction In Process
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Project
     * @param {number} id 
     * @param {PatchProjectRequest} patchProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public patchProjectV1ProjectIdPatch(id: number, patchProjectRequest: PatchProjectRequest, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).patchProjectV1ProjectIdPatch(id, patchProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Project
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public readProjectV1ProjectIdGet(id: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).readProjectV1ProjectIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Projects
     * @param {string} [search] 
     * @param {string} [optionalTechnologyIds] 
     * @param {string} [requiredTechnologyIds] 
     * @param {boolean} [showInactive] 
     * @param {number} [limit] 
     * @param {number} [skip] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public readProjectsV1ProjectGet(search?: string, optionalTechnologyIds?: string, requiredTechnologyIds?: string, showInactive?: boolean, limit?: number, skip?: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).readProjectsV1ProjectGet(search, optionalTechnologyIds, requiredTechnologyIds, showInactive, limit, skip, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsByUserV1StatsUserIdGet: async (userId: number, startDate?: string, endDate?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getCandidateInteractionsStatsByUserV1StatsUserIdGet', 'userId', userId)
            const localVarPath = `/v1/stats/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsV1StatsGet: async (startDate?: string, endDate?: string, type?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet: async (timePeriod: string, users?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/in-process-interactions-by-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet: async (timePeriod: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/in-process-interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet: async (timePeriod: string, users?: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/search-interactions-by-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (users !== undefined) {
                localVarQueryParameter['users'] = users;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsV1StatsSearchInteractionsGet: async (timePeriod: string, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timePeriod' is not null or undefined
            assertParamExists('readCandidateSearchInteractionsV1StatsSearchInteractionsGet', 'timePeriod', timePeriod)
            const localVarPath = `/v1/stats/search-interactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (timePeriod !== undefined) {
                localVarQueryParameter['time_period'] = timePeriod;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['from_date'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['to_date'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserStatsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserStatsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsByUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsByUserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateInteractionsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Candidate Interactions Stats By User
         * @param {number} userId 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: any): AxiosPromise<Array<UserStatsResponse>> {
            return localVarFp.getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Candidate Interactions Stats
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: any): AxiosPromise<Array<UserStatsResponse>> {
            return localVarFp.getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions By User
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsByUserResponse>> {
            return localVarFp.readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate In Process Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsResponse>> {
            return localVarFp.readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Search Interactions By Users
         * @param {string} timePeriod 
         * @param {string} [users] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsByUserResponse>> {
            return localVarFp.readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Candidate Search Interactions
         * @param {string} timePeriod 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<CandidateInteractionsResponse>> {
            return localVarFp.readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * 
     * @summary Get Candidate Interactions Stats By User
     * @param {number} userId 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId: number, startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getCandidateInteractionsStatsByUserV1StatsUserIdGet(userId, startDate, endDate, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Candidate Interactions Stats
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {string} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public getCandidateInteractionsStatsV1StatsGet(startDate?: string, endDate?: string, type?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).getCandidateInteractionsStatsV1StatsGet(startDate, endDate, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate In Process Interactions By User
     * @param {string} timePeriod 
     * @param {string} [users] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate In Process Interactions
     * @param {string} timePeriod 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Search Interactions By Users
     * @param {string} timePeriod 
     * @param {string} [users] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod: string, users?: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(timePeriod, users, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Candidate Search Interactions
     * @param {string} timePeriod 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod: string, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).readCandidateSearchInteractionsV1StatsSearchInteractionsGet(timePeriod, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaskApi - axios parameter creator
 * @export
 */
export const TaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskV1TaskPost: async (createTaskRequest: CreateTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTaskRequest' is not null or undefined
            assertParamExists('createTaskV1TaskPost', 'createTaskRequest', createTaskRequest)
            const localVarPath = `/v1/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTaskV1TaskIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deactivateTaskV1TaskIdDelete', 'id', id)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaskV1TaskIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readTaskV1TaskIdGet', 'id', id)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTasksV1TaskGet: async (candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (candidateId !== undefined) {
                localVarQueryParameter['candidate_id'] = candidateId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskV1TaskIdPatch: async (id: number, patchTaskRequest: PatchTaskRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskV1TaskIdPatch', 'id', id)
            // verify required parameter 'patchTaskRequest' is not null or undefined
            assertParamExists('updateTaskV1TaskIdPatch', 'patchTaskRequest', patchTaskRequest)
            const localVarPath = `/v1/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTaskRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskApi - functional programming interface
 * @export
 */
export const TaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskV1TaskPost(createTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deactivateTaskV1TaskIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deactivateTaskV1TaskIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTaskV1TaskIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTaskV1TaskIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskV1TaskIdPatch(id, patchTaskRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskApi - factory interface
 * @export
 */
export const TaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Task
         * @param {CreateTaskRequest} createTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.createTaskV1TaskPost(createTaskRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateTaskV1TaskIdDelete(id: number, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.deactivateTaskV1TaskIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Task
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTaskV1TaskIdGet(id: number, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.readTaskV1TaskIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Tasks
         * @param {number} [candidateId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {boolean} [showInactive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: any): AxiosPromise<Array<TaskResponse>> {
            return localVarFp.readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Task
         * @param {number} id 
         * @param {PatchTaskRequest} patchTaskRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: any): AxiosPromise<TaskResponse> {
            return localVarFp.updateTaskV1TaskIdPatch(id, patchTaskRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaskApi - object-oriented interface
 * @export
 * @class TaskApi
 * @extends {BaseAPI}
 */
export class TaskApi extends BaseAPI {
    /**
     * 
     * @summary Create Task
     * @param {CreateTaskRequest} createTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public createTaskV1TaskPost(createTaskRequest: CreateTaskRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).createTaskV1TaskPost(createTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public deactivateTaskV1TaskIdDelete(id: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).deactivateTaskV1TaskIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Task
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public readTaskV1TaskIdGet(id: number, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).readTaskV1TaskIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Tasks
     * @param {number} [candidateId] 
     * @param {string} [startDate] 
     * @param {string} [endDate] 
     * @param {boolean} [showInactive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public readTasksV1TaskGet(candidateId?: number, startDate?: string, endDate?: string, showInactive?: boolean, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).readTasksV1TaskGet(candidateId, startDate, endDate, showInactive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Task
     * @param {number} id 
     * @param {PatchTaskRequest} patchTaskRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskApi
     */
    public updateTaskV1TaskIdPatch(id: number, patchTaskRequest: PatchTaskRequest, options?: AxiosRequestConfig) {
        return TaskApiFp(this.configuration).updateTaskV1TaskIdPatch(id, patchTaskRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TechnologyApi - axios parameter creator
 * @export
 */
export const TechnologyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTechnologyV1TechnologyPost: async (createTechnologyRequest: CreateTechnologyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTechnologyRequest' is not null or undefined
            assertParamExists('createTechnologyV1TechnologyPost', 'createTechnologyRequest', createTechnologyRequest)
            const localVarPath = `/v1/technology`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTechnologyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTechnologyV1TechnologyIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTechnologyV1TechnologyIdDelete', 'id', id)
            const localVarPath = `/v1/technology/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTechnologyV1TechnologyIdPatch: async (id: number, patchTechnologyRequest: PatchTechnologyRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchTechnologyV1TechnologyIdPatch', 'id', id)
            // verify required parameter 'patchTechnologyRequest' is not null or undefined
            assertParamExists('patchTechnologyV1TechnologyIdPatch', 'patchTechnologyRequest', patchTechnologyRequest)
            const localVarPath = `/v1/technology/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchTechnologyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTechnologiesV1TechnologyGet: async (showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/technology`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (names !== undefined) {
                localVarQueryParameter['names'] = names;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TechnologyApi - functional programming interface
 * @export
 */
export const TechnologyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TechnologyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTechnologyV1TechnologyPost(createTechnologyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTechnologyV1TechnologyIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTechnologyV1TechnologyIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TechnologyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TechnologyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TechnologyApi - factory interface
 * @export
 */
export const TechnologyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TechnologyApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Technology
         * @param {CreateTechnologyRequest} createTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.createTechnologyV1TechnologyPost(createTechnologyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Technology
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTechnologyV1TechnologyIdDelete(id: number, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.deleteTechnologyV1TechnologyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Patch Technology
         * @param {number} id 
         * @param {PatchTechnologyRequest} patchTechnologyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: any): AxiosPromise<TechnologyResponse> {
            return localVarFp.patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Technologies
         * @param {boolean} [showInactive] 
         * @param {string} [search] 
         * @param {string} [names] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<TechnologyResponse>> {
            return localVarFp.readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TechnologyApi - object-oriented interface
 * @export
 * @class TechnologyApi
 * @extends {BaseAPI}
 */
export class TechnologyApi extends BaseAPI {
    /**
     * 
     * @summary Create Technology
     * @param {CreateTechnologyRequest} createTechnologyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnologyApi
     */
    public createTechnologyV1TechnologyPost(createTechnologyRequest: CreateTechnologyRequest, options?: AxiosRequestConfig) {
        return TechnologyApiFp(this.configuration).createTechnologyV1TechnologyPost(createTechnologyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Technology
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnologyApi
     */
    public deleteTechnologyV1TechnologyIdDelete(id: number, options?: AxiosRequestConfig) {
        return TechnologyApiFp(this.configuration).deleteTechnologyV1TechnologyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Patch Technology
     * @param {number} id 
     * @param {PatchTechnologyRequest} patchTechnologyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnologyApi
     */
    public patchTechnologyV1TechnologyIdPatch(id: number, patchTechnologyRequest: PatchTechnologyRequest, options?: AxiosRequestConfig) {
        return TechnologyApiFp(this.configuration).patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Technologies
     * @param {boolean} [showInactive] 
     * @param {string} [search] 
     * @param {string} [names] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TechnologyApi
     */
    public readTechnologiesV1TechnologyGet(showInactive?: boolean, search?: string, names?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return TechnologyApiFp(this.configuration).readTechnologiesV1TechnologyGet(showInactive, search, names, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1UserPost: async (createUserRequest: CreateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequest' is not null or undefined
            assertParamExists('createUserV1UserPost', 'createUserRequest', createUserRequest)
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCandidateRelationsV1UserUserIdRelationsGet: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('readUserCandidateRelationsV1UserUserIdRelationsGet', 'userId', userId)
            const localVarPath = `/v1/user/{user_id}/relations`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserV1UserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readUserV1UserIdGet', 'id', id)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersV1UserGet: async (showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (showInactive !== undefined) {
                localVarQueryParameter['show_inactive'] = showInactive;
            }

            if (isAdmin !== undefined) {
                localVarQueryParameter['is_admin'] = isAdmin;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1UserIdPatch: async (id: number, patchUserRequest: PatchUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserV1UserIdPatch', 'id', id)
            // verify required parameter 'patchUserRequest' is not null or undefined
            assertParamExists('updateUserV1UserIdPatch', 'patchUserRequest', patchUserRequest)
            const localVarPath = `/v1/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserV1UserPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserV1UserPost(createUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CandidateUserRelationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUserV1UserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUserV1UserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserV1UserIdPatch(id, patchUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Create User
         * @param {CreateUserRequest} createUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserV1UserPost(createUserRequest: CreateUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserV1UserPost(createUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User Candidate Relations
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: any): AxiosPromise<Array<CandidateUserRelationResponse>> {
            return localVarFp.readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUserV1UserIdGet(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.readUserV1UserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Users
         * @param {boolean} [showInactive] 
         * @param {boolean} [isAdmin] 
         * @param {string} [search] 
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {PatchUserRequest} patchUserRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserV1UserIdPatch(id, patchUserRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Create User
     * @param {CreateUserRequest} createUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUserV1UserPost(createUserRequest: CreateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).createUserV1UserPost(createUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User Candidate Relations
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readUserCandidateRelationsV1UserUserIdRelationsGet(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).readUserCandidateRelationsV1UserUserIdRelationsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read User
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readUserV1UserIdGet(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).readUserV1UserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Users
     * @param {boolean} [showInactive] 
     * @param {boolean} [isAdmin] 
     * @param {string} [search] 
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public readUsersV1UserGet(showInactive?: boolean, isAdmin?: boolean, search?: string, skip?: number, limit?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).readUsersV1UserGet(showInactive, isAdmin, search, skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {number} id 
     * @param {PatchUserRequest} patchUserRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserV1UserIdPatch(id: number, patchUserRequest: PatchUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserV1UserIdPatch(id, patchUserRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WarrantyApi - axios parameter creator
 * @export
 */
export const WarrantyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWarrantyV1WarrantyIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelWarrantyV1WarrantyIdDelete', 'id', id)
            const localVarPath = `/v1/warranty/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantiesV1WarrantyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/warranty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantyV1WarrantyIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readWarrantyV1WarrantyIdGet', 'id', id)
            const localVarPath = `/v1/warranty/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WarrantyApi - functional programming interface
 * @export
 */
export const WarrantyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WarrantyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelWarrantyV1WarrantyIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelWarrantyV1WarrantyIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarrantiesV1WarrantyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WarrantyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarrantiesV1WarrantyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readWarrantyV1WarrantyIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WarrantyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readWarrantyV1WarrantyIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WarrantyApi - factory interface
 * @export
 */
export const WarrantyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WarrantyApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelWarrantyV1WarrantyIdDelete(id: number, options?: any): AxiosPromise<WarrantyResponse> {
            return localVarFp.cancelWarrantyV1WarrantyIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warranties
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantiesV1WarrantyGet(options?: any): AxiosPromise<Array<WarrantyResponse>> {
            return localVarFp.readWarrantiesV1WarrantyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read Warranty
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readWarrantyV1WarrantyIdGet(id: number, options?: any): AxiosPromise<WarrantyResponse> {
            return localVarFp.readWarrantyV1WarrantyIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WarrantyApi - object-oriented interface
 * @export
 * @class WarrantyApi
 * @extends {BaseAPI}
 */
export class WarrantyApi extends BaseAPI {
    /**
     * 
     * @summary Cancel Warranty
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public cancelWarrantyV1WarrantyIdDelete(id: number, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).cancelWarrantyV1WarrantyIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warranties
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public readWarrantiesV1WarrantyGet(options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).readWarrantiesV1WarrantyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read Warranty
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WarrantyApi
     */
    public readWarrantyV1WarrantyIdGet(id: number, options?: AxiosRequestConfig) {
        return WarrantyApiFp(this.configuration).readWarrantyV1WarrantyIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}



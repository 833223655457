import { ArrowBackIosOutlined, ArrowForwardIosOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import {
  COSCandidateAvatar,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSTable,
  COSUserAvatar
} from '../../../helpers/ui';
import { CandidateProjectResponse, UserInteractionResponse } from '../../../services/api';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../../services/cadabraService';
import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';

import { DatePicker } from '@mui/x-date-pickers';
import { parseQuery } from '../../../helpers/url';
import { useNavigate } from 'react-router-dom';
import { useProjectCandidatesContacted } from '../../../helpers/hooks';

interface CandidateEventLogsComponentProps {
  projectId: number;
}

const apiFetchSize = 25;

const ContactedCandidatesComponent = ({ projectId }: CandidateEventLogsComponentProps) => {
  const { selectedPage } = parseQuery(location.search);
  const { candidatesContacted, loading, setParameters } = useProjectCandidatesContacted(
    projectId,
    undefined,
    apiFetchSize,
    0
  );
  const [filterDate, setFilterDate] = useState<Moment | null>(null);
  const [page, setPage] = useState<number>(selectedPage ? +selectedPage : 0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const createdAt = filterDate?.format('YYYY-MM-DD');
    const limit = filterDate ? 1000 : apiFetchSize;
    const skip = filterDate ? 0 : page * apiFetchSize;
    setParameters([projectId, createdAt, limit, skip]);
    navigate(`/project?id=${projectId}&tabId=2&selectedPage=${page}`);
  }, [filterDate, page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [candidatesContacted]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={2}>
          <DatePicker<Moment>
            views={['year', 'month', 'day']}
            label="Date"
            inputFormat="DD/MM/YY"
            mask="__/__/__"
            disableMaskedInput={false}
            value={filterDate}
            onChange={setFilterDate}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment:
                    filterDate != null ? (
                      <>
                        <InputAdornment sx={{ marginRight: -2 }} position="end">
                          <COSIconButton color="inherit" onClick={() => setFilterDate(null)}>
                            <CloseOutlined />
                          </COSIconButton>
                        </InputAdornment>
                        {params.InputProps?.endAdornment}
                      </>
                    ) : (
                      params.InputProps?.endAdornment
                    )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <COSTable<CandidateProjectResponse>
        isLoading={loading}
        data={candidatesContacted}
        enablePagination={false}
        muiTableContainerProps={{
          ref: tableContainerRef,
          sx: { maxHeight: '60vh', minHeight: '300px' }
        }}
        columns={[
          {
            accessorKey: 'id',
            header: '#',
            size: 50,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) =>
              row.index + page * apiFetchSize + 1
          },
          {
            accessorKey: 'id',
            header: '',
            size: 50,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) => {
              return (
                <COSLink href={`/candidate?id=${row.original.id}`}>
                  <COSCandidateAvatar
                    id={row.original.id}
                    name={row.original.name}
                    imagePath={row.original.imagePath}
                    tooltipPlacement="left"
                  />
                </COSLink>
              );
            }
          },
          {
            accessorKey: 'name',
            header: 'Candidate',
            size: 200,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) => {
              return (
                <COSLink href={`/candidate?id=${row.original.id}`}>
                  <Typography>{row.original.name}</Typography>
                </COSLink>
              );
            }
          },
          {
            accessorKey: 'latestInteractionCreatedAt',
            header: 'Date',
            size: 200,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) => {
              const value = moment(
                row.original.latestInteractionCreatedAt ?? '2020-01-01T00:00:00'
              ).format('DD/MM/YY, HH:mm:ss');
              return <Typography>{value}</Typography>;
            },
            sortingFn: (row1, row2) =>
              moment(row1.original.latestInteractionCreatedAt).isBefore(
                moment(row2.original.latestInteractionCreatedAt)
              )
                ? -1
                : 1
          },
          {
            accessorKey: 'latestInteractionType',
            header: 'Type',
            size: 200,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) => {
              const value = row.original.latestInteractionType
                ? getInteractionTypesByKey(row.original.latestInteractionType as InteractionTypeKey)
                    .label
                : '-';
              return <Typography>{value}</Typography>;
            }
          },
          {
            accessorKey: 'latestUserInteractions',
            header: 'Users',
            size: 300,
            Cell: ({ row }: COSCellProps<CandidateProjectResponse>) => {
              return (
                <Box sx={{ display: 'flex' }}>
                  {row.original.latestUserInteractions.map((user: UserInteractionResponse) => (
                    <COSLink key={user.userId} href={`/user?id=${user.userId}`}>
                      <COSUserAvatar
                        id={user.userId}
                        name={user.userName}
                        tooltipText={`${user.userName}, ${moment(user.createdAt).format(
                          'DD/MM/YY, hh:mm:ss'
                        )}`}
                        tooltipPlacement="left"
                        sx={{ width: 28, height: 28, fontSize: 12, marginRight: 0.3 }}
                        withImage={true}
                      />
                    </COSLink>
                  ))}
                </Box>
              );
            }
          }
        ]}
        renderBottomToolbarCustomActions={
          !filterDate
            ? () => (
                <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                  <IconButton
                    onClick={() => setPage(page > 0 ? page - 1 : 0)}
                    disabled={page === 0}
                  >
                    <ArrowBackIosOutlined />
                  </IconButton>
                  <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
                    <Typography align="center" variant="h4" component="h5">
                      {page + 1}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => setPage(page + 1)}
                    disabled={candidatesContacted.length < apiFetchSize}
                  >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                </Box>
              )
            : undefined
        }
      />
    </Box>
  );
};

export default ContactedCandidatesComponent;

import {
  COSAsyncAutocomplete,
  COSAsyncMultipleAutocomplete,
  COSButton,
  COSOutlinedDiv,
  COSTextField,
  COSViewOnlyTextField,
  Lookup
} from '../../../../helpers/ui';
import { Checkbox, Container, FormControlLabel, Grid, Paper } from '@mui/material';
import {
  ClientSimpleResponse,
  CreateProjectRequest,
  PatchProjectRequest,
  PositionResponse,
  TechnologyResponse,
  UserResponse
} from '../../../../services/api';
import React, { useEffect, useMemo, useState } from 'react';
import { cadabraService, snackbarService } from '../../../../services/services';
import { checkedIcon, icon } from '../../../../helpers/ui/common';
import { useProject, useUsers } from '../../../../helpers/hooks';

import useClients from '../../../../helpers/hooks/useClients';
import { useDebounce } from '@react-hook/debounce';
import { useNavigate } from 'react-router-dom';
import usePositions from '../../../../helpers/hooks/usePositions';
import useTechnologies from '../../../../helpers/hooks/useTechnologies';

interface ProjectInfoComponentProps {
  projectId?: number;
}

const ProjectInfoComponent = ({ projectId }: ProjectInfoComponentProps) => {
  const navigate = useNavigate();
  const { project, setParameters } = useProject(projectId);
  const [header, setHeader] = useState<string>('New project');
  const [openPositions, setOpenPositions] = useState<any>('');
  const usersData = useUsers();
  const [usersValue, setUsersValue] = useState<Lookup[]>([]);
  const [usersFilter, setUsersFilter] = useDebounce<string>('', 250);
  const positionsData = usePositions();
  const [positionValue, setPositionValue] = useState<Lookup | null>(null);
  const [positionsFilter, setPositionsFilter] = useDebounce<string>('', 250);
  const clientsData = useClients();
  const [clientValue, setClientValue] = useState<Lookup | null>(null);
  const [clientsFilter, setClientsFilter] = useDebounce<string>('', 250);
  const technologiesData = useTechnologies();
  const [techsValue, setTechsValue] = useState<Lookup[]>([]);
  const [techsFilter, setTechsFilter] = useDebounce<string>('', 250);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [description, setDescription] = useState<string | undefined>('');
  const [loadingAction, setLoadingAction] = useState<boolean>(false);

  useEffect(() => {
    technologiesData.setParameters([techsFilter]);
  }, [techsFilter]);

  useEffect(() => {
    positionsData.setParameters([positionsFilter]);
  }, [positionsFilter]);

  useEffect(() => {
    clientsData.setParameters([clientsFilter]);
  }, [clientsFilter]);

  useEffect(() => {
    usersData.setParameters([usersFilter]);
  }, [usersFilter]);

  useEffect(() => {
    if (!project) {
      return;
    }
    setHeader(`${project.position.name} @ ${project.client.companyName}`);
    setOpenPositions(project.openPositions);
    setClientValue({
      id: project.clientId,
      value: project.client.companyName
    });
    setTechsValue(
      project.technologies.map((technology: TechnologyResponse) => ({
        id: technology.id,
        value: technology.name
      }))
    );
    setUsersValue(
      project.users.map((user: UserResponse) => ({
        id: user.id,
        value: user.name
      }))
    );
    setPositionValue({
      id: project.positionId,
      value: project.position.name
    });
    setIsActive(project.isActive);
    setDescription(project.description);
  }, [project]);

  const handleOnClick = async () => {
    setLoadingAction(true);
    if (projectId) {
      const patchRequest: PatchProjectRequest = {
        technologyIds: techsValue.map((value: Lookup) => value.id),
        userIds: usersValue.map((value: Lookup) => value.id),
        isActive,
        description
      };
      const resp = await cadabraService.patchProject(projectId, patchRequest);
      if (resp.data) {
        snackbarService.setSnackbarOpen(true, 'Data seved successfully', 'success');
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
    }
    if (!projectId && isValidProject) {
      const createRequest: CreateProjectRequest = {
        clientId: clientValue?.id ?? 0,
        positionId: positionValue?.id ?? 0,
        technologyIds: techsValue.map((value: Lookup) => value.id),
        openPositions,
        isActive: true,
        userIds: usersValue.map((value: Lookup) => value.id),
        description
      };
      const resp = await cadabraService.createProject(createRequest);
      if (resp?.data) {
        snackbarService.setSnackbarOpen(true, 'Data seved successfully', 'success');
        navigate(`/project?id=${resp.data.id}`);
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
    }
    setLoadingAction(false);
  };

  const isValidProject = useMemo(
    () => openPositions && positionValue && techsValue && usersValue && clientValue,
    [openPositions, positionValue, techsValue, usersValue, clientValue]
  );

  useEffect(() => {
    setParameters([projectId]);
  }, [projectId]);

  return (
    <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Container maxWidth="xl">
          <Paper elevation={2}>
            <COSOutlinedDiv label={header}>
              <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {projectId ? (
                    <COSViewOnlyTextField label="Client" value={clientValue?.value ?? ''} />
                  ) : (
                    <COSAsyncAutocomplete<Lookup>
                      label="Select client"
                      value={clientValue}
                      options={clientsData.clients.map((client: ClientSimpleResponse) => ({
                        id: client.id,
                        value: client.companyName
                      }))}
                      loading={clientsData.loading}
                      onChange={(value: Lookup | null) => setClientValue(value)}
                      minChar={0}
                      filter={clientsFilter}
                      onChangeFilter={(clientsFilter: string) => setClientsFilter(clientsFilter)}
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {projectId ? (
                    <COSViewOnlyTextField label="Position" value={positionValue?.value ?? ''} />
                  ) : (
                    <COSAsyncAutocomplete<Lookup>
                      label="Select position"
                      value={positionValue}
                      options={positionsData.positions.map((position: PositionResponse) => ({
                        id: position.id,
                        value: position.name
                      }))}
                      loading={positionsData.loading}
                      onChange={(value: Lookup | null) => setPositionValue(value)}
                      minChar={0}
                      filter={positionsFilter}
                      onChangeFilter={(positionsFilter: string) =>
                        setPositionsFilter(positionsFilter)
                      }
                    />
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Select technology"
                    value={techsValue}
                    options={technologiesData.technologies.map(
                      (technology: TechnologyResponse) => ({
                        id: technology.id,
                        value: technology.name
                      })
                    )}
                    loading={technologiesData.loading}
                    onChange={(value: Lookup[]) => setTechsValue(value)}
                    onEnter={async (option) => {
                      setTechsFilter('');
                      if (option.id > 0 && !techsValue.includes(option)) {
                        setTechsValue([...techsValue, option]);
                        return;
                      }
                      if (option.id > 0 && techsValue.includes(option)) {
                        setTechsValue(techsValue.filter((o) => o.id !== option.id));
                        return;
                      }
                      const technologyResp = await cadabraService.createTechnology({
                        name: option.value
                      });
                      technologiesData.setTechnologies([
                        ...technologiesData.technologies,
                        technologyResp.data
                      ]);
                      setTechsValue([
                        ...techsValue,
                        {
                          id: technologyResp.data.id,
                          value: technologyResp.data.name
                        }
                      ]);
                    }}
                    limitTags={7}
                    minChar={0}
                    filter={techsFilter}
                    onChangeFilter={(techsFilter: string) => setTechsFilter(techsFilter)}
                  />
                </Grid>
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <COSAsyncMultipleAutocomplete<Lookup>
                    label="Select user"
                    value={usersValue}
                    options={usersData.users.map((user: UserResponse) => ({
                      id: user.id,
                      value: user.name
                    }))}
                    loading={usersData.loading}
                    onChange={(value: Lookup[]) => setUsersValue(value)}
                    minChar={0}
                    filter={usersFilter}
                    onChangeFilter={(usersFilter: string) => setUsersFilter(usersFilter)}
                  />
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                  {projectId ? (
                    <COSViewOnlyTextField label="Positions" value={openPositions} />
                  ) : (
                    <COSTextField
                      label="Open positions"
                      value={openPositions}
                      onChange={(value) => setOpenPositions(value)}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={2}
                  sm={12}
                  xs={12}
                  alignContent="center"
                  justifyContent="center"
                  container
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Description"
                    value={description}
                    onChange={(value) => setDescription(value)}
                    multiline={true}
                    rows={10}
                  />
                </Grid>
                <Grid container item lg={12} justifyContent="center">
                  <COSButton
                    text="Save"
                    variant="contained"
                    sx={{
                      width: '20%'
                    }}
                    onClick={handleOnClick}
                    disabled={loadingAction || !isValidProject}
                    loading={loadingAction}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </COSOutlinedDiv>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ProjectInfoComponent;

import {
  COSAsyncAutocomplete,
  COSButton,
  COSCountryAutocomplete,
  COSIconButton,
  COSOutlinedDiv,
  COSTextField,
  COSViewOnlyTextField,
  Lookup
} from '../../helpers/ui';
import {
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Tooltip
} from '@mui/material';
import { Country, findCountry } from '../../helpers/country-helper';
import { CreateClientRequest, PatchClientRequest } from '../../services/api';
import React, { useEffect, useMemo, useState } from 'react';
import { cadabraService, snackbarService } from '../../services/services';
import { checkedIcon, icon } from '../../helpers/ui/common';
import { useLocation, useNavigate } from 'react-router-dom';

import { AutoFixHighOutlined } from '@mui/icons-material';
import Axios from 'axios';
import { parseQuery } from '../../helpers/url';
import useCompanies from '../../helpers/hooks/useCompanies';
import { useDebounce } from '@react-hook/debounce';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const ClientComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = parseQuery(location.search);
  const [header, setHeader] = useState<string>('New client');
  const [city, setCity] = useState<string>();
  const [vatnumber, setVatnumber] = useState<string>();
  const [country, setCountry] = useState<Country | null>(null);
  const [address, setAddress] = useState<string>();
  const [invoiceName, setInvoiceName] = useState<string>();
  const [feePercentage, setFeePercentage] = useState<string>();
  const [warrantyDays, setWarrantyDays] = useState<string>();
  const { companies, setParameters } = useCompanies();
  const [companyValue, setCompanyValue] = useState<Lookup | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingAction, setLoadingAction] = useState<boolean>(false);
  const [companiesFilter, setCompaniesFilter] = useDebounce<string>('', 250);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [legacyId, setLegacyId] = useState<any>('');
  const [legacyNote, setLegacyNote] = useState<any>('');
  const [legacyContact, setLegacyContact] = useState<any>('');
  const [legacyUrl, setLegacyUrl] = useState<any>('');

  useEffect(() => {
    setParameters([companiesFilter]);
  }, [companiesFilter]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const clientResp = await cadabraService.getClient(id);
        const client = clientResp.data;
        setHeader(client.companyName);
        setCompanyValue({ id: client.companyId, value: client.companyName });
        setCountry(findCountry(client.country));
        setCity(client.city);
        setVatnumber(client.vatnumber);
        setAddress(client.address);
        setInvoiceName(client.invoiceName);
        setFeePercentage(`${client.feePercentage}`);
        setWarrantyDays(`${client.warrantyDays}`);
        setIsActive(client.isActive);
        setLegacyId(client.legacyId);
        setLegacyNote(client.legacyNote);
        setLegacyContact(client.legacyContact);
        setLegacyUrl(client.legacyUrl);
      }
      setIsLoading(false);
    };
    fetchData().catch(console.error);
  }, [id, companiesFilter]);

  const handleOnClick = async () => {
    setLoadingAction(true);
    if (id) {
      const patchRequest: PatchClientRequest = {
        vatnumber,
        country: country?.alpha3,
        city,
        address,
        invoiceName,
        feePercentage: feePercentage ? +feePercentage : 0,
        warrantyDays: warrantyDays ? +warrantyDays : 0,
        isActive
      };
      const resp = await cadabraService.patchClient(id, patchRequest);
      if (resp.data) {
        snackbarService.setSnackbarOpen(true, 'Data saved successfully', 'success');
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
    }
    if (!id && isValidClient) {
      const createRequest: CreateClientRequest = {
        vatnumber: vatnumber ?? '',
        country: country?.alpha3 ?? '',
        city: city ?? '',
        address: address ?? '',
        invoiceName: invoiceName ?? '',
        feePercentage: feePercentage ? +feePercentage : 0,
        warrantyDays: warrantyDays ? +warrantyDays : 0,
        companyId: companyValue?.id ?? 0,
        isActive
      };
      const resp = await cadabraService.createClient(createRequest);
      if (resp?.data) {
        snackbarService.setSnackbarOpen(true, 'Data saved successfully', 'success');
        navigate(`/client?id=${resp.data.id}`);
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
    }
    setLoadingAction(false);
  };

  const isValidClient = useMemo(
    () =>
      vatnumber &&
      country &&
      city &&
      address &&
      invoiceName &&
      feePercentage &&
      warrantyDays &&
      companyValue,
    [vatnumber, country, city, address, invoiceName, feePercentage, warrantyDays, companyValue]
  );

  return !isLoading ? (
    <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Container maxWidth="lg">
          <Paper elevation={2}>
            <COSOutlinedDiv label={header}>
              <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {id ? (
                    <COSViewOnlyTextField label="Company" value={header} />
                  ) : (
                    <COSAsyncAutocomplete<Lookup>
                      label="Company"
                      value={companyValue}
                      options={companies.map((c) => ({ id: c.id, value: c.name }))}
                      onChange={(value: Lookup | null) => setCompanyValue(value)}
                      minChar={0}
                      loading={isLoading}
                      filter={companiesFilter}
                      onChangeFilter={(usersFilter: string) => setCompaniesFilter(usersFilter)}
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <COSCountryAutocomplete
                    country={country}
                    onChange={(_, country) => setCountry(country)}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Invoice name"
                    value={invoiceName}
                    onChange={(value) => setInvoiceName(value)}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <COSTextField label="City" value={city} onChange={(value) => setCity(value)} />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Vat number"
                    value={vatnumber}
                    onChange={(value) => setVatnumber(value)}
                  />
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Address"
                    value={address}
                    onChange={(value) => setAddress(value)}
                  />
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Warranty days"
                    value={warrantyDays}
                    onChange={(value) => setWarrantyDays(value)}
                  />
                </Grid>
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <COSTextField
                    label="Fee percentage (%)"
                    value={feePercentage}
                    onChange={(value) => setFeePercentage(value)}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={2}
                  sm={12}
                  xs={12}
                  alignContent="center"
                  justifyContent="center"
                  container
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={isActive}
                        onChange={() => setIsActive(!isActive)}
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
                <Grid container item lg={12} justifyContent="center">
                  <COSButton
                    text="Save"
                    variant="contained"
                    sx={{
                      width: '20%'
                    }}
                    onClick={handleOnClick}
                    disabled={loadingAction || !isValidClient}
                    loading={loadingAction}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </COSOutlinedDiv>
          </Paper>
        </Container>
      </Grid>
      {legacyId ? (
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <Container maxWidth="lg">
            <Paper elevation={2}>
              <COSOutlinedDiv label={'Legacy Info'}>
                <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSViewOnlyTextField
                      label="Legacy Contact"
                      value={legacyContact}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <COSIconButton
                              color="info"
                              onClick={() =>
                                window.open(
                                  `https://cylinder.cadabra.bg/index.php?m=companies&a=show&companyID=${legacyId}`,
                                  '_blank',
                                  'noopener,noreferrer'
                                )
                              }
                            >
                              <Tooltip arrow placement="top" title="Cylinder profile">
                                <AutoFixHighOutlined />
                              </Tooltip>
                            </COSIconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSViewOnlyTextField label="Legacy Url" value={legacyUrl} />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <COSViewOnlyTextField
                      label="Legacy Note"
                      value={legacyNote}
                      multiline={true}
                      rows={6}
                    />
                  </Grid>
                </Grid>
              </COSOutlinedDiv>
            </Paper>
          </Container>
        </Grid>
      ) : null}
    </Grid>
  ) : (
    <CircularProgress />
  );
};

export default withErrorHandler(ClientComponent, Axios);

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { TaskResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ITasks<T> extends IAsyncType<T> {
  tasks: T;
  setTasks: Dispatch<SetStateAction<T>>;
}

const useTasks = (
  candidateId?: number | undefined,
  startDate?: string | undefined,
  endDate?: string | undefined,
  showInactive: boolean = true
): ITasks<TaskResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    TaskResponse[]
  >(cadabraService.getTasks, [], [candidateId, startDate, endDate, showInactive]);
  return {
    tasks: data,
    setTasks: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useTasks;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { UserResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IUser<T> extends IAsyncType<T> {
  user: T;
  setUser: Dispatch<SetStateAction<T>>;
}

const useUser = (
  id: number
): IUser<UserResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    UserResponse | null
  >(cadabraService.getUser, null, [id]);
  return {
    user: data,
    setUser: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useUser;

import GaugeChart from 'react-gauge-chart';
import React from 'react';

interface COSGaugeChartProps {
  value: number;
  animate: boolean;
  style?: React.CSSProperties | undefined;
}

const COSGaugeChart = ({
  value,
  animate = false,
  style = { width: '80%', margin: 'auto' }
}: COSGaugeChartProps) => {
  return (
    <GaugeChart
      nrOfLevels={4}
      percent={Math.ceil(value * 100) / 100}
      animate={animate}
      textColor="#B63876"
      colors={['#EDD0DE', '#DEA7C2', '#C66594', '#B63876']}
      needleColor="#EDD0DE"
      needleBaseColor="#EDD0DE"
      style={style}
    />
  );
};

export default COSGaugeChart;

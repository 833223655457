import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { COSLink } from '../../../../helpers/ui';
import { MenuItem } from '../listItems';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface MenuItemContainerProps {
  menuItem: MenuItem;
  small: boolean;
}

const MenuItemContainer = ({ menuItem, small }: MenuItemContainerProps) => {
  const location = useLocation();
  return (
    <COSLink href={menuItem.route}>
      <div key={menuItem.route}>
        <ListItem
          key={menuItem.route}
          button
          selected={location.pathname === menuItem.route}
          className="menu-item"
        >
          <ListItemIcon>{menuItem.icon}</ListItemIcon>
          {!small && <ListItemText primary={menuItem.label} />}
        </ListItem>
      </div>
    </COSLink>
  );
};

export default MenuItemContainer;

import { OutlinedInputProps, TextField } from '@mui/material';

import React from 'react';

export interface COSViewOnlyTextFieldProps {
  label: string;
  value: string;
  multiline?: boolean;
  rows?: string | number;
  InputProps?: Partial<OutlinedInputProps> | undefined;
}

const COSViewOnlyTextField = ({
  label,
  value,
  multiline,
  rows,
  InputProps
}: COSViewOnlyTextFieldProps) => {
  return (
    <TextField
      label={label}
      margin="dense"
      variant="outlined"
      size="small"
      fullWidth
      disabled
      value={value}
      multiline={multiline}
      rows={rows}
      InputProps={InputProps}
    />
  );
};

export default COSViewOnlyTextField;

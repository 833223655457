import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { COSButton, COSOutlinedDiv, COSTextField, COSViewOnlyTextField } from '../../../helpers/ui';
import React, { useEffect, useState } from 'react';
import { cadabraService, snackbarService } from '../../../services/services';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { PatchUserRequest } from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../helpers/hooks';

const icon = <CheckBoxOutlineBlankIcon />;
const checkedIcon = <CheckBoxIcon />;

interface UserFormFormProps {
  id: number;
}
const UserFormForm = ({ id }: UserFormFormProps) => {
  const navigate = useNavigate();
  const { user, loading, setLoading } = useUser(id);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [legacyId, setLegacyId] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const handleOnClick = async () => {
    setLoading(true);
    const patchUser: PatchUserRequest = { name, isActive, isAdmin };
    const resp = await cadabraService.patchUser(id, patchUser);
    if (resp) {
      navigate(`/user?id=${resp.data.id}`);
    }
    setLoading(false);
    snackbarService.setSnackbarOpen(true, 'Data seved successfully', 'success');
  };
  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setLegacyId(`${user.legacyId ?? ''}`);
      setIsActive(user.isActive);
      setIsAdmin(user.isAdmin);
    }
    setLoading(false);
  }, [user]);
  return (
    <COSOutlinedDiv label={name}>
      <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
        <Grid item lg={6}>
          <Box
            component="object"
            sx={{
              width: '75%',
              height: 'auto',
              maxWidth: 300,
              maxHeight: 300,
              borderRadius: 50,
              margin: 'auto'
            }}
            data={`https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/users/${id}.jpeg`}
          >
            <Box
              component="img"
              sx={{
                width: '75%',
                height: 'auto',
                maxWidth: 300,
                maxHeight: 300,
                borderRadius: 50,
                margin: 'auto',
                padding: 10,
                border: '2px solid #ae2367'
              }}
              src="https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/users/29.jpeg"
            />
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
            <Grid item lg={12}>
              <COSTextField label="Name" value={name} onChange={(value) => setName(value)} />
            </Grid>
            <Grid item lg={12}>
              <COSViewOnlyTextField label="Email" value={email ?? ''} />
            </Grid>
            <Grid item lg={12}>
              <COSViewOnlyTextField label="Legacy ID" value={`${legacyId}`} />
            </Grid>
            <Grid item lg={6} alignContent="center" justifyContent="center" container>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={isAdmin}
                    onChange={() => setIsAdmin(!isAdmin)}
                    color="primary"
                  />
                }
                label="Admin"
              />
            </Grid>
            <Grid item lg={6} alignContent="center" justifyContent="center" container>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    color="primary"
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent="center">
          <COSButton
            text="Save"
            variant="contained"
            sx={{
              width: '20%'
            }}
            onClick={handleOnClick}
            disabled={loading}
            loading={loading}
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </COSOutlinedDiv>
  );
};

export default UserFormForm;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CompanyResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ICompanies<T> extends IAsyncType<T> {
  companies: T;
  setCompanies: Dispatch<SetStateAction<T>>;
}

const useCompanies = (
  search?: string,
  limit: number = 25,
  skip: number = 0
): ICompanies<CompanyResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CompanyResponse[]
  >(cadabraService.getCompanies, [], [search, limit, skip]);
  return {
    companies: data,
    setCompanies: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCompanies;

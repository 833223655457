import { CircularProgress, IconButton, SxProps, Theme } from '@mui/material';

import React from 'react';

type COSIconButtonColor =
  | 'inherit'
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'primary'
  | 'secondary'
  | undefined;

interface COSIconButtonProps {
  children: any;
  color?: COSIconButtonColor;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const COSIconButton = ({
  children,
  color,
  onClick,
  disabled = false,
  loading = false,
  sx
}: COSIconButtonProps) => {
  return (
    <IconButton sx={sx} size="small" onClick={onClick} disabled={disabled} color={color}>
      {!loading && children}
      {loading && <CircularProgress size={24} />}
    </IconButton>
  );
};

export default COSIconButton;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { PositionResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IPositions<T> extends IAsyncType<T> {
  positions: T;
  setPositions: Dispatch<SetStateAction<T>>;
}

const usePositions = (
  search?: string,
  showInactive: boolean = false,
  limit: number = 25,
  skip: number = 0
): IPositions<PositionResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    PositionResponse[]
  >(cadabraService.getPositions, [], [search, showInactive, limit, skip]);
  return {
    positions: data,
    setPositions: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default usePositions;

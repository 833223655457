import { Box, Checkbox, Typography } from '@mui/material';
import { COSButton, COSCellProps, COSRowActions, COSTable } from '../../../helpers/ui';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { CreateTaskRequest, PatchTaskRequest, TaskResponse } from '../../../services/api';
import React, { useCallback, useEffect, useState } from 'react';
import Task, { compareTasks, toTask } from '../../../models/task';
import { cadabraService, snackbarService } from '../../../services/services';

import Axios from 'axios';
import CandidateTaskDialog from '../CandidateInfoPanel/CandidateTaskDialog';
import moment from 'moment';
import { useTasks } from '../../../helpers/hooks';
import withErrorHandler from '../../../helpers/hoc/withErrorHandler/withErrorHandler';

interface CandidateTasksComponentProps {
  candidateId: number;
}

const CandidateTasksComponent = ({ candidateId }: CandidateTasksComponentProps) => {
  const [tasksData, setTasksData] = useState<Task[]>([]);
  const { tasks, setTasks, loading, setLoading } = useTasks(candidateId);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setTasksData(tasks.map(toTask).sort(compareTasks));
  }, [tasks]);

  const onChange = useCallback(
    async (task: TaskResponse) => {
      const patchRequest: PatchTaskRequest = {
        isActive: !task.isActive
      };
      setLoading(true);
      const resp = await cadabraService.patchTask(task.id, patchRequest);
      if (resp.data) {
        snackbarService.setSnackbarOpen(true, 'Data saved successfully', 'success');
        setTasks(
          tasks.reduce((res: TaskResponse[], t: TaskResponse) => {
            if (t.id === task.id) {
              res.push(resp.data);
            } else {
              res.push(t);
            }
            return res;
          }, [])
        );
        setLoading(false);
      } else {
        snackbarService.setSnackbarOpen(true, 'Error while recording', 'error');
      }
    },
    [tasks]
  );

  return (
    <Box>
      <COSButton text="ADD TASK" onClick={() => setOpenDialog(true)} />
      <CandidateTaskDialog
        candidateId={candidateId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={async (taskRequest: CreateTaskRequest) => {
          const resp = await cadabraService.createTask(taskRequest);
          if (resp) {
            setTasks([resp.data, ...tasks]);
            setOpenDialog(false);
            snackbarService.setSnackbarOpen(true, 'Task saved!', 'success');
          }
        }}
      />
      <COSTable<Task>
        isLoading={loading}
        data={tasksData}
        enablePagination={false}
        enableStickyHeader={true}
        columns={[
          {
            accessorKey: 'reminderDate',
            header: 'Date',
            size: 150,
            Cell: ({ row }: COSCellProps<Task>) => {
              const value = moment(row.original.reminderDate ?? '2020-01-01T00:00:00').format(
                'DD/MM/YY'
              );
              return (
                <Typography
                  sx={!row.original.isActive ? { textDecoration: 'line-through' } : undefined}
                >
                  {value}
                  {row.original.isActive && !row.original.pending ? ' (overdue)' : ''}
                </Typography>
              );
            },
            sortingFn: (row1, row2) =>
              moment(row1.original.reminderDate).isBefore(moment(row2.original.reminderDate))
                ? -1
                : 1
          },
          {
            accessorKey: 'comment',
            header: 'Comment',
            size: 250,
            Cell: ({ row }: COSCellProps<Task>) => {
              return (
                <Typography
                  sx={!row.original.isActive ? { textDecoration: 'line-through' } : undefined}
                >
                  {row.original.comment}
                </Typography>
              );
            }
          },
          {
            accessorKey: 'isActive',
            header: 'Actions',
            size: 100,
            Cell: ({ row }: COSRowActions<Task>) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank />}
                  checkedIcon={<CheckBox />}
                  checked={!row.original.isActive}
                  onChange={async () => await onChange(row.original)}
                  color="default"
                />
              </Box>
            )
          }
        ]}
      />
    </Box>
  );
};

export default withErrorHandler(CandidateTasksComponent, Axios);

import { Box, Typography } from '@mui/material';
import {
  COSButton,
  COSCandidateAvatar,
  COSCellProps,
  COSLink,
  COSTable
} from '../../../helpers/ui';
import {
  CandidateUserRelationResponse,
  CreateCandidateUserRelationRequest
} from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { RelationTypeKey, getRelationTypesByKey } from '../../../services/cadabraService';

import UserCandidateRelationDialog from './UserCandidateRelationDialog';
import { cadabraService } from '../../../services/services';

interface UserCandidateRelationComponentProps {
  userId: number;
}

const UserCandidateRelationComponent = ({ userId }: UserCandidateRelationComponentProps) => {
  const [userCandidateRelation, setUserCandidateRelation] = useState<
    CandidateUserRelationResponse[]
  >([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  useEffect(() => {
    const loadData = async () => {
      if (userId) {
        const userCandidateRelationResp = await cadabraService.getUserCandidateRelation(userId);
        setUserCandidateRelation(userCandidateRelationResp.data);
      }
    };
    loadData();
  }, [userId]);

  return (
    <Box>
      <COSButton text="ADD" onClick={() => setOpenDialog(true)} />
      <COSTable<CandidateUserRelationResponse>
        isLoading={false}
        data={userCandidateRelation}
        enablePagination={true}
        enableRowNumbers={true}
        columns={[
          {
            accessorKey: 'id',
            header: '',
            size: 50,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => {
              return (
                <COSLink href={`/candidate?id=${row.original.candidateId}`}>
                  <COSCandidateAvatar
                    id={row.original.candidateId}
                    name={row.original.candidateName}
                    imagePath={row.original.candidateImagePath}
                    tooltipPlacement="left"
                    sx={{ marginY: 0.5 }}
                  />
                </COSLink>
              );
            }
          },
          {
            accessorKey: 'candidateName',
            header: 'Candidate',
            size: 150,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => (
              <COSLink href={`/candidate?id=${row.original.candidateId}`}>
                {row.original.candidateName}
              </COSLink>
            )
          },
          {
            accessorKey: 'relationType',
            header: 'Relation type',
            size: 790,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => {
              const value = row.original.relationType
                ? getRelationTypesByKey(row.original.relationType as RelationTypeKey).label
                : '-';
              return <Typography>{value}</Typography>;
            }
          }
        ]}
      />
      <UserCandidateRelationDialog
        userId={userId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={async (candidateUserRelationRequest: CreateCandidateUserRelationRequest) => {
          const resp = await cadabraService.createCandidateUserRelation(
            candidateUserRelationRequest
          );
          if (resp) {
            setUserCandidateRelation([resp.data, ...userCandidateRelation]);
            setOpenDialog(false);
          }
        }}
      />
    </Box>
  );
};

export default UserCandidateRelationComponent;

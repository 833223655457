import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateProjectResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IEventLogsContacted<T> extends IAsyncType<T> {
  candidatesContacted: T;
  setCandidatesContacted: Dispatch<SetStateAction<T>>;
}

const useProjectCandidatesContacted = (
  projectId: number,
  createdAt?: string,
  limit = 25,
  skip = 0
): IEventLogsContacted<CandidateProjectResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateProjectResponse[]
  >(
    cadabraService.getCandidatesByProjectContactedAndLastInteraction,
    [],
    [projectId, createdAt, limit, skip]
  );
  return {
    candidatesContacted: data,
    setCandidatesContacted: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProjectCandidatesContacted;

import { Container, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { COSTabPanel } from '../../helpers/ui';
import ContactedCandidatesComponent from './ContactedCandidatesComponent/ContactedCandidatesComponent';
import ProjectCandidateSuggetionsComponent from './ProjectDashboardComponent/ProjectCandidateSuggetionsComponent/ProjectCandidateSuggetionsComponent';
import ProjectDashboardComponent from './ProjectDashboardComponent/ProjectDashboardComponent';
import ProjectInfoComponent from './ProjectDashboardComponent/ProjectInfoPanel/ProjectInfoComponent';
import ProjectLegacyComponent from './ProjectLegacyComponent/ProjectLegacyComponent';
import ProjectStatsComponent from './ProjectStatsComponent/ProjectStatsComponent';
import { parseQuery } from '../../helpers/url';
import { useProject } from '../../helpers/hooks';

const ProjectComponent = () => {
  const location = useLocation();
  const { id, tabId } = parseQuery(location.search);
  const { project } = useProject(id);
  const navigate = useNavigate();

  const [tab, setTab] = useState(tabId ? +tabId : 0);

  useEffect(() => {
    setTab(tabId ? +tabId : 0);
  }, [tabId, setTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    navigate(`/project?${id ? `id=${id}&` : ''}tabId=${newTab}`);
  };

  return (
    <Container maxWidth="xl">
      <Typography component="h5" variant="h5">
        { project?.id ? `${project.position.name} @ ${project.client.companyName}` : 'New Project'}
      </Typography>
      {id && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Process" />
          <Tab label="Info" />
          <Tab label="Contacted" />
          <Tab label="Suggestions" />
          <Tab label="Stats" />
          {project?.legacyId ? <Tab label="Legacy" /> : null}
        </Tabs>
      )}
      <COSTabPanel value={tab} index={0}>
        <ProjectDashboardComponent projectId={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={1}>
        <ProjectInfoComponent projectId={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={2}>
        <ContactedCandidatesComponent projectId={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={3}>
        <ProjectCandidateSuggetionsComponent projectId={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={4}>
        <ProjectStatsComponent projectId={id} />
      </COSTabPanel>
      {project?.legacyId ? (<COSTabPanel value={tab} index={5}>
        <ProjectLegacyComponent projectId={id} />
      </COSTabPanel>) : null}
    </Container>
  );
};

export default ProjectComponent;

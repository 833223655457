import { ICandidates, useAsync } from '.';

import { CandidateResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

const useProjectCandidateSuggestions = (
  projectId: number,
  skip = 0,
  limit = 100
): ICandidates<CandidateResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateResponse[]
  >(cadabraService.getProjectCandidateSuggestions, [], [projectId, skip, limit]);
  return {
    candidates: data,
    setCandidates: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProjectCandidateSuggestions;

import * as React from 'react';

import { Box, Container } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { COSLink } from '../../helpers/ui';
import CandidateComponent from '../../components/CandidateComponent/CandidateComponent';
import CandidateImportComponent from '../../components/CandidateImportComponent/CandidateImportComponent';
import CandidateImportsComponent from '../../components/CandidateImportsComponent/CandidateImportsComponent';
import CandidatesComponent from '../../components/CandidatesComponent/CandidatesComponent';
import ClientComponent from '../../components/ClientComponent/ClientComponent';
import ClientsComponent from '../../components/ClientsComponent/ClientsComponent';
import CompaniesComponent from '../../components/CompaniesComponent/CompaniesComponent';
import CssBaseline from '@mui/material/CssBaseline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeComponent from '../../components/HomeComponent/HomeComponent';
import IconButton from '@mui/material/IconButton';
import InProcessReportComponent from '../../components/InProcessReportComponent/InProcessReportComponent';
import MenuContainer from './MenuContainer/MenuContainer';
import NotFoundPage from '../../components/NotFoundPage/NotFoundPage';
import PositionsComponent from '../../components/PositionsComponent/PositionsComponent';
import ProjectComponent from '../../components/ProjectComponent/ProjectComponent';
import ProjectsComponent from '../../components/ProjectsComponent/ProjectsComponent';
import SearchBarComponent from './MenuContainer/MenuItemContainer/SearchBarComponent';
import SearchReportComponent from '../../components/SearchReportComponent/SearchReportComponent';
import TasksComponent from '../../components/TasksComponent/TasksComponent';
import TechnologiesComponent from '../../components/TechnologiesComponent/TechnologiesComponent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserComponent from '../../components/UserComponent/UserComponent';
import { UserResponse } from '../../services/api';
import UsersComponent from '../../components/UsersComponent/UsersComponent';
import { logout } from '../../firebase';

const MainContainer = () => {
  const [currentUser, setCurrentUser] = useState<UserResponse | null>(null);
  const [open, setOpen] = useState<boolean>(true);

  useEffect(() => {
    const loadUser = async () => {
      // const resp = await cadabraService.getMe();
      setCurrentUser(null);
    };
    void loadUser();
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplate: 'auto 1fr auto / auto 1fr',
        height: '100vh'
      }}
    >
      <CssBaseline />
      <Toolbar
        sx={{
          paddingRight: '4px !important',
          paddingLeft: '4px !important',
          gridColumn: '1 / 4',
          color: '#fff',
          backgroundColor: '#ae2367',
          minHeight: '54px !important'
        }}
      >
        <Box flexGrow={1}>
          <COSLink href={'/'}>
            <img
              src="https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/favicon-96x96.ico"
              style={{
                width: 32,
                height: 32,
                borderRadius: 8
              }}
            />
          </COSLink>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 4px'
          }}
        >
          <SearchBarComponent />
          {currentUser != null && (
            <Typography component="h1" variant="h6" color="inherit" noWrap align="right">
              {`${currentUser?.name ?? 'User'}`}
            </Typography>
          )}
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        sx={{
          gridColumn: '1 / 2',
          backgroundColor: '#FAFAFA',
          overflowX: 'hidden',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 70px)',
          width: !open ? 50 : undefined
        }}
      >
        <MenuContainer
          open={open}
          handleDrawerToggle={handleDrawerToggle}
          toolbarIcon="toolbarIcon"
        />
      </Box>
      <Box
        sx={{
          gridColumn: '2 / 3',
          // backgroundColor: 'white',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 70px)'
        }}
      >
        {/* <div className={classes.appBarSpacer} /> */}
        <Container
          maxWidth="xl"
          sx={{
            paddingTop: 4,
            margin: 0
          }}
        >
          <Routes>
            <Route path="/" element={<HomeComponent />} />
            <Route path="/candidate" element={<CandidateComponent />} />
            <Route path="/user" element={<UserComponent />} />
            <Route path="/candidates" element={<CandidatesComponent />} />
            <Route path="/candidate-imports" element={<CandidateImportsComponent />} />
            <Route path="/projects" element={<ProjectsComponent />} />
            <Route path="/clients" element={<ClientsComponent />} />
            <Route path="/client" element={<ClientComponent />} />
            <Route path="/users" element={<UsersComponent />} />
            <Route path="/companies" element={<CompaniesComponent />} />
            <Route path="/positions" element={<PositionsComponent />} />
            <Route path="/technologies" element={<TechnologiesComponent />} />
            <Route path="/project" element={<ProjectComponent />} />
            <Route path="/candidate-import" element={<CandidateImportComponent />} />
            <Route path="/tasks" element={<TasksComponent />} />
            <Route path="/search" element={<SearchReportComponent />} />
            <Route path="/in-process" element={<InProcessReportComponent />} />
            <Route path="/not_found" element={<NotFoundPage />} />
          </Routes>
        </Container>
      </Box>
      {/* <div className={classes.additionalMenu}>ADDITIONAL MENU</div> */}
    </Box>
  );
};

export default MainContainer;

import { Backup, CloseOutlined, Delete, Edit, LinkOutlined } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { COSCellProps, COSIconButton, COSRowActions, COSTable } from '../../helpers/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { cadabraService, snackbarService } from '../../services/services';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';

import Axios from 'axios';
import { CandidateImportResponse } from '../../services/api';
import { DatePicker } from '@mui/x-date-pickers';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const CandidateImportsComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);

  const [candidateImports, setCandidateImports] = useState<CandidateImportResponse[]>([]);
  const [filterDate, setFilterDate] = useState<Moment | null>(
    searchParams.has('createdDate') ? moment(searchParams.get('createdDate')) : moment()
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCandidateImports = useCallback(async () => {
    const resp = await cadabraService.getCandidateImports(filterDate?.format('YYYY-MM-DD'));
    if (resp) {
      setCandidateImports(
        resp.data.sort((a: CandidateImportResponse, b: CandidateImportResponse) => b.id - a.id)
      );
    }
    setIsLoading(false);
  }, [filterDate]);

  useEffect(() => {
    getCandidateImports();
    setSearchParams(
      createSearchParams(
        filterDate
          ? {
              createdDate: filterDate?.format('YYYY-MM-DD')
            }
          : {}
      )
    );
  }, [filterDate]);

  const handleDelete = useCallback(async (candidateImport: CandidateImportResponse) => {
    const resp = await cadabraService.deleteCandidateImport(candidateImport.id);
    snackbarService.setSnackbarOpen(true, `Import ${resp.data.id} is deleted`, 'success');
    getCandidateImports();
  }, []);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Candidate Imports
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item md={4}>
            <DatePicker<Moment>
              views={['year', 'month', 'day']}
              label="Date"
              inputFormat="DD/MM/YY"
              mask="__/__/__"
              disableMaskedInput={false}
              value={filterDate}
              onChange={setFilterDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    endAdornment:
                      filterDate != null ? (
                        <>
                          <InputAdornment sx={{ marginRight: -2 }} position="end">
                            <COSIconButton color="inherit" onClick={() => setFilterDate(null)}>
                              <CloseOutlined />
                            </COSIconButton>
                          </InputAdornment>
                          {params.InputProps?.endAdornment}
                        </>
                      ) : (
                        params.InputProps?.endAdornment
                      )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item lg={12}>
            <COSTable<CandidateImportResponse>
              isLoading={isLoading}
              data={candidateImports}
              columns={[
                {
                  accessorKey: 'id',
                  header: 'Actions',
                  size: 60,
                  maxSize: 60,
                  Cell: ({ row }: COSCellProps<CandidateImportResponse>) => (
                    <COSIconButton
                      onClick={() => navigate(`/candidate-import?id=${row.original.id}`)}
                    >
                      <Backup />
                    </COSIconButton>
                  )
                },
                {
                  accessorKey: 'url',
                  header: 'Path',
                  size: 500
                },
                {
                  Cell: ({ row }) => moment(row.original.createdAt).format('DD/MM/YY, HH:mm:ss'),
                  accessorKey: 'createdAt',
                  header: 'Created at',
                  size: 150,
                  muiTableHeadCellFilterTextFieldProps: {
                    type: 'date'
                  },
                  sortingFn: 'datetime'
                }
              ]}
              renderRowActions={({ row }: COSRowActions<CandidateImportResponse>) => (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip arrow placement="top" title="Edit">
                    <IconButton
                      onClick={() => navigate(`/candidate-import?id=${row.original.id}`)}
                      size="small"
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="Delete">
                    <IconButton
                      color="error"
                      onClick={async () => await handleDelete(row.original)}
                      size="small"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="top" title="LinkedIn profile">
                    <IconButton
                      color="info"
                      onClick={() => window.open(row.original.url, '_blank', 'noopener,noreferrer')}
                      size="small"
                    >
                      <LinkOutlined />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(CandidateImportsComponent, Axios);

import { Box, Typography } from '@mui/material';
import { COSButton, COSCellProps, COSLink, COSTable, COSUserAvatar } from '../../../helpers/ui';
import {
  CandidateUserRelationResponse,
  CreateCandidateUserRelationRequest
} from '../../../services/api';
import React, { useEffect, useState } from 'react';
import { RelationTypeKey, getRelationTypesByKey } from '../../../services/cadabraService';

import CandidateUserRelationDialog from './CandidateUserRelationDialog';
import { cadabraService } from '../../../services/services';

interface CandidateUserRelationComponentProps {
  candidateId: number;
}

const CandidateUserRelationComponent = ({ candidateId }: CandidateUserRelationComponentProps) => {
  const [candidateUserRelation, setCandidateUserRelation] = useState<
    CandidateUserRelationResponse[]
  >([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  useEffect(() => {
    const loadData = async () => {
      if (candidateId) {
        const candidateUserRelationResp = await cadabraService.getCandidateUserRelation(
          candidateId
        );
        setCandidateUserRelation(candidateUserRelationResp.data);
      }
    };
    loadData();
  }, [candidateId]);

  return (
    <Box>
      <COSButton text="ADD" onClick={() => setOpenDialog(true)} />
      <COSTable<CandidateUserRelationResponse>
        isLoading={false}
        data={candidateUserRelation}
        enablePagination={false}
        columns={[
          {
            accessorKey: 'id',
            header: '',
            size: 50,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => (
              <COSLink href={`/user?id=${row.original.userId}`}>
                <COSUserAvatar
                  id={row.original.userId}
                  name={row.original.userName}
                  tooltipPlacement="left"
                  sx={{ marginY: 0.5 }}
                  withImage={true}
                />
              </COSLink>
            )
          },
          {
            accessorKey: 'userName',
            header: 'User',
            size: 250,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => (
              <COSLink href={`/user?id=${row.original.userId}`}>{row.original.userName}</COSLink>
            )
          },
          {
            accessorKey: 'relationType',
            header: 'Relation type',
            size: 700,
            Cell: ({ row }: COSCellProps<CandidateUserRelationResponse>) => {
              const value = row.original.relationType
                ? getRelationTypesByKey(row.original.relationType as RelationTypeKey).label
                : '-';
              return <Typography>{value}</Typography>;
            }
          }
        ]}
      />
      <CandidateUserRelationDialog
        candidateId={candidateId}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={async (candidateUserRelationRequest: CreateCandidateUserRelationRequest) => {
          const resp = await cadabraService.createCandidateUserRelation(
            candidateUserRelationRequest
          );
          if (resp) {
            setCandidateUserRelation([resp.data, ...candidateUserRelation]);
            setOpenDialog(false);
          }
        }}
      />
    </Box>
  );
};

export default CandidateUserRelationComponent;

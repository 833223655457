import { AlertColor } from '@mui/material';
import { Observable } from '../helpers/observable';

export interface Snackbar {
  readonly snackbarOpen: boolean;
  message: string;
  severity: AlertColor;
}

export class SnackbarService {
  readonly snackbar = new Observable<Snackbar>({ snackbarOpen: false, message: '', severity: 'success' });

  isSnackbarOpen = () => this.snackbar.get();

  setSnackbarOpen = (isOpen: boolean, message: string, severity: AlertColor) => {
    this.snackbar.set({ snackbarOpen: isOpen, message, severity });
  };
}

import { Avatar, SxProps, Theme, Tooltip } from '@mui/material';

import React from 'react';
import { stringAvatar } from '..';

interface COSUserAvatarProps {
  id: number;
  name: string;
  withImage?: boolean;
  tooltipText?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  sx?: SxProps<Theme> | undefined;
}

const COSUserAvatar = ({
  id,
  name,
  withImage = false,
  sx = { width: 36, height: 36, fontSize: 18 },
  tooltipText,
  tooltipPlacement = 'top'
}: COSUserAvatarProps) => {
  return (
    <Tooltip arrow placement={tooltipPlacement} title={tooltipText ?? name}>
      {withImage ? (
        <Avatar
          src={`https://prod-cadabra-api-images.s3.eu-central-1.amazonaws.com/images/users/${id}.jpeg`}
          sx={sx}
        />
      ) : (
        <Avatar {...stringAvatar(id, name, sx)} />
      )}
    </Tooltip>
  );
};

export default COSUserAvatar;

import { COSAsyncAutocomplete, COSButton, Lookup } from '../../../helpers/ui';
import {
  CompanyResponse,
  CreateCandidateHistoryRequest,
  PositionResponse
} from '../../../services/api';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import useCompanies from '../../../helpers/hooks/useCompanies';
import { useDebounce } from '@react-hook/debounce';
import usePositions from '../../../helpers/hooks/usePositions';

export interface CandidateWorkHistoryDialogProps {
  candidateId: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateCandidateHistoryRequest) => void;
}

const CandidateWorkHistoryDialog = ({
  candidateId,
  open,
  onClose,
  onSave
}: CandidateWorkHistoryDialogProps) => {
  const companiesData = useCompanies();
  const [companyValue, setCompanyValue] = useState<Lookup | null>(null);
  const [companiesFilter, setCompaniesFilter] = useDebounce<string>('', 250);
  const positionsData = usePositions();
  const [positionValue, setPositionValue] = useState<Lookup | null>(null);
  const [positionsFilter, setPositionsFilter] = useDebounce<string>('', 250);
  const [startMonth, setStartMonth] = useState<Moment | null>(null);
  const [endMonth, setEndMonth] = useState<Moment | null>(null);

  const isFormValid = useMemo(
    () => companyValue && positionValue && startMonth && endMonth,
    [companyValue, positionValue, startMonth, endMonth]
  );

  useEffect(() => {
    companiesData.setParameters([companiesFilter]);
  }, [companiesFilter]);

  useEffect(() => {
    positionsData.setParameters([positionsFilter]);
  }, [positionsFilter]);

  const handleOnClose = useCallback(() => {
    setCompanyValue(null);
    setPositionValue(null);
    setStartMonth(null);
    setEndMonth(null);
    onClose();
  }, [onClose]);

  const handleOnSave = useCallback(() => {
    onSave({
      companyId: companyValue!.id,
      positionId: positionValue!.id,
      startDate: startMonth!.startOf('month').format('YYYY-MM-DD'),
      endDate: endMonth!.endOf('month').format('YYYY-MM-DD'),
      candidateId
    });
    handleOnClose();
  }, [companyValue, positionValue, startMonth, endMonth, handleOnClose, onSave]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Candidate work history</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={2}>
          <Grid item lg={6}>
            <COSAsyncAutocomplete<Lookup>
              label="Company"
              value={companyValue}
              options={companiesData.companies.map((company: CompanyResponse) => ({
                id: company.id,
                value: company.name
              }))}
              loading={companiesData.loading}
              onChange={(value: Lookup | null) => setCompanyValue(value)}
              minChar={0}
              filter={companiesFilter}
              onChangeFilter={(companiesFilter: string) => setCompaniesFilter(companiesFilter)}
            />
          </Grid>
          <Grid item lg={6}>
            <COSAsyncAutocomplete<Lookup>
              label="Position"
              value={positionValue}
              options={positionsData.positions.map((position: PositionResponse) => ({
                id: position.id,
                value: position.name
              }))}
              loading={positionsData.loading}
              onChange={(value: Lookup | null) => setPositionValue(value)}
              minChar={0}
              filter={positionsFilter}
              onChangeFilter={(positionsFilter: string) => setPositionsFilter(positionsFilter)}
            />
          </Grid>
          <Grid item lg={6}>
            <DatePicker<Moment>
              views={['year', 'month']}
              label="From"
              inputFormat="MM/YY"
              mask="__/__"
              disableMaskedInput={false}
              value={startMonth}
              onChange={setStartMonth}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={6}>
            <DatePicker<Moment>
              views={['year', 'month']}
              label="To"
              inputFormat="MM/YY"
              mask="__/__"
              disableMaskedInput={false}
              value={endMonth}
              onChange={setEndMonth}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <COSButton
          text="Save"
          onClick={handleOnSave}
          color="primary"
          variant="contained"
          disabled={!isFormValid}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CandidateWorkHistoryDialog;

import { Button, CircularProgress, SxProps, Theme } from '@mui/material';

import React from 'react';

type COSButtonColor =
  | 'inherit'
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'primary'
  | 'secondary'
  | undefined;

interface COSButtonProps {
  text: string;
  color?: COSButtonColor;
  variant?: 'text' | 'outlined' | 'contained';
  sx?: SxProps<Theme> | undefined;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  startIcon?: any;
}

const COSButton = ({
  text,
  color = 'primary',
  variant,
  sx,
  onClick,
  disabled = false,
  loading = false,
  fullWidth = false,
  startIcon
}: COSButtonProps) => {
  return (
    <Button
      sx={sx}
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      startIcon={startIcon}
    >
      {!loading && text}
      {loading && <CircularProgress size={24} />}
    </Button>
  );
};

export default COSButton;

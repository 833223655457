import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { ProjectResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IProject<T> extends IAsyncType<T> {
  project: T;
  setProject: Dispatch<SetStateAction<T>>;
}

const useProject = (id?: number): IProject<ProjectResponse | null> => {
  const { data, setData, loading, setLoading, parameters, setParameters } =
    useAsync<ProjectResponse | null>(cadabraService.getProject, null, id ? [id] : null);
  return {
    project: data,
    setProject: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProject;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateProjectEventLogResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface ICandidateEventLogs<T> extends IAsyncType<T> {
  eventLogs: T;
  setEventLogs: Dispatch<SetStateAction<T>>;
}

const useCandidateEventLogs = (
  candidateId: number,
  fromDate?: string,
  toDate?: string,
  skip: number = 0,
  limit: number = 500
): ICandidateEventLogs<CandidateProjectEventLogResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateProjectEventLogResponse[]
  >(
    cadabraService.getCandidateProjectEventLogs,
    [],
    [[candidateId], [], [], [], fromDate, toDate, limit, skip]
  );
  return {
    eventLogs: data,
    setEventLogs: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useCandidateEventLogs;

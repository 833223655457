import { ArrowBackIosOutlined, ArrowForwardIosOutlined, CloseOutlined } from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import {
  COSCandidateAvatar,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSTable
} from '../../../helpers/ui';
import { CandidateProjectEventLogResponse, UserInteractionResponse } from '../../../services/api';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../../services/cadabraService';
import React, { useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';

import { DatePicker } from '@mui/x-date-pickers';
import { useUserEventLogs } from '../../../helpers/hooks';

export interface CandidateProjectEventLog extends CandidateProjectEventLogResponse {
  projectName?: string;
  users: UserInteractionResponse[];
}

interface UserCandidateProjectEventLogsProps {
  id: number;
}

const apiFetchSize = 25;

const UserCandidateProjectEventLogs = ({ id }: UserCandidateProjectEventLogsProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [filterDate, setFilterDate] = useState<Moment | null>(
    searchParams.has('filterDate') ? moment(searchParams.get('filterDate')) : moment()
  );
  const { eventLogs, loading, setParameters } = useUserEventLogs(
    id,
    [],
    filterDate?.format('YYYY-MM-DD'),
    filterDate?.format('YYYY-MM-DD'),
    apiFetchSize,
    0
  );
  const [eventLogsData, setEventLogsData] = useState<CandidateProjectEventLog[]>([]);
  const [page, setPage] = useState<number>(
    searchParams.has('selectedPage') ? +(searchParams.get('selectedPage') ?? '0') : 0
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setEventLogsData(
      eventLogs.map((log: CandidateProjectEventLogResponse) => ({
        ...log,
        projectName: `${log.positionName} @ ${log.companyName}`,
        comment: log.comment?.replace('<span style="color: #ff6c00;">', '').replace('</span>', ''),
        users: []
      }))
    );
  }, [eventLogs]);

  useEffect(() => {
    const filterDateFormatter = filterDate?.format('YYYY-MM-DD');
    const limit = filterDateFormatter ? 1000 : apiFetchSize;
    const skip = filterDateFormatter ? 0 : page * apiFetchSize;
    setParameters([[], [], [id], [], filterDateFormatter, filterDateFormatter, limit, skip]);
    setSearchParams(
      createSearchParams(
        filterDateFormatter
          ? {
              id: `${id}`,
              tabId: '1',
              filterDate: filterDateFormatter
            }
          : { id: `${id}`, tabId: '1', selectedPage: `${page}` }
      )
    );
  }, [filterDate, page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [eventLogs]);

  return (
    <Box sx={{ margin: 2 }}>
      <Grid container spacing={1}>
        <Grid item md={4}>
          <DatePicker<Moment>
            views={['year', 'month', 'day']}
            label="Date"
            inputFormat="DD/MM/YY"
            mask="__/__/__"
            disableMaskedInput={false}
            value={filterDate}
            onChange={setFilterDate}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                fullWidth
                margin="dense"
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment:
                    filterDate != null ? (
                      <>
                        <InputAdornment sx={{ marginRight: -2 }} position="end">
                          <COSIconButton color="inherit" onClick={() => setFilterDate(null)}>
                            <CloseOutlined />
                          </COSIconButton>
                        </InputAdornment>
                        {params.InputProps?.endAdornment}
                      </>
                    ) : (
                      params.InputProps?.endAdornment
                    )
                }}
              />
            )}
          />
        </Grid>
        <Grid item lg={12}>
          <COSTable<CandidateProjectEventLog>
            isLoading={loading}
            data={eventLogsData}
            enablePagination={false}
            muiTableContainerProps={{
              ref: tableContainerRef,
              sx: { maxHeight: '60vh' }
            }}
            columns={[
              {
                accessorKey: 'id',
                header: '#',
                size: 50,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) =>
                  row.index + page * apiFetchSize + 1
              },
              {
                accessorKey: 'id',
                header: '',
                size: 50,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) => {
                  return (
                    <COSLink href={`/candidate?id=${row.original.candidateId}`}>
                      <COSCandidateAvatar
                        id={row.original.candidateId}
                        name={row.original.candidateName}
                        imagePath={row.original.imagePath}
                        tooltipPlacement="left"
                      />
                    </COSLink>
                  );
                }
              },
              {
                accessorKey: 'candidateName',
                header: 'Candidate',
                size: 150,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) => {
                  return (
                    <COSLink href={`/candidate?id=${row.original.candidateId}`}>
                      <Typography>{row.original.candidateName}</Typography>
                    </COSLink>
                  );
                }
              },
              {
                accessorKey: 'createdAt',
                header: 'Date',
                size: 150,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) => {
                  const value = moment(row.original.createdAt ?? '2020-01-01T00:00:00').format(
                    'DD/MM/YY, HH:mm:ss'
                  );
                  return <Typography>{value}</Typography>;
                },
                sortingFn: (row1, row2) =>
                  moment(row1.original.createdAt).isBefore(moment(row2.original.createdAt)) ? -1 : 1
              },
              {
                accessorKey: 'type',
                header: 'Type',
                size: 120,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) => {
                  const value = row.original.type
                    ? getInteractionTypesByKey(row.original.type as InteractionTypeKey).label
                    : '-';
                  return <Typography>{value}</Typography>;
                }
              },
              {
                accessorKey: 'positionName',
                header: 'Project',
                size: 150,
                Cell: ({ row }: COSCellProps<CandidateProjectEventLog>) => (
                  <COSLink href={`/project?id=${row.original.projectId}`}>
                    {`${row.original.positionName} @ ${row.original.companyName}`}
                  </COSLink>
                )
              },
              {
                accessorKey: 'comment',
                header: 'Comment',
                size: 330
              }
            ]}
            renderBottomToolbarCustomActions={
              !filterDate
                ? () => (
                    <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                      <IconButton
                        onClick={() => setPage(page > 0 ? page - 1 : 0)}
                        disabled={page === 0}
                      >
                        <ArrowBackIosOutlined />
                      </IconButton>
                      <Box
                        sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}
                      >
                        <Typography align="center" variant="h4" component="h5">
                          {page + 1}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => setPage(page + 1)}
                        disabled={eventLogs.length < apiFetchSize}
                      >
                        <ArrowForwardIosOutlined />
                      </IconButton>
                    </Box>
                  )
                : undefined
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserCandidateProjectEventLogs;

import { createTheme } from '@mui/material/styles';

export const mainColor = '#3f51b5';
export const secondaryColor = '#F65B40';

const CustomTheme = createTheme({
  palette: {
    primary: {
      main: mainColor,
      light: mainColor,
      dark: mainColor,
      contrastText: '#fff'
    },
    error: {
      main: '#FD9728'
    }
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '@media (max-height: 768px), (max-width: 1820px)': {
            padding: 9,
            fontSize: '0.75rem !important'
          }
        },
        footer: {
          left: 0,
          bottom: 0,
          zIndex: 2,
          position: 'sticky'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: 1,
          marginBottom: 1
        },
        primary: {
          fontSize: 14
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
          maxWidth: 36
        }
      }
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          padding: 0,
          backgroundColor: '#be4e85'
        }
      }
    }
  }
});

export default CustomTheme;

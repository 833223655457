import {
  Autocomplete,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  ListItem,
  TextField
} from '@mui/material';
import React, { SyntheticEvent, useMemo } from 'react';

import { Lookup } from '..';

interface COSAsyncAutocompleteProps<T extends Lookup> {
  label?: string;
  options: T[];
  value: T | null;
  loading: boolean;
  onChange: (option: T | null) => void;
  filter?: string;
  onChangeFilter?: (filter: string) => void;
  onEnter?: (option: T) => void;
  canAddNew?: boolean;
  minChar?: number;
  required?: boolean;
  disabled?: boolean;
  limit?: number;
  limitTags?: number;
  getOptionLabel?: (option: T) => string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => JSX.Element;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
}

const COSAsyncAutocomplete = <T extends Lookup>({
  label = '',
  options = [],
  value = null,
  filter,
  loading = false,
  onChange,
  onChangeFilter,
  onEnter,
  canAddNew = false,
  disabled = false,
  required = false,
  minChar = 3,
  getOptionLabel = (option: T) => `${option.value}`,
  renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: T,
    state: AutocompleteRenderOptionState
  ) => {
    return option.id > 0 ? (
      <ListItem {...props}>{option.value}</ListItem>
    ) : (
      <ListItem {...props}>{`Press enter to add '${option.value}'`}</ListItem>
    );
  },
  renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      margin="dense"
      variant="outlined"
      placeholder={minChar > 0 ? `Minimum ${minChar} characters` : ''}
      fullWidth
      required={required}
      disabled={disabled}
      onChange={(event) => {
        if (onChangeFilter != null && event.target.value.length >= minChar) {
          onChangeFilter(event.target.value);
        }
      }}
      onKeyDown={(e) => {
        if (e.code !== 'Enter') return;
        onEnter?.(
          options.find((option) => option.value === filter) ??
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ({ id: 0, value: filter ?? '' } as T)
        );
      }}
    />
  )
}: COSAsyncAutocompleteProps<T>) => {
  const hasExactMatch = useMemo(
    () => options.findIndex((option) => option.value === filter) >= 0,
    [options, filter]
  );
  const opts = useMemo(() => {
    if (hasExactMatch || !canAddNew || filter === '') {
      return options;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return [{ id: 0, value: filter ?? '' } as T, ...options];
  }, [hasExactMatch, canAddNew, options, filter]);
  return (
    <Autocomplete<T, false, false, false>
      options={opts}
      loading={loading}
      value={value}
      filterOptions={(opts: T[], state: FilterOptionsState<T>) => opts}
      onChange={(event: SyntheticEvent, option: T | null) => onChange(option)}
      getOptionLabel={getOptionLabel}
      fullWidth
      disabled={disabled}
      renderOption={renderOption}
      renderInput={renderInput}
      isOptionEqualToValue={(option: T, value: T) => {
        return option.id === value.id;
      }}
      size="small"
      noOptionsText="No results were found"
      loadingText="Loading..."
    />
  );
};

export default COSAsyncAutocomplete;

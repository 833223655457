import {
  CityResponse,
  CreateCandidateFromImportRequest,
  CreateCandidateHistoryRequest,
  CreateCandidateProjectEventLogRequest,
  CreateCandidateRequest,
  CreateCandidateUserRelationRequest,
  CreateCityRequest,
  CreateClientRequest,
  CreateCompanyRequest,
  CreatePositionRequest,
  CreateProjectRequest,
  CreateTaskRequest,
  CreateTechnologyRequest,
  CreateUserRequest,
  PatchCandidateRequest,
  PatchClientRequest,
  PatchProjectRequest,
  PatchTaskRequest,
  PatchTechnologyRequest,
  PatchUserRequest,
  ProjectSimpleResponse,
  TechnologyResponse
} from './api';
import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import { backendApiUrl, cadabraApi } from '../helpers/openapi-axios-helper';

import { auth } from '../firebase';

export class CadabraService {
  // getMe = async () => {
  //   const api = await cadabraApi();
  //   return await api.getMeV1MeGet();
  // };

  getUser = async (id: number) => {
    const api = await cadabraApi();
    return await api.readUserV1UserIdGet(id);
  };

  getUsers = async (
    search?: string,
    showInactive: boolean = false,
    isAdmin?: boolean,
    skip: number = 0,
    limit: number = 50
  ) => {
    const api = await cadabraApi();
    return await api.readUsersV1UserGet(showInactive, isAdmin, search, skip, limit);
  };

  createUser = async (createUserRequest: CreateUserRequest) => {
    const api = await cadabraApi();
    return await api.createUserV1UserPost(createUserRequest);
  };

  patchUser = async (id: number, patchUserRequest: PatchUserRequest) => {
    const api = await cadabraApi();
    return await api.updateUserV1UserIdPatch(id, patchUserRequest);
  };

  getCandidate = async (id: number) => {
    const api = await cadabraApi();
    return await api.readCandidateV1CandidateIdGet(id);
  };

  getCandidateImage = async (imagePath: string) => {
    const idToken: string | undefined = await auth.currentUser?.getIdToken(false);
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer' as ResponseType,
      headers: { Authorization: `Bearer ${idToken}` }
    };
    const resp = await axios.get(`${backendApiUrl}/${imagePath}`, config);
    return resp;
  };

  getCandidateHistory = async (id: number) => {
    const api = await cadabraApi();
    return await api.readCandidateHistoryV1CandidateHistoryIdGet(id);
  };

  getCandidates = async (
    search?: string,
    linkedinUrl?: string,
    technologies?: number[],
    desiredTechnologies?: number[],
    mainTechnologies?: number[],
    positions?: number[],
    cities?: number[],
    weeksSinceLastContact?: number,
    limit = 200,
    skip = 0
  ) => {
    const api = await cadabraApi();
    return await api.readCandidatesV1CandidateGet(
      search,
      linkedinUrl,
      technologies?.join(','),
      desiredTechnologies?.join(','),
      mainTechnologies?.join(','),
      positions?.join(','),
      cities?.join(','),
      weeksSinceLastContact,
      limit,
      skip
    );
  };

  createCandidate = async (createCandidateRequest: CreateCandidateRequest) => {
    const api = await cadabraApi();
    return await api.createCandidateV1CandidatePost(createCandidateRequest);
  };

  patchCandidate = async (id: number, patchCandidateRequest: PatchCandidateRequest) => {
    const api = await cadabraApi();
    return await api.patchCandidateV1CandidateIdPatch(id, patchCandidateRequest);
  };

  getProject = async (id: number) => {
    const api = await cadabraApi();
    return await api.readProjectV1ProjectIdGet(id);
  };

  getProjects = async (
    search?: string,
    technologies?: TechnologyResponse[],
    optionalTechnologies?: TechnologyResponse[],
    showInactive: boolean = false,
    limit = 200,
    skip = 0
  ) => {
    const api = await cadabraApi();
    return await api.readProjectsV1ProjectGet(
      search,
      technologies?.map((t: TechnologyResponse) => t.id).join(','),
      optionalTechnologies?.map((t: TechnologyResponse) => t.id).join(','),
      showInactive,
      limit,
      skip
    );
  };

  createProject = async (createProjectRequest: CreateProjectRequest) => {
    const api = await cadabraApi();
    return await api.createProjectV1ProjectPost(createProjectRequest);
  };

  patchProject = async (id: number, patchProjectRequest: PatchProjectRequest) => {
    const api = await cadabraApi();
    return await api.patchProjectV1ProjectIdPatch(id, patchProjectRequest);
  };

  getClient = async (id: number) => {
    const api = await cadabraApi();
    return await api.readClientV1ClientIdGet(id);
  };

  getClients = async (
    search?: string,
    showInactive: boolean = false,
    limit: number = 200,
    skip: number = 0
  ) => {
    const api = await cadabraApi();
    return await api.readClientsV1ClientGet(search, showInactive, limit, skip);
  };

  createClient = async (createClientRequest: CreateClientRequest) => {
    const api = await cadabraApi();
    return await api.createClientV1ClientPost(createClientRequest);
  };

  patchClient = async (id: number, patchClientRequest: PatchClientRequest) => {
    const api = await cadabraApi();
    return await api.updateClientV1ClientIdPatch(id, patchClientRequest);
  };

  deactivateClient = async (id: number) => {
    const api = await cadabraApi();
    return await api.deactivateClientV1ClientIdDelete(id);
  };

  getCompanies = async (search?: string, limit: number = 50, skip: number = 0) => {
    const api = await cadabraApi();
    return await api.readCompaniesV1CompanyGet(search, skip, limit);
  };

  createCompany = async (createCompanyRequest: CreateCompanyRequest) => {
    const api = await cadabraApi();
    return await api.createCompanyV1CompanyPost(createCompanyRequest);
  };

  getPositions = async (
    search?: string,
    showInactive: boolean = false,
    limit: number = 100,
    skip: number = 0
  ) => {
    const api = await cadabraApi();
    return await api.readPositionsV1PositionGet(showInactive, search, skip, limit);
  };

  createPosition = async (createPositionRequest: CreatePositionRequest) => {
    const api = await cadabraApi();
    return await api.createPositionV1PositionPost(createPositionRequest);
  };

  deactivatePosition = async (id: number) => {
    const api = await cadabraApi();
    return await api.deactivatePositionV1PositionIdDelete(id);
  };

  getTechnologies = async (
    search?: string,
    showInactive: boolean = false,
    names: string[] = [],
    limit: number = 500,
    skip: number = 0
  ) => {
    const api = await cadabraApi();
    return await api.readTechnologiesV1TechnologyGet(
      showInactive,
      search,
      names.join(','),
      skip,
      limit
    );
  };

  createTechnology = async (createTechnologyRequest: CreateTechnologyRequest) => {
    const api = await cadabraApi();
    return await api.createTechnologyV1TechnologyPost(createTechnologyRequest);
  };

  patchTechnology = async (id: number, patchTechnologyRequest: PatchTechnologyRequest) => {
    const api = await cadabraApi();
    return await api.patchTechnologyV1TechnologyIdPatch(id, patchTechnologyRequest);
  };

  deactivateTechnology = async (id: number) => {
    const api = await cadabraApi();
    return await api.deleteTechnologyV1TechnologyIdDelete(id);
  };

  getCities = async (
    search?: string,
    showInactive: boolean = false,
    skip: number = 0,
    limit: number = 50
  ) => {
    const api = await cadabraApi();
    const citiesResp = await api.readCitiesV1CityGet(showInactive);
    return citiesResp.data.filter((city: CityResponse) => {
      return city.name.toLowerCase().includes((search ?? '').toLowerCase());
    });
  };

  createCity = async (createCitRequest: CreateCityRequest) => {
    const api = await cadabraApi();
    return await api.createCityV1CityPost(createCitRequest);
  };

  getCandidateProjectEventLogs = async (
    candidates?: number[],
    projects?: number[],
    users?: number[],
    types?: number[],
    fromDate?: string,
    toDate?: string,
    limit?: number,
    skip?: number
  ) => {
    const api = await cadabraApi();
    return await api.readCandidateProjectEventLogsV1CandidateProjectEventLogGet(
      candidates?.join(','),
      projects?.join(','),
      users?.join(','),
      types?.join(','),
      fromDate,
      toDate,
      limit,
      skip
    );
  };

  getCandidatesByProjectInProcessAndLastInteraction = async (projectId: number) => {
    const api = await cadabraApi();
    return await api.getCandidatesByProjectAndLastInteractionInProcessV1ProjectProjectIdInProcessGet(
      projectId
    );
  };

  getCandidatesByProjectContactedAndLastInteraction = async (
    projectId: number,
    createdAt?: string,
    limit = 50,
    skip = 0
  ) => {
    const api = await cadabraApi();
    return await api.getCandidatesByProjectAndLastInteractionContactedV1ProjectProjectIdContactedGet(
      projectId,
      createdAt,
      limit,
      skip
    );
  };

  getProjectCandidateSuggestions = async (
    projectId: number,
    skip: number = 0,
    limit: number = 50
  ) => {
    const api = await cadabraApi();
    // TODO: This is a hack to get the technologies of the project. We should have a separate endpoint for this.
    const projectResp = await this.getProject(projectId);
    return await api.readCandidatesV1CandidateGet(
      undefined,
      undefined,
      undefined,
      undefined,
      projectResp.data.technologies.map((t: TechnologyResponse) => t.id).join(','),
      undefined,
      undefined,
      undefined,
      limit,
      skip
    );
  };

  createTask = async (createTaskRequest: CreateTaskRequest) => {
    const api = await cadabraApi();
    return await api.createTaskV1TaskPost(createTaskRequest);
  };

  getTask = async (id: number) => {
    const api = await cadabraApi();
    return await api.readTaskV1TaskIdGet(id);
  };

  createCandidateProjectEventLog = async (
    createCandidateProjectEventLogRequest: CreateCandidateProjectEventLogRequest
  ) => {
    const api = await cadabraApi();
    return await api.createCandidateProjectEventLogV1CandidateProjectEventLogPost(
      createCandidateProjectEventLogRequest
    );
  };

  deleteCandidateProjectEventLog = async (id: number) => {
    const api = await cadabraApi();
    return await api.deleteCandidateProjectEventLogV1CandidateProjectEventLogIdDelete(id);
  };

  createCandidateHistory = async (createCandidateHistoryRequest: CreateCandidateHistoryRequest) => {
    const api = await cadabraApi();
    return await api.createCandidateHistoryV1CandidateHistoryPost(createCandidateHistoryRequest);
  };

  createCandidateUserRelation = async (
    createCandidateUserRelationRequest: CreateCandidateUserRelationRequest
  ) => {
    const api = await cadabraApi();
    return await api.createCandidateUserRelationV1CandidateRelationPost(
      createCandidateUserRelationRequest
    );
  };

  getCandidateImport = async (id: number) => {
    const api = await cadabraApi();
    return await api.readCandidateImportV1CandidateImportIdGet(id);
  };

  getCandidatesByLinkedInUrl = async (linkedInUrl: string) => {
    const api = await cadabraApi();
    return await api.readCandidatesV1CandidateGet(undefined, linkedInUrl);
  };

  createCandidateFromImportRequest = async (createRequest: CreateCandidateFromImportRequest) => {
    const api = await cadabraApi();
    return await api.createCandidateFromImportV1CandidateExternalPost(createRequest);
  };

  deleteCandidateImport = async (id: number) => {
    const api = await cadabraApi();
    return await api.deleteImportV1CandidateImportIdDelete(id);
  };

  getCandidateUserRelation = async (id: number) => {
    const api = await cadabraApi();
    return await api.readCandidateUserRelationsV1CandidateCandidateIdRelationsGet(id);
  };

  getUserCandidateRelation = async (id: number) => {
    const api = await cadabraApi();
    return await api.readUserCandidateRelationsV1UserUserIdRelationsGet(id);
  };

  getCandidateImports = async (createdDate?: string) => {
    const api = await cadabraApi();
    return await api.readCandidateImportsV1CandidateImportGet(createdDate);
  };

  getStats = async (startDate?: string, endDate?: string, types?: string) => {
    const api = await cadabraApi();
    return await api.getCandidateInteractionsStatsV1StatsGet(startDate, endDate, types);
  };

  getStatsByUser = async (userId: number, startDate?: string, endDate?: string, types?: string) => {
    const api = await cadabraApi();
    return await api.getCandidateInteractionsStatsByUserV1StatsUserIdGet(
      userId,
      startDate,
      endDate,
      types
    );
  };

  getCandidateSearchInteractions = async (
    timePeriodType: string,
    startDate?: string,
    endDate?: string
  ) => {
    const api = await cadabraApi();
    return await api.readCandidateSearchInteractionsV1StatsSearchInteractionsGet(
      timePeriodType,
      startDate,
      endDate
    );
  };

  getCandidateInProcessInteractions = async (
    timePeriodType: string,
    startDate?: string,
    endDate?: string
  ) => {
    const api = await cadabraApi();
    return await api.readCandidateInProcessInteractionsV1StatsInProcessInteractionsGet(
      timePeriodType,
      startDate,
      endDate
    );
  };

  getCandidateSearchInteractionsByUsers = async (
    timePeriodType: string,
    userIds?: number[],
    startDate?: string,
    endDate?: string
  ) => {
    const api = await cadabraApi();
    return await api.readCandidateSearchInteractionsByUsersV1StatsSearchInteractionsByUsersGet(
      timePeriodType,
      userIds?.join(','),
      startDate,
      endDate
    );
  };

  getCandidateInProcessInteractionsByUsers = async (
    timePeriodType: string,
    userIds?: number[],
    startDate?: string,
    endDate?: string
  ) => {
    const api = await cadabraApi();
    return await api.readCandidateInProcessInteractionsByUserV1StatsInProcessInteractionsByUsersGet(
      timePeriodType,
      userIds?.join(','),
      startDate,
      endDate
    );
  };

  getTasks = async (
    candidateId?: number,
    startDate?: string,
    endDate?: string,
    showInactive?: boolean
  ) => {
    const api = await cadabraApi();
    return await api.readTasksV1TaskGet(candidateId, startDate, endDate, showInactive);
  };

  patchTask = async (id: number, patchTaskRequest: PatchTaskRequest) => {
    const api = await cadabraApi();
    return await api.updateTaskV1TaskIdPatch(id, patchTaskRequest);
  };

  calculateMatchPercentage = (
    project: ProjectSimpleResponse,
    technologies: TechnologyResponse[]
  ) => {
    if (project.technologies.length === 0) {
      return 0;
    }
    return (
      (project.technologies.reduce((res: number, pt: TechnologyResponse) => {
        if (technologies.findIndex((t) => t.id === pt.id) > -1) {
          res++;
        }
        return res;
      }, 0) /
        project.technologies.length) *
      100
    ).toFixed(2);
  };
}

export enum InteractionTypeId {
  PRELIMINARY = 1,
  CONTACTED_LINKEDIN_MESSAGE = 11,
  CONTACTED_IN_MAIL = 12,
  CONTACTED_VIA_INVITATION = 13,
  CONTACTED_PHONE_CALL = 14,
  CONTACTED_EMAIL = 15,
  CONTACTED_MEETING = 16,
  REPLIED = 21,
  FOLLOW_UP = 22,
  POSTPONED = 23,
  PUSH_LINKEDIN_MESSAGE = 24,
  PUSH_IN_MAIL = 25,
  PUSH_VIA_INVITATION = 26,
  PUSH_PHONE_CALL = 27,
  PUSH_EMAIL = 28,
  PUSH_MEETING = 29,
  SUBMITTED = 31,
  SCHEDULED_INTERVIEW = 41,
  MISSED_INTERVIEW = 42,
  OFFERED = 51,
  OFFER_DECLINED = 52,
  ACCEPTED_OFFER = 61,
  PLACED = 99,
  NOT_INTERESTED = 101,
  DECLINED_BY_RECRUITER = 102,
  REJECTED_BY_CLIENT = 103,
  REJECTED_BY_CANDIDATE = 104,
  OFFER_WITHDRAWN = 105,
  LEGACY = 999
}

export enum InteractionTypeKey {
  PRELIMINARY = 'PRELIMINARY',
  CONTACTED_LINKEDIN_MESSAGE = 'CONTACTED_LINKEDIN_MESSAGE',
  CONTACTED_IN_MAIL = 'CONTACTED_IN_MAIL',
  CONTACTED_VIA_INVITATION = 'CONTACTED_VIA_INVITATION',
  CONTACTED_PHONE_CALL = 'CONTACTED_PHONE_CALL',
  CONTACTED_EMAIL = 'CONTACTED_EMAIL',
  CONTACTED_MEETING = 'CONTACTED_MEETING',
  PUSH_LINKEDIN_MESSAGE = 'PUSH_LINKEDIN_MESSAGE',
  PUSH_IN_MAIL = 'PUSH_IN_MAIL',
  PUSH_VIA_INVITATION = 'PUSH_VIA_INVITATION',
  PUSH_PHONE_CALL = 'PUSH_PHONE_CALL',
  PUSH_EMAIL = 'PUSH_EMAIL',
  PUSH_MEETING = 'PUSH_MEETING',
  REPLIED = 'REPLIED',
  FOLLOW_UP = 'FOLLOW_UP',
  POSTPONED = 'POSTPONED',
  SUBMITTED = 'SUBMITTED',
  SCHEDULED_INTERVIEW = 'SCHEDULED_INTERVIEW',
  MISSED_INTERVIEW = 'MISSED_INTERVIEW',
  OFFERED = 'OFFERED',
  OFFER_DECLINED = 'OFFER_DECLINED',
  ACCEPTED_OFFER = 'ACCEPTED_OFFER',
  PLACED = 'PLACED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  DECLINED_BY_RECRUITER = 'DECLINED_BY_RECRUITER',
  REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
  REJECTED_BY_CANDIDATE = 'REJECTED_BY_CANDIDATE',
  OFFER_WITHDRAWN = 'OFFER_WITHDRAWN',
  LEGACY = 'LEGACY'
}

export enum InteractionTypeLabel {
  PRELIMINARY = 'Preliminary',
  CONTACTED_LINKEDIN_MESSAGE = 'Contact LI message',
  CONTACTED_IN_MAIL = 'Contact in mail',
  CONTACTED_VIA_INVITATION = 'Contact via invitation',
  CONTACTED_PHONE_CALL = 'Contact by phone',
  CONTACTED_EMAIL = 'Contact by email',
  CONTACTED_MEETING = 'Contact by meeting',
  PUSH_LINKEDIN_MESSAGE = 'Push LI message',
  PUSH_IN_MAIL = 'Push in mail',
  PUSH_VIA_INVITATION = 'Push via invitation',
  PUSH_PHONE_CALL = 'Push by phone',
  PUSH_EMAIL = 'Push by email',
  PUSH_MEETING = 'Push by meeting',
  REPLIED = 'Replied',
  FOLLOW_UP = 'Follow up',
  POSTPONED = 'Postponed',
  SUBMITTED = 'Submitted',
  SCHEDULED_INTERVIEW = 'Scheduled interview',
  MISSED_INTERVIEW = 'Missed interview',
  OFFERED = 'Offered',
  OFFER_DECLINED = 'Offer declined',
  ACCEPTED_OFFER = 'Accepted offer',
  PLACED = 'Placed',
  NOT_INTERESTED = 'Not interested',
  DECLINED_BY_RECRUITER = 'Declined by recruiter',
  REJECTED_BY_CLIENT = 'Rejected by client',
  REJECTED_BY_CANDIDATE = 'Rejected by candidate',
  OFFER_WITHDRAWN = 'Offer withdrawn',
  LEGACY = 'Other'
}

export class InteractionType {
  static readonly PRELIMINARY = new InteractionType(
    InteractionTypeKey.PRELIMINARY,
    InteractionTypeLabel.PRELIMINARY
  );

  static readonly CONTACTED_LINKEDIN_MESSAGE = new InteractionType(
    InteractionTypeKey.CONTACTED_LINKEDIN_MESSAGE,
    InteractionTypeLabel.CONTACTED_LINKEDIN_MESSAGE
  );

  static readonly CONTACTED_IN_MAIL = new InteractionType(
    InteractionTypeKey.CONTACTED_IN_MAIL,
    InteractionTypeLabel.CONTACTED_IN_MAIL
  );

  static readonly CONTACTED_VIA_INVITATION = new InteractionType(
    InteractionTypeKey.CONTACTED_VIA_INVITATION,
    InteractionTypeLabel.CONTACTED_VIA_INVITATION
  );

  static readonly CONTACTED_PHONE_CALL = new InteractionType(
    InteractionTypeKey.CONTACTED_PHONE_CALL,
    InteractionTypeLabel.CONTACTED_PHONE_CALL
  );

  static readonly CONTACTED_EMAIL = new InteractionType(
    InteractionTypeKey.CONTACTED_EMAIL,
    InteractionTypeLabel.CONTACTED_EMAIL
  );

  static readonly CONTACTED_MEETING = new InteractionType(
    InteractionTypeKey.CONTACTED_MEETING,
    InteractionTypeLabel.CONTACTED_MEETING
  );

  static readonly PUSH_LINKEDIN_MESSAGE = new InteractionType(
    InteractionTypeKey.PUSH_LINKEDIN_MESSAGE,
    InteractionTypeLabel.PUSH_LINKEDIN_MESSAGE
  );

  static readonly PUSH_IN_MAIL = new InteractionType(
    InteractionTypeKey.PUSH_IN_MAIL,
    InteractionTypeLabel.PUSH_IN_MAIL
  );

  static readonly PUSH_VIA_INVITATION = new InteractionType(
    InteractionTypeKey.PUSH_VIA_INVITATION,
    InteractionTypeLabel.PUSH_VIA_INVITATION
  );

  static readonly PUSH_PHONE_CALL = new InteractionType(
    InteractionTypeKey.PUSH_PHONE_CALL,
    InteractionTypeLabel.PUSH_PHONE_CALL
  );

  static readonly PUSH_EMAIL = new InteractionType(
    InteractionTypeKey.PUSH_EMAIL,
    InteractionTypeLabel.PUSH_EMAIL
  );

  static readonly PUSH_MEETING = new InteractionType(
    InteractionTypeKey.PUSH_MEETING,
    InteractionTypeLabel.PUSH_MEETING
  );

  static readonly REPLIED = new InteractionType(
    InteractionTypeKey.REPLIED,
    InteractionTypeLabel.REPLIED
  );

  static readonly FOLLOW_UP = new InteractionType(
    InteractionTypeKey.FOLLOW_UP,
    InteractionTypeLabel.FOLLOW_UP
  );

  static readonly POSTPONED = new InteractionType(
    InteractionTypeKey.POSTPONED,
    InteractionTypeLabel.POSTPONED
  );

  static readonly SUBMITTED = new InteractionType(
    InteractionTypeKey.SUBMITTED,
    InteractionTypeLabel.SUBMITTED
  );

  static readonly SCHEDULED_INTERVIEW = new InteractionType(
    InteractionTypeKey.SCHEDULED_INTERVIEW,
    InteractionTypeLabel.SCHEDULED_INTERVIEW
  );

  static readonly MISSED_INTERVIEW = new InteractionType(
    InteractionTypeKey.MISSED_INTERVIEW,
    InteractionTypeLabel.MISSED_INTERVIEW
  );

  static readonly OFFERED = new InteractionType(
    InteractionTypeKey.OFFERED,
    InteractionTypeLabel.OFFERED
  );

  static readonly OFFER_DECLINED = new InteractionType(
    InteractionTypeKey.OFFER_DECLINED,
    InteractionTypeLabel.OFFER_DECLINED
  );

  static readonly ACCEPTED_OFFER = new InteractionType(
    InteractionTypeKey.ACCEPTED_OFFER,
    InteractionTypeLabel.ACCEPTED_OFFER
  );

  static readonly PLACED = new InteractionType(
    InteractionTypeKey.PLACED,
    InteractionTypeLabel.PLACED
  );

  static readonly NOT_INTERESTED = new InteractionType(
    InteractionTypeKey.NOT_INTERESTED,
    InteractionTypeLabel.NOT_INTERESTED
  );

  static readonly DECLINED_BY_RECRUITER = new InteractionType(
    InteractionTypeKey.DECLINED_BY_RECRUITER,
    InteractionTypeLabel.DECLINED_BY_RECRUITER
  );

  static readonly REJECTED_BY_CLIENT = new InteractionType(
    InteractionTypeKey.REJECTED_BY_CLIENT,
    InteractionTypeLabel.REJECTED_BY_CLIENT
  );

  static readonly REJECTED_BY_CANDIDATE = new InteractionType(
    InteractionTypeKey.REJECTED_BY_CANDIDATE,
    InteractionTypeLabel.REJECTED_BY_CANDIDATE
  );

  static readonly OFFER_WITHDRAWN = new InteractionType(
    InteractionTypeKey.OFFER_WITHDRAWN,
    InteractionTypeLabel.OFFER_WITHDRAWN
  );

  static readonly LEGACY = new InteractionType(
    InteractionTypeKey.LEGACY,
    InteractionTypeLabel.LEGACY
  );

  private constructor(
    public readonly key: InteractionTypeKey,
    public readonly label: InteractionTypeLabel
  ) {}
}

export const INTERACTION_TYPE = [
  InteractionType.PRELIMINARY,
  InteractionType.CONTACTED_LINKEDIN_MESSAGE,
  InteractionType.CONTACTED_IN_MAIL,
  InteractionType.CONTACTED_VIA_INVITATION,
  InteractionType.CONTACTED_PHONE_CALL,
  InteractionType.CONTACTED_EMAIL,
  InteractionType.CONTACTED_MEETING,
  InteractionType.PUSH_LINKEDIN_MESSAGE,
  InteractionType.PUSH_IN_MAIL,
  InteractionType.PUSH_VIA_INVITATION,
  InteractionType.PUSH_PHONE_CALL,
  InteractionType.PUSH_EMAIL,
  InteractionType.PUSH_MEETING,
  InteractionType.REPLIED,
  InteractionType.FOLLOW_UP,
  InteractionType.POSTPONED,
  InteractionType.SUBMITTED,
  InteractionType.SCHEDULED_INTERVIEW,
  InteractionType.MISSED_INTERVIEW,
  InteractionType.OFFERED,
  InteractionType.OFFER_DECLINED,
  InteractionType.ACCEPTED_OFFER,
  InteractionType.PLACED,
  InteractionType.NOT_INTERESTED,
  InteractionType.DECLINED_BY_RECRUITER,
  InteractionType.REJECTED_BY_CLIENT,
  InteractionType.REJECTED_BY_CANDIDATE,
  InteractionType.OFFER_WITHDRAWN,
  InteractionType.LEGACY
];

export const getInteractionTypesByKey = (key: InteractionTypeKey) =>
  INTERACTION_TYPE.find((status: InteractionType) => status.key === key) ?? {
    key,
    label: `Other (${key})`
  };

export enum RelationTypeKey {
  FIRST_CONNECTION = 'FIRST_CONNECTION',
  HAS_PHONE_PERMISSION = 'HAS_PHONE_PERMISSION',
  HAS_EMAIL_PERMISSION = 'HAS_EMAIL_PERMISSION',
  PERSONAL_FRIEND = 'PERSONAL_FRIEND'
}

export enum RelationTypeLabel {
  FIRST_CONNECTION = 'First connection',
  HAS_PHONE_PERMISSION = 'Has phone permission',
  HAS_EMAIL_PERMISSION = 'Has email permission',
  PERSONAL_FRIEND = 'Personal friend'
}

export class RelationType {
  static readonly FIRST_CONNECTION = new RelationType(
    RelationTypeKey.FIRST_CONNECTION,
    RelationTypeLabel.FIRST_CONNECTION
  );

  static readonly HAS_PHONE_PERMISSION = new RelationType(
    RelationTypeKey.HAS_PHONE_PERMISSION,
    RelationTypeLabel.HAS_PHONE_PERMISSION
  );

  static readonly HAS_EMAIL_PERMISSION = new RelationType(
    RelationTypeKey.HAS_EMAIL_PERMISSION,
    RelationTypeLabel.HAS_EMAIL_PERMISSION
  );

  static readonly PERSONAL_FRIEND = new RelationType(
    RelationTypeKey.PERSONAL_FRIEND,
    RelationTypeLabel.PERSONAL_FRIEND
  );

  private constructor(
    public readonly key: RelationTypeKey,
    public readonly label: RelationTypeLabel
  ) {}
}

export const RELATION_TYPE = [
  RelationType.FIRST_CONNECTION,
  RelationType.HAS_PHONE_PERMISSION,
  RelationType.HAS_EMAIL_PERMISSION,
  RelationType.PERSONAL_FRIEND
];

const invalidRelationType = {
  key: 'INVALID_RELATION_TYPE' as RelationTypeKey,
  label: '<INVALID INTERACRELATIONTION TYPE>'
};

export const getRelationTypesByKey = (key: RelationTypeKey) =>
  RELATION_TYPE.find((status: RelationType) => status.key === key) ?? invalidRelationType;

export const userRelationRank = {
  [RelationTypeKey.FIRST_CONNECTION]: 1,
  [RelationTypeKey.HAS_EMAIL_PERMISSION]: 2,
  [RelationTypeKey.HAS_PHONE_PERMISSION]: 3,
  [RelationTypeKey.PERSONAL_FRIEND]: 4
};

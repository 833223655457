import { Avatar, CircularProgress, SxProps, Theme, Tooltip } from '@mui/material';

import React from 'react';
import { stringAvatar } from '../../ui';
import { useCandidateImage } from '../../hooks';

interface COSCandidateAvatarProps {
  id: number;
  name: string;
  imagePath?: string;
  tooltipText?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  sx?: SxProps<Theme> | undefined;
}

const COSCandidateAvatar = ({
  id,
  name,
  imagePath,
  tooltipText,
  tooltipPlacement = 'top',
  sx = { width: 36, height: 36, fontSize: 18 }
}: COSCandidateAvatarProps) => {
  const { data, loading } = useCandidateImage(imagePath);
  return loading ? (
    <CircularProgress />
  ) : (
    <Tooltip arrow placement={tooltipPlacement} title={tooltipText ?? name}>
      {data ? <Avatar src={data} sx={sx} /> : <Avatar {...stringAvatar(id, name, sx)} />}
    </Tooltip>
  );
};

export default COSCandidateAvatar;

import { CandidateInteractionsResponse, InteractionsDataResponse } from '../../services/api';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { TimePeriod, simpleInteractionTypeColors, useAsync } from '.';

import { cadabraService } from '../../services/services';
import { capitalize } from '@mui/material';
import moment from 'moment';

interface IChartDatasetItem {
  label: string;
  data: number[];
  backgroundColor: string[];
  stack: string;
}

interface IChartData {
  labels: string[];
  datasets: IChartDatasetItem[];
  legend?: any;
  tooltip?: any;
  annotations?: any[];
}

export interface IInProcessInteractionsInfo {
  chartData: IChartData[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setParameters: any;
}

const useInProcessInteractions = (): IInProcessInteractionsInfo => {
  const companyInProcessStats = useAsync<CandidateInteractionsResponse[]>(
    cadabraService.getCandidateInProcessInteractions,
    [],
    [null, null, null]
  );

  const setParameters = (
    timePeriod: TimePeriod,
    startDate?: string | undefined,
    endDate?: string | undefined
  ) => {
    companyInProcessStats.setParameters([timePeriod, startDate, endDate]);
  };

  const createLabel = useCallback((data: InteractionsDataResponse) => {
    const fromDate = moment(data.fromDate).format('DD/MM/YY');
    if (data.fromDate === data.toDate) {
      return fromDate;
    }
    const toDate = moment(data.toDate).format('DD/MM/YY');
    return `${fromDate} - ${toDate}`;
  }, []);

  const labels = useMemo(
    () =>
      companyInProcessStats.data
        .reduce(
          (res: InteractionsDataResponse[], value: CandidateInteractionsResponse) => [
            ...res,
            ...value.interactionsData
          ],
          []
        )
        .reduce((res: string[], value: InteractionsDataResponse) => {
          if (!res.includes(createLabel(value))) {
            res.push(createLabel(value));
          }
          return res;
        }, []),
    [companyInProcessStats]
  );

  return {
    chartData: [
      {
        labels,
        datasets: parseData(companyInProcessStats.data),
        legend: {
          labels: {
            filter: (item: any) => !item.fillStyle.endsWith('20')
          }
        },
        tooltip: {
          filter: (item: any) => item.dataset.label !== 'BEST'
        }
      }
    ],
    loading: companyInProcessStats.loading,
    setLoading: companyInProcessStats.setLoading,
    setParameters
  };
};

export default useInProcessInteractions;

const parseData = (data: CandidateInteractionsResponse[]) =>
  data.reduce(
    (res: IChartDatasetItem[], resp: CandidateInteractionsResponse) => [
      ...res,
      {
        label: capitalize(resp.simpleInteractionType),
        data: resp.interactionsData.map(
          (value: InteractionsDataResponse) =>
            value.interactionsCount - resp.targetInteractionsCount
        ),
        backgroundColor: resp.interactionsData.map((value: InteractionsDataResponse) =>
          value.interactionsCount - resp.targetInteractionsCount > 0
            ? `${simpleInteractionTypeColors[resp.simpleInteractionType]}BB`
            : `${simpleInteractionTypeColors[resp.simpleInteractionType]}80`
        ),
        stack: resp.simpleInteractionType
      },
      {
        label: 'BEST',
        data: resp.interactionsData.map((value: InteractionsDataResponse) =>
          resp.targetInteractionsCount > resp.bestInteractionsCount
            ? 0
            : value.interactionsCount > resp.targetInteractionsCount
            ? value.interactionsCount > resp.bestInteractionsCount
              ? 0
              : resp.bestInteractionsCount - value.interactionsCount
            : resp.bestInteractionsCount - resp.targetInteractionsCount
        ),
        backgroundColor: resp.interactionsData.map(
          (value: InteractionsDataResponse) =>
            `${simpleInteractionTypeColors[resp.simpleInteractionType]}20`
        ),
        stack: `${resp.simpleInteractionType}`,
        order: 1
      }
    ],
    []
  );

import { Grid } from '@mui/material';
import React from 'react';
import RejectionsDistribution from '../ProjectDashboardComponent/RejectionsDistribution/RejectionsDistribution';
import UserDistribution from '../ProjectDashboardComponent/UserDistribution/UserDistribution';

interface ProjectStatsComponentProps {
  projectId: number;
}

const ProjectStatsComponent = ({ projectId }: ProjectStatsComponentProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <UserDistribution projectId={projectId} />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <RejectionsDistribution projectId={projectId} />
      </Grid>
    </Grid>
  );
};

export default ProjectStatsComponent;

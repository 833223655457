import { Box, CircularProgress, Typography } from '@mui/material';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../../services/cadabraService';

import CandidateCard from './CandidateCard/CandidateCard';
import { CandidateProjectResponse } from '../../../services/api';
import React from 'react';
import { useProjectCandidatesInProcess } from '../../../helpers/hooks';

const interactionTypeNames = [
  InteractionTypeKey.SUBMITTED,
  InteractionTypeKey.SCHEDULED_INTERVIEW,
  InteractionTypeKey.OFFERED,
  InteractionTypeKey.PLACED
];

interface ProjectDashboardComponentProps {
  projectId: number;
}
const ProjectDashboardComponent = ({ projectId }: ProjectDashboardComponentProps) => {
  const { candidatesInProcess, loading } = useProjectCandidatesInProcess(projectId);

  return loading ? (
    <CircularProgress />
  ) : (
    <Box sx={{ display: 'inline-flex', width: '100%' }}>
      {interactionTypeNames.map((type, index: number) => (
        <Box key={type} sx={{ padding: 1, width: '25%' }}>
          <Box>
            <Typography>{getInteractionTypesByKey(type).label}</Typography>
          </Box>
          <Box sx={{ backgroundColor: '#ECECEC', padding: 2 }}>
            {candidatesInProcess
              .filter(
                (candidate: CandidateProjectResponse) => candidate.latestInteractionType === type
              )
              .map((candidate: CandidateProjectResponse) => (
                <CandidateCard
                  key={candidate.id}
                  candidateId={candidate.id}
                  candidateName={candidate.name}
                  candidateImagePath={candidate.imagePath}
                  userName={candidate.latestUserInteractions[0]?.userName}
                  createdAt={candidate.latestInteractionCreatedAt}
                  weight={index}
                  users={candidate.latestUserInteractions}
                />
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProjectDashboardComponent;

import {
  Assignment,
  AssignmentInd,
  BarChart,
  Business,
  CorporateFare,
  Engineering,
  FeaturedPlayList,
  FolderShared,
  Handyman,
  People,
  StackedBarChart
} from '@mui/icons-material';

import CandidateImportsComponent from '../../../components/CandidateImportsComponent/CandidateImportsComponent';
import CandidatesComponent from '../../../components/CandidatesComponent/CandidatesComponent';
import ClientsComponent from '../../../components/ClientsComponent/ClientsComponent';
import CompaniesComponent from '../../../components/CompaniesComponent/CompaniesComponent';
import InProcessReportComponent from '../../../components/InProcessReportComponent/InProcessReportComponent';
import PositionsComponent from '../../../components/PositionsComponent/PositionsComponent';
import ProjectsComponent from '../../../components/ProjectsComponent/ProjectsComponent';
import React from 'react';
import SearchReportComponent from '../../../components/SearchReportComponent/SearchReportComponent';
import TasksComponent from '../../../components/TasksComponent/TasksComponent';
import TechnologiesComponent from '../../../components/TechnologiesComponent/TechnologiesComponent';
import UsersComponent from '../../../components/UsersComponent/UsersComponent';

export interface MenuItem {
  label?: string;
  component: any;
  route: string;
  icon: any;
}

export const mainMenuItems: MenuItem[] = [
  {
    label: 'Candidates',
    component: CandidatesComponent,
    route: '/candidates',
    icon: <FolderShared className="icon-style" />
  },
  {
    label: 'Projects',
    component: ProjectsComponent,
    route: '/projects',
    icon: <AssignmentInd className="icon-style" />
  },
  {
    label: 'Tasks',
    component: TasksComponent,
    route: '/tasks',
    icon: <Assignment className="icon-style" />
  }
];

export const reportMenuItems: MenuItem[] = [
  {
    label: 'Search',
    component: SearchReportComponent,
    route: '/search',
    icon: <BarChart className="icon-style" />
  },
  {
    label: 'In Process',
    component: InProcessReportComponent,
    route: '/in-process',
    icon: <StackedBarChart className="icon-style" />
  }
];

export const administrationMenuItems: MenuItem[] = [
  {
    label: 'Users',
    component: UsersComponent,
    route: '/users',
    icon: <People className="icon-style" />
  },
  {
    label: 'Clients',
    component: ClientsComponent,
    route: '/clients',
    icon: <Business className="icon-style" />
  },
  {
    label: 'Companies',
    component: CompaniesComponent,
    route: '/companies',
    icon: <CorporateFare className="icon-style" />
  },
  {
    label: 'Positions',
    component: PositionsComponent,
    route: '/positions',
    icon: <Engineering className="icon-style" />
  },
  {
    label: 'Technologies',
    component: TechnologiesComponent,
    route: '/technologies',
    icon: <Handyman className="icon-style" />
  }
];

export const deprecatedMenuItems: MenuItem[] = [
  {
    label: 'Candidate Imports',
    component: CandidateImportsComponent,
    route: '/candidate-imports',
    icon: <FeaturedPlayList className="icon-style" />
  }
];

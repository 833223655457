import { Box, Typography } from '@mui/material';

import React from 'react';

interface COSTabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: any;
  className?: any;
}

const COSTabPanel = (props: COSTabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box px={0} pt={3} pb={0}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default COSTabPanel;

import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';
import { ProjectSimpleResponse, TechnologyResponse } from '../../services/api';

import { cadabraService } from '../../services/services';

export interface IProjects<T> extends IAsyncType<T> {
  projects: T;
  setProjects: Dispatch<SetStateAction<T>>;
}

const useProjects = (
  search?: string,
  technologies?: TechnologyResponse[],
  optionalTechnologies?: TechnologyResponse[],
  showInactive: boolean = false,
  limit = 100,
  skip = 0
): IProjects<ProjectSimpleResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    ProjectSimpleResponse[]
  >(
    cadabraService.getProjects,
    [],
    [search, technologies, optionalTechnologies, showInactive, limit, skip]
  );
  return {
    projects: data,
    setProjects: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProjects;

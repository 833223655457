import 'firebase/auth';
import './App.css';

import { Container, LinearProgress, ThemeProvider } from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { COSSnackBar } from './helpers/ui';
import CustomTheme from './Theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import MainContainer from './containers/MainContainer/MainContainer';
import React from 'react';
import { auth } from './firebase';
import { snackbarService } from './services/services';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObservable } from './helpers/hooks';

const App = () => {
  const [user, loading] = useAuthState(auth);
  const { snackbarOpen, message, severity } = useObservable(snackbarService.snackbar);
  return (
    <ThemeProvider theme={CustomTheme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="app">
          {loading ? (
            <Container maxWidth="sm">
              <LinearProgress />
            </Container>
          ) : user != null ? (
            <MainContainer />
          ) : (
            <LoginContainer />
          )}
          <COSSnackBar
            open={snackbarOpen}
            message={message}
            severity={severity}
            onClose={() => snackbarService.setSnackbarOpen(false, message, severity)}
          />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

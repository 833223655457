import {
  CandidateInteractionsByUserResponse,
  CandidateInteractionsResponse,
  InteractionsDataResponse
} from '../../services/api';
import { TimePeriod, simpleInteractionTypeColors, useAsync } from '.';
import { useCallback, useMemo } from 'react';

import { cadabraService } from '../../services/services';
import { capitalize } from '../domain/utils';

interface IChartDatasetItem {
  label: string;
  data: number[];
  backgroundColor: string[];
  stack: string;
}

interface IChartData {
  labels: string[];
  datasets: IChartDatasetItem[];
  legend?: any;
  tooltip?: any;
  annotations?: any[];
}

export interface IDashboardInfo {
  personalChartData: IChartData;
  companyChartData: IChartData;
  userProcessChartData: IChartData;
  responseRate: number;
  submissionRate: number;
  setParameters: any;
}

const useDashboardInfo = (
  timePeriod: TimePeriod,
  startDate: string,
  endDate: string,
  userId?: number
): IDashboardInfo => {
  const personalSearchStats = useAsync<CandidateInteractionsByUserResponse[]>(
    cadabraService.getCandidateSearchInteractionsByUsers,
    [],
    [timePeriod, userId ? [userId] : [], startDate, endDate]
  );
  const companyStats = useAsync<CandidateInteractionsResponse[]>(
    cadabraService.getCandidateInProcessInteractions,
    [],
    [timePeriod, startDate, endDate]
  );
  const userProcessStats = useAsync<CandidateInteractionsByUserResponse[]>(
    cadabraService.getCandidateInProcessInteractionsByUsers,
    [],
    [timePeriod, [], startDate, endDate]
  );

  const setParameters = (
    timePeriod: TimePeriod,
    userId: number,
    startDate: string,
    endDate: string
  ) => {
    personalSearchStats.setParameters([timePeriod, [userId], startDate, endDate]);
    companyStats.setParameters([timePeriod, startDate, endDate]);
    userProcessStats.setParameters([timePeriod, [], startDate, endDate]);
  };

  const calculateInteractionsByType = useCallback(
    (data: any[], simpleInteractionType: string) =>
      data
        .filter(
          (resp: CandidateInteractionsByUserResponse) =>
            resp.simpleInteractionType === simpleInteractionType
        )
        .reduce(
          (res: number, resp: CandidateInteractionsByUserResponse) =>
            res +
            resp.interactionsData.reduce(
              (userRes: number, value: InteractionsDataResponse) =>
                userRes + value.interactionsCount,
              0
            ),
          0
        ),
    []
  );

  const contacted = useMemo(
    () => calculateInteractionsByType(personalSearchStats.data, 'CONTACTED'),
    [personalSearchStats]
  );
  const replied = useMemo(
    () => calculateInteractionsByType(personalSearchStats.data, 'REPLIED'),
    [personalSearchStats]
  );
  const submitted = useMemo(
    () => calculateInteractionsByType(personalSearchStats.data, 'SUBMITTED'),
    [personalSearchStats]
  );

  return {
    personalChartData: {
      labels: (personalSearchStats.data ?? []).reduce(
        (res: string[], resp: CandidateInteractionsByUserResponse) => {
          if (!res.includes(resp.userName)) {
            res.push(resp.userName);
          }
          return res;
        },
        []
      ),
      datasets: parseUserData(personalSearchStats.data),
      legend: {
        labels: {
          filter: (item: any) => !item.fillStyle.endsWith('20')
        }
      },
      tooltip: {
        filter: (item: any) => item.dataset.label !== 'BEST'
      }
    },
    companyChartData: {
      labels: ['Process'],
      datasets: parseData(companyStats.data),
      legend: {
        labels: {
          filter: (item: any) => !item.fillStyle.endsWith('20')
        }
      },
      tooltip: {
        filter: (item: any) => item.dataset.label !== 'BEST'
      }
    },
    userProcessChartData: {
      labels: (userProcessStats.data ?? []).reduce(
        (res: string[], resp: CandidateInteractionsByUserResponse) => {
          if (!res.includes(resp.userName)) {
            res.push(resp.userName);
          }
          return res;
        },
        []
      ),
      datasets: parseUserData(userProcessStats.data),
      legend: {
        labels: {
          filter: (item: any) => !item.fillStyle.endsWith('20')
        }
      },
      tooltip: {
        filter: (item: any) => item.dataset.label !== 'BEST'
      }
    },
    responseRate: contacted > 0 ? replied / contacted : 0,
    submissionRate: contacted > 0 ? submitted / contacted : 0,
    setParameters
  };
};

export default useDashboardInfo;

const parseData = (data: CandidateInteractionsResponse[]) =>
  data.reduce(
    (res: IChartDatasetItem[], resp: CandidateInteractionsResponse) => [
      ...res,
      {
        label: capitalize(resp.simpleInteractionType),
        data: resp.interactionsData.map(
          (value: InteractionsDataResponse) =>
            value.interactionsCount - resp.targetInteractionsCount
        ),
        backgroundColor: resp.interactionsData.map((value: InteractionsDataResponse) =>
          value.interactionsCount - resp.targetInteractionsCount > 0
            ? `${simpleInteractionTypeColors[resp.simpleInteractionType]}BB`
            : `${simpleInteractionTypeColors[resp.simpleInteractionType]}80`
        ),
        stack: resp.simpleInteractionType
      },
      {
        label: 'BEST',
        data: resp.interactionsData.map((value: InteractionsDataResponse) =>
          resp.targetInteractionsCount > resp.bestInteractionsCount
            ? 0
            : value.interactionsCount > resp.targetInteractionsCount
            ? value.interactionsCount > resp.bestInteractionsCount
              ? 0
              : resp.bestInteractionsCount - value.interactionsCount
            : resp.bestInteractionsCount - resp.targetInteractionsCount
        ),
        backgroundColor: resp.interactionsData.map(
          (value: InteractionsDataResponse) =>
            `${simpleInteractionTypeColors[resp.simpleInteractionType]}20`
        ),
        stack: `${resp.simpleInteractionType}`,
        order: 1
      }
    ],
    []
  );

const parseUserData = (data: CandidateInteractionsByUserResponse[]) =>
  Object.values(
    (data ?? []).reduce((res: any, resp: CandidateInteractionsByUserResponse) => {
      if (!(resp.simpleInteractionType in res)) {
        const filteredData = data?.filter(
          (c: CandidateInteractionsByUserResponse) =>
            c.simpleInteractionType === resp.simpleInteractionType
        );
        const label = capitalize(resp.simpleInteractionType);
        res[resp.simpleInteractionType] = [
          {
            label,
            data: filteredData.reduce(
              (res: number[], c: CandidateInteractionsByUserResponse) => [
                ...res,
                ...c.interactionsData.map(
                  (d: InteractionsDataResponse) =>
                    d.interactionsCount - resp.targetInteractionsCount
                )
              ],
              []
            ),
            backgroundColor: filteredData.reduce(
              (res: string[], c: CandidateInteractionsByUserResponse) => [
                ...res,
                ...c.interactionsData.map((d: InteractionsDataResponse) =>
                  d.interactionsCount - resp.targetInteractionsCount > 0
                    ? `${simpleInteractionTypeColors[resp.simpleInteractionType]}BB`
                    : `${simpleInteractionTypeColors[resp.simpleInteractionType]}80`
                )
              ],
              []
            ),
            stack: `${resp.simpleInteractionType}`,
            order: 1
          },
          {
            label: 'BEST',
            data: filteredData.reduce(
              (res: number[], c: CandidateInteractionsByUserResponse) => [
                ...res,
                ...c.interactionsData.map((d: InteractionsDataResponse) =>
                  c.targetInteractionsCount > c.bestInteractionsCount
                    ? 0
                    : d.interactionsCount > c.targetInteractionsCount
                    ? d.interactionsCount > c.bestInteractionsCount
                      ? 0
                      : c.bestInteractionsCount - d.interactionsCount
                    : c.bestInteractionsCount - c.targetInteractionsCount
                )
              ],
              []
            ),
            backgroundColor: filteredData.reduce(
              (res: string[], c: CandidateInteractionsByUserResponse) => [
                ...res,
                ...c.interactionsData.map(
                  (_d: InteractionsDataResponse) =>
                    `${simpleInteractionTypeColors[resp.simpleInteractionType]}20`
                )
              ],
              []
            ),
            stack: `${resp.simpleInteractionType}`,
            order: 1
          }
        ];
      }
      return res;
    }, {})
  ).reduce((res: any[], value: any) => [...res, ...value], []);

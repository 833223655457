import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { cadabraService } from '../../services/services';

export interface ICandidate<T> {
  data: T;
  setData: Dispatch<SetStateAction<T>>;
  setImagePath: Dispatch<SetStateAction<string | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const useCandidateImage = (defaultImagePath?: string): ICandidate<string | null> => {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imagePath, setImagePath] = useState<string | undefined>(defaultImagePath);
  const fetchData = useCallback(async () => {
    if (!imagePath) {
      return;
    }
    setLoading(true);
    cadabraService
      .getCandidateImage(imagePath)
      .then((resp) => {
        let dataString = null;
        if (resp) {
          dataString = `data:image/jpeg;base64, ${btoa(
            new Uint8Array(resp.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
          )}`;
          setData(dataString);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [imagePath]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    setData,
    setImagePath,
    loading,
    setLoading
  };
};

export default useCandidateImage;

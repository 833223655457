import { Box, Tooltip, Typography } from '@mui/material';
import {
  COSButton,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSRowActions,
  COSTable,
  COSUserAvatar
} from '../../helpers/ui';
import {
  CandidateProjectEventLogResponse,
  CreateCandidateProjectEventLogRequest,
  CreateTaskRequest
} from '../../services/api';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../services/cadabraService';
import React, { UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cadabraService, snackbarService } from '../../services/services';
import moment, { Moment } from 'moment';
import { useCandidateEventLogs, useFlag, useMe } from '../../helpers/hooks';

import COSConfirmDialog from '../../helpers/ui/COSConfirmDialog/COSConfirmDialog';
import CandidateEventLogDialog from './CandidateEventLogDialog';
import CandidateTaskDialog from './CandidateInfoPanel/CandidateTaskDialog';
import { Delete } from '@mui/icons-material';

const fetchSize = 25;

const badExitTypes = [
  InteractionTypeKey.DECLINED_BY_RECRUITER,
  InteractionTypeKey.REJECTED_BY_CANDIDATE,
  InteractionTypeKey.REJECTED_BY_CLIENT,
  InteractionTypeKey.NOT_INTERESTED,
  InteractionTypeKey.OFFER_DECLINED,
  InteractionTypeKey.OFFER_WITHDRAWN
];

interface CandidateEventLogsComponentProps {
  candidateId: number;
}

const MAXIMUM_ALLOWED_FOR_DELETION_LOG_AGE = 14;

const CandidateEventLogsComponent = ({ candidateId }: CandidateEventLogsComponentProps) => {
  const [eventLogsData, setEventLogsData] = useState<CandidateProjectEventLogResponse[]>([]);
  const { eventLogs, setEventLogs, loading, setParameters } = useCandidateEventLogs(candidateId);
  const { user } = useMe();
  const [openDialog, setOpenDialog] = useFlag(false);
  const [openTaskDialog, setOpenTaskDialog] = useFlag(false);
  const [defaultTaskComment, setDefaultTaskComment] = useState<string>('');
  const [defaultTaskDate, setDefaultTaskDate] = useState<Moment>(moment());
  const [page, setPage] = useState<number>(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [selectedEventLog, setSelectedEventLog] = useState<CandidateProjectEventLogResponse>();
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);

  const maximumAllowedForDeletionMoment = useMemo(
    () => moment().subtract(MAXIMUM_ALLOWED_FOR_DELETION_LOG_AGE, 'days').endOf('day'),
    []
  );

  const latestEventLogs = useMemo(() => {
    return eventLogs.reduce((res: any, eventLog: CandidateProjectEventLogResponse) => {
      if (!res[eventLog.projectId]) {
        res[eventLog.projectId] = eventLog;
      }
      return res;
    }, {});
  }, [eventLogs]);

  const latestEventLogsIds = useMemo(() => {
    return Object.values(latestEventLogs)
      .filter((eventLog: any) => user && user.id === eventLog.userId)
      .filter(
        (eventLog: any) => moment(eventLog.createdAt).diff(maximumAllowedForDeletionMoment) > 0
      )
      .map((eventLog: any) => eventLog.id);
  }, [user, latestEventLogs, maximumAllowedForDeletionMoment]);

  useEffect(() => {
    setEventLogsData(
      eventLogs.map((log: CandidateProjectEventLogResponse) => ({
        ...log,
        projectName: `${log.positionName} @ ${log.companyName}`,
        comment: log.comment?.replace('<span style="color: #ff6c00;">', '').replace('</span>', ''),
        users: []
      }))
    );
  }, [eventLogs]);

  const visibleEventLogs = useMemo(
    () => eventLogsData.slice(0, (page + 1) * fetchSize),
    [eventLogsData, page]
  );

  const handleDelete = useCallback(async (eventLog: CandidateProjectEventLogResponse) => {
    await cadabraService.deleteCandidateProjectEventLog(eventLog.id);
    setParameters([[candidateId], [], []]);
    snackbarService.setSnackbarOpen(true, 'Activity successfully deleted', 'success');
  }, []);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 600 &&
          visibleEventLogs.length < eventLogsData.length
        ) {
          setPage(page + 1);
        }
      }
    },

    [eventLogsData, page, visibleEventLogs]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  return (
    <Box>
      <COSButton text="ADD" onClick={() => setOpenDialog(true)} />
      <COSTable<CandidateProjectEventLogResponse>
        isLoading={loading}
        data={visibleEventLogs}
        enablePagination={false}
        enableRowNumbers={true}
        enableStickyHeader={true}
        muiTableContainerProps={{
          ref: tableContainerRef,
          sx: { maxHeight: '600px' },
          onScroll: (event: UIEvent<HTMLDivElement>) =>
            fetchMoreOnBottomReached(event.target as HTMLDivElement)
        }}
        columns={[
          {
            accessorKey: 'userName',
            header: 'User',
            size: 70,
            Cell: ({ row }: COSCellProps<CandidateProjectEventLogResponse>) => {
              return (
                <Box sx={{ display: 'flex', marginY: 0.5 }}>
                  <COSLink href={`/user?id=${row.original.userId}`}>
                    <COSUserAvatar
                      id={row.original.userId}
                      name={row.original.userName}
                      tooltipPlacement="left"
                      withImage={true}
                    />
                  </COSLink>
                </Box>
              );
            }
          },
          {
            accessorKey: 'createdAt',
            header: 'Date',
            size: 150,
            Cell: ({ row }: COSCellProps<CandidateProjectEventLogResponse>) => {
              const value = moment(row.original.createdAt ?? '2020-01-01T00:00:00').format(
                'DD/MM/YY, HH:mm:ss'
              );
              return <Typography>{value}</Typography>;
            },
            sortingFn: (row1, row2) =>
              moment(row1.original.createdAt).isBefore(moment(row2.original.createdAt)) ? -1 : 1
          },
          {
            accessorKey: 'type',
            header: 'Type',
            size: 100,
            Cell: ({ row }: COSCellProps<CandidateProjectEventLogResponse>) => {
              const value = row.original.type
                ? getInteractionTypesByKey(row.original.type as InteractionTypeKey).label
                : '-';
              return <Typography>{value}</Typography>;
            }
          },
          {
            accessorKey: 'positionName',
            header: 'Project',
            size: 250,
            Cell: ({ row }: COSCellProps<CandidateProjectEventLogResponse>) => (
              <COSLink href={`/project?id=${row.original.projectId}`}>
                {row.original.companyName === 'Legacy Company'
                  ? 'General'
                  : `${row.original.positionName} @ ${row.original.companyName}`}
              </COSLink>
            )
          },
          {
            accessorKey: 'comment',
            header: 'Comment',
            size: 390
          },
          {
            accessorKey: 'id',
            header: '',
            size: 30,
            Cell: ({ row }: COSRowActions<CandidateProjectEventLogResponse>) =>
              latestEventLogsIds.includes(row.original.id) && (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <COSIconButton
                    onClick={() => {
                      setSelectedEventLog(row.original);
                      setOpenConfirmDeleteDialog(true);
                    }}
                  >
                    <Tooltip arrow placement="top" title="Delete activity">
                      <Delete sx={{ color: '#ab0740' }}/>
                    </Tooltip>
                  </COSIconButton>
                </Box>
              )
          }
        ]}
      />
      <CandidateEventLogDialog
        candidateId={candidateId}
        eventLogs={eventLogsData}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={async (eventLog: CreateCandidateProjectEventLogRequest) => {
          const resp = await cadabraService.createCandidateProjectEventLog(eventLog);
          if (resp) {
            const log = resp.data;
            setEventLogs([log, ...eventLogsData]);
            setOpenDialog(false);
            if (badExitTypes.includes(log.type as InteractionTypeKey)) {
              setDefaultTaskComment(
                `Marked '${getInteractionTypesByKey(log.type as InteractionTypeKey).label}' for '${
                  log.positionName
                } @ ${log.companyName}'`
              );
              setDefaultTaskDate(moment(log.createdAt).add(14, 'days'));
              setOpenTaskDialog(true);
            }
          }
        }}
      />
      {openTaskDialog && (
        <CandidateTaskDialog
          candidateId={candidateId}
          open={openTaskDialog}
          onClose={() => setOpenTaskDialog(false)}
          onSave={async (taskRequest: CreateTaskRequest) => {
            const resp = await cadabraService.createTask(taskRequest);
            if (resp) {
              setOpenTaskDialog(false);
              snackbarService.setSnackbarOpen(true, 'Task saved!', 'success');
            }
          }}
          defaultDate={defaultTaskDate}
          defaultComment={defaultTaskComment}
        />
      )}
      <COSConfirmDialog
        open={openConfirmDeleteDialog && !!selectedEventLog}
        onConfirm={() => {
          handleDelete(selectedEventLog!);
          setOpenConfirmDeleteDialog(false);
          setSelectedEventLog(undefined);
        }}
        onClose={() => {
          setOpenConfirmDeleteDialog(false);
          setSelectedEventLog(undefined);
        }}
        title={'Confirm delete'}
        message={'Are you sure you want to delete this activity?'}
      />
    </Box>
  );
};

export default CandidateEventLogsComponent;

import { Grid, Typography } from '@mui/material';
import { InteractionTypeId, InteractionTypeKey } from '../../../services/cadabraService';
import React, { useMemo } from 'react';

import { CandidateProjectEventLogResponse } from '../../../services/api';
import UserActivityCalendarComponent from './UserActivityCalendar';
import moment from 'moment';
import { useUserEventLogs } from '../../../helpers/hooks';

interface UserContactContactActivityCalendarComponentProps {
  id: number;
}

const UserContactActivityCalendarComponent = ({
  id
}: UserContactContactActivityCalendarComponentProps) => {
  const { eventLogs, loading } = useUserEventLogs(
    id,
    [
      InteractionTypeId.CONTACTED_LINKEDIN_MESSAGE,
      InteractionTypeId.CONTACTED_IN_MAIL,
      InteractionTypeId.CONTACTED_VIA_INVITATION,
      InteractionTypeId.CONTACTED_PHONE_CALL,
      InteractionTypeId.CONTACTED_EMAIL,
      InteractionTypeId.CONTACTED_MEETING,
      InteractionTypeId.PUSH_LINKEDIN_MESSAGE,
      InteractionTypeId.PUSH_IN_MAIL,
      InteractionTypeId.PUSH_VIA_INVITATION,
      InteractionTypeId.PUSH_PHONE_CALL,
      InteractionTypeId.PUSH_EMAIL,
      InteractionTypeId.PUSH_MEETING,
      InteractionTypeId.FOLLOW_UP
    ],
    undefined,
    undefined,
    2500,
    0
  );

  const todayContacts = useMemo(() => {
    const today = moment().startOf('day');
    return eventLogs
      .filter(
        (eventLog: CandidateProjectEventLogResponse) =>
          eventLog.type !== InteractionTypeKey.FOLLOW_UP
      )
      .filter((eventLog: CandidateProjectEventLogResponse) =>
        moment(eventLog.createdAt).startOf('day').isSame(today)
      );
  }, [eventLogs]);

  const todayProjects = useMemo(() => {
    return todayContacts.reduce((res: any, log: CandidateProjectEventLogResponse) => {
      const projectName = `${log.positionName} @ ${log.companyName}`;
      if (!res[projectName]) {
        res[projectName] = 0;
      }
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      res[projectName] = res[projectName] + 1;
      return res;
    }, {});
  }, [todayContacts]);

  return (
    <Grid container>
      <Grid item lg={12}>
        <Typography variant="body1">Projects</Typography>
      </Grid>
      <Grid item lg={12}>
        <UserActivityCalendarComponent
          eventLogs={eventLogs}
          loading={loading}
          hideColorLegend={false}
        />
      </Grid>
      <Grid item lg={12} sx={{ marginTop: 2 }}>
        <Typography variant="body1">Today</Typography>
      </Grid>
      <Grid item lg={12}>
        {Object.keys(todayProjects).map((projectName: string) => (
          <Typography key={projectName} variant="body1">
            {projectName}: {todayProjects[projectName]}
          </Typography>
        ))}
      </Grid>
    </Grid>
  );
};

export default UserContactActivityCalendarComponent;

import {
  AutocompleteRenderOptionState,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem
} from '@mui/material';
import { COSAsyncAutocomplete, COSAutocomplete, COSTextField, Lookup } from '../../helpers/ui';
import {
  CandidateProjectEventLogResponse,
  CreateCandidateProjectEventLogRequest,
  ProjectSimpleResponse
} from '../../services/api';
import { InteractionTypeKey, getInteractionTypesByKey } from '../../services/cadabraService';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebounce } from '@react-hook/debounce';
import { useProjects } from '../../helpers/hooks';

interface CandidateEventLogDialogProps {
  candidateId: number;
  eventLogs: CandidateProjectEventLogResponse[];
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateCandidateProjectEventLogRequest) => void;
}

const CandidateEventLogDialog = ({
  candidateId,
  eventLogs,
  open,
  onClose,
  onSave
}: CandidateEventLogDialogProps) => {
  const [projectValue, setProjectValue] = useState<Lookup | null>(null);
  const { projects, loading, setParameters } = useProjects();
  const [projectsFilter, setProjectsFilter] = useDebounce<string>('', 250);
  const [comment, setComment] = useState<string>('');
  const [type, setType] = useState<Lookup | null>(null);

  const latestInteractions = useMemo(() => {
    return eventLogs.reduce((res: any, eventLog: CandidateProjectEventLogResponse) => {
      if (!res[eventLog.projectId]) {
        res[eventLog.projectId] = eventLog;
      }
      return res;
    }, {});
  }, [eventLogs]);

  const projectIds = useMemo(() => {
    return Object.keys(latestInteractions).map((key: string) => +key);
  }, [latestInteractions]);

  const types = useMemo(() => {
    if (!projectValue) {
      return [];
    }
    const options = latestInteractions[projectValue.id]?.allowedSubsequentInteractions.map(
      (status: string) => ({
        id: status,
        value: status
      })
    ) ?? [{ id: 'PRELIMINARY', value: 'PRELIMINARY' }];

    if (options.length > 0) {
      setType(options[0]);
    } else {
      setType({ id: 'PRELIMINARY', value: 'PRELIMINARY' });
    }
    return options;
  }, [projectValue, latestInteractions]);

  useEffect(() => {
    setParameters([projectsFilter]);
  }, [projectsFilter]);

  const handleOnClose = useCallback(() => {
    setProjectValue(null);
    setComment('');
    setType(null);
    onClose();
  }, [onClose]);

  const handleOnSave = useCallback(() => {
    onSave({
      projectId: projectValue?.id,
      comment,
      type: type?.id ?? 'PRELIMINARY',
      candidateId
    });
    handleOnClose();
  }, [projectValue, comment, type, candidateId, onSave, handleOnClose]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>{'Add interaction'}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <COSAsyncAutocomplete<Lookup>
              label="Project"
              value={projectValue}
              options={projects
                .sort((a: ProjectSimpleResponse, b: ProjectSimpleResponse) =>
                  projectIds.indexOf(b.id) > projectIds.indexOf(a.id) ? 1 : -1
                )
                .map((project: ProjectSimpleResponse) => ({
                  id: project.id,
                  value: `${project.position.name} @ ${project.client.companyName}`
                }))}
              loading={loading}
              onChange={(value: Lookup | null) => setProjectValue(value)}
              minChar={0}
              filter={projectsFilter}
              onChangeFilter={(projectsFilter: string) => setProjectsFilter(projectsFilter)}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <COSAutocomplete<Lookup>
              label="Type"
              value={type}
              options={types}
              loading={loading}
              onChange={(value: Lookup | null) => setType(value)}
              getOptionLabel={(option: Lookup) =>
                `${getInteractionTypesByKey(option.value as InteractionTypeKey).label}`
              }
              renderOption={(
                props: React.HTMLAttributes<HTMLLIElement>,
                option: Lookup,
                state: AutocompleteRenderOptionState
              ) => {
                return (
                  <ListItem {...props}>
                    {getInteractionTypesByKey(option.value as InteractionTypeKey).label}
                  </ListItem>
                );
              }}
              minChar={0}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <COSTextField
              label="Comment"
              multiline
              rows={6}
              value={comment}
              onChange={(value) => setComment(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleOnSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateEventLogDialog;

import { Box, Tooltip, Typography } from '@mui/material';
import { COSCellProps, COSLink, COSTable } from '../../../helpers/ui';
import { ProjectSimpleResponse, TechnologyResponse } from '../../../services/api';
import React, { useEffect, useState } from 'react';

import { cadabraService } from '../../../services/services';
import { useProjects } from '../../../helpers/hooks';

interface CandidateProjectSuggestionsComponentProps {
  technologies: TechnologyResponse[];
}

interface ProjectWithMatchPercentage extends ProjectSimpleResponse {
  matchPercentage: number;
}

const CandidateProjectSuggestionsComponent = ({
  technologies
}: CandidateProjectSuggestionsComponentProps) => {
  const { projects, loading } = useProjects(undefined, undefined, technologies, false);
  const [projectData, setProjectData] = useState<ProjectWithMatchPercentage[]>([]);

  useEffect(() => {
    setProjectData(
      projects
        .map((project: ProjectSimpleResponse) => ({
          ...project,
          matchPercentage: +cadabraService.calculateMatchPercentage(project, technologies)
        }))
        .filter((project: ProjectWithMatchPercentage) => project.matchPercentage > 0)
        .sort(
          (p1: ProjectWithMatchPercentage, p2: ProjectWithMatchPercentage) =>
            p2.matchPercentage - p1.matchPercentage
        )
    );
  }, [projects]);

  return (
    <COSTable<ProjectWithMatchPercentage>
      isLoading={loading}
      data={projectData}
      columns={[
        {
          accessorKey: 'position.name',
          header: 'Name',
          size: 250,
          Cell: ({ row }: COSCellProps<ProjectWithMatchPercentage>) => (
            <COSLink href={`/project?id=${row.original.id}`}>
              {`${row.original.position.name} @ ${row.original.client.companyName}`}
            </COSLink>
          )
        },
        {
          accessorKey: 'client.companyName',
          header: 'Client',
          size: 250,
          Cell: ({ row }: COSCellProps<ProjectWithMatchPercentage>) => (
            <COSLink href={`/client?id=${row.original.client.id}`}>
              {row.original.client.companyName}
            </COSLink>
          )
        },
        {
          accessorKey: 'technologies',
          header: 'Technologies',
          size: 200,
          Cell: ({ row }: COSCellProps<ProjectWithMatchPercentage>) => {
            const projectTechnologies = row.original.technologies
              .map((technology: TechnologyResponse) => technology.name)
              .join(', ');
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem'
                }}
              >
                <Tooltip title={projectTechnologies} arrow placement="right">
                  <Typography>
                    ({cadabraService.calculateMatchPercentage(row.original, technologies)}%){' '}
                    {projectTechnologies}
                  </Typography>
                </Tooltip>
              </Box>
            );
          }
        }
      ]}
    />
  );
};

export default CandidateProjectSuggestionsComponent;

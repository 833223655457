import { Dispatch, SetStateAction } from 'react';
import { IAsyncType, useAsync } from '.';

import { CandidateProjectResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IEventLogsInProcess<T> extends IAsyncType<T> {
  candidatesInProcess: T;
  setCandidatesInProcess: Dispatch<SetStateAction<T>>;
}

const useProjectCandidatesInProcess = (
  projectId: number
): IEventLogsInProcess<CandidateProjectResponse[]> => {
  const { data, setData, loading, setLoading, parameters, setParameters } = useAsync<
    CandidateProjectResponse[]
  >(cadabraService.getCandidatesByProjectInProcessAndLastInteraction, [], [projectId]);
  return {
    candidatesInProcess: data,
    setCandidatesInProcess: setData,
    loading,
    setLoading,
    parameters,
    setParameters
  };
};

export default useProjectCandidatesInProcess;

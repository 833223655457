import {
  Add,
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  AutoFixHighOutlined,
  Check,
  CheckBox,
  CheckBoxOutlineBlank,
  Clear
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  COSButton,
  COSCellProps,
  COSIconButton,
  COSLink,
  COSRowActions,
  COSTable,
  COSTextField
} from '../../helpers/ui';
import { ProjectSimpleResponse, TechnologyResponse } from '../../services/api';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import { parseQuery } from '../../helpers/url';
import { useNavigate } from 'react-router-dom';
import { useProjects } from '../../helpers/hooks';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const apiFetchSize = 25;

const ProjectsComponent = () => {
  const { selectedPage } = parseQuery(location.search);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const { projects, loading, setParameters } = useProjects(
    undefined,
    undefined,
    undefined,
    false,
    apiFetchSize,
    0
  );
  const [page, setPage] = useState<number>(selectedPage ? +selectedPage : 0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    setParameters([search, [], [], showInactive, apiFetchSize, page * apiFetchSize]);
  }, [showInactive]);

  const onEnter = useCallback(() => {
    setPage(0);
    setParameters([search, undefined, undefined, showInactive, apiFetchSize, 0]);
    navigate('/projects');
  }, [search, showInactive]);

  useEffect(() => {
    setParameters([search, undefined, undefined, showInactive, apiFetchSize, page * apiFetchSize]);
    navigate(`/projects?selectedPage=${page}`);
  }, [page]);

  useEffect(() => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollTop = 0;
    }
  }, [projects]);

  const handleAdd = useCallback(() => navigate('/project?tabId=1'), [navigate]);

  return (
    <Box sx={{ margin: 2 }}>
      <Typography align="center" variant="h5" component="h6">
        Projects
      </Typography>
      <Box sx={{ margin: 2 }}>
        <Grid container spacing={1}>
          <Grid item lg={9}>
            <COSTextField
              label="Search"
              value={search}
              onChange={(value: string) => setSearch(value)}
              onEnter={onEnter}
            />
          </Grid>
          <Grid item lg={3} sx={{ marginTop: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    checked={showInactive}
                    onChange={() => setShowInactive(!showInactive)}
                    color="primary"
                  />
                }
                label="Show Inactive"
              />
              <COSButton text="NEW" variant="outlined" onClick={handleAdd} startIcon={<Add />} />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <COSTable<ProjectSimpleResponse>
              isLoading={loading}
              data={projects}
              muiTableContainerProps={{
                ref: tableContainerRef,
                sx: { maxHeight: '60vh', minHeight: '300px' }
              }}
              columns={[
                {
                  accessorKey: 'id',
                  header: '#',
                  size: 50,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) =>
                    row.index + page * apiFetchSize + 1
                },
                {
                  accessorKey: 'position.name',
                  header: 'Name',
                  size: 200,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) => (
                    <COSLink href={`/project?id=${row.original.id}`}>
                      {`${row.original.position.name} @ ${row.original.client.companyName}`}
                    </COSLink>
                  )
                },
                {
                  accessorKey: 'client.companyName',
                  header: 'Client',
                  size: 140,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) => (
                    <COSLink href={`/client?id=${row.original.client.id}`}>
                      {row.original.client.companyName}
                    </COSLink>
                  )
                },
                {
                  accessorKey: 'position.name',
                  header: 'Position',
                  size: 140,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) => (
                    <Typography
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => {
                        setSearch(row.original.position.name);
                        setParameters([row.original.position.name, [], [], showInactive]);
                      }}
                    >
                      {row.original.position.name}
                    </Typography>
                  )
                },
                {
                  accessorKey: 'technologies',
                  header: 'Technologies',
                  size: 310,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) => {
                    const technologies = row.original.technologies
                      .map((technology: TechnologyResponse) => technology.name)
                      .join(', ');
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem'
                        }}
                      >
                        <Tooltip title={technologies} arrow placement="right">
                          <Typography>{technologies}</Typography>
                        </Tooltip>
                      </Box>
                    );
                  }
                },
                {
                  accessorKey: 'isActive',
                  header: 'Active',
                  size: 80,
                  Cell: ({ row }: COSCellProps<ProjectSimpleResponse>) =>
                    row.original.isActive ? <Check /> : <Clear />
                },
                {
                  accessorKey: 'id',
                  header: 'Actions',
                  size: 80,
                  Cell: ({ row }: COSRowActions<ProjectSimpleResponse>) =>
                    row.original.legacyId ? (
                      <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <COSIconButton
                          color="info"
                          onClick={() =>
                            window.open(
                              `https://cylinder.cadabra.bg/index.php?m=joborders&a=show&jobOrderID=${row.original.legacyId}`,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }
                        >
                          <Tooltip arrow placement="top" title="Cylinder project">
                            <AutoFixHighOutlined />
                          </Tooltip>
                        </COSIconButton>
                      </Box>
                    ) : (
                      <Box></Box>
                    )
                }
              ]}
              renderBottomToolbarCustomActions={() => (
                <Box sx={{ display: 'flex', margin: 'auto', justifyContent: 'space-around' }}>
                  <IconButton
                    onClick={() => setPage(page > 0 ? page - 1 : 0)}
                    disabled={page === 0}
                  >
                    <ArrowBackIosOutlined />
                  </IconButton>
                  <Box sx={{ marginLeft: 2, marginRight: 2, paddingTop: 0.5, color: '#717171' }}>
                    <Typography align="center" variant="h4" component="h5">
                      {page + 1}
                    </Typography>
                  </Box>
                  <IconButton
                    onClick={() => setPage(page + 1)}
                    disabled={projects.length < apiFetchSize}
                  >
                    <ArrowForwardIosOutlined />
                  </IconButton>
                </Box>
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default withErrorHandler(ProjectsComponent, Axios);

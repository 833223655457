import * as DOMPurify from 'dompurify';

import { Container, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import { AutoFixHighOutlined } from '@mui/icons-material';
import { COSIconButton } from '../../../helpers/ui';
import { useProject } from '../../../helpers/hooks';

interface ProjectLegacyComponentProps {
  projectId?: number;
}

const ProjectLegacyComponent = ({ projectId }: ProjectLegacyComponentProps) => {
  const { project } = useProject(projectId);
  const [legacyId, setLegacyId] = useState<any>('');
  const [legacyNotes, setLegacyNotes] = useState<any>('');

  const clean = useMemo(() => ({ __html: DOMPurify.sanitize(legacyNotes) }), [legacyNotes]);

  useEffect(() => {
    if (!project) {
      return;
    }
    setLegacyId(project.legacyId);
    setLegacyNotes(project.legacyNotes);
  }, [project]);

  return (
    <Grid item lg={4} md={12} sm={12} xs={12}>
      <Container maxWidth="lg">
        <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            alignContent="center"
            justifyContent="center"
            container
          >
            <COSIconButton
              color="info"
              onClick={() =>
                window.open(
                  `https://cylinder.cadabra.bg/index.php?m=joborders&a=show&jobOrderID=${legacyId}`,
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            >
              <Tooltip arrow placement="top" title="Cylinder profile">
                <AutoFixHighOutlined />
              </Tooltip>
            </COSIconButton>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div dangerouslySetInnerHTML={clean} />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default ProjectLegacyComponent;

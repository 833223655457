import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';

import { COSTextField } from '../../../helpers/ui';
import { CreateTaskRequest } from '../../../services/api';
import { DatePicker } from '@mui/x-date-pickers';

interface CandidateTaskDialogProps {
  candidateId: number;
  open: boolean;
  onClose: () => void;
  onSave: (request: CreateTaskRequest) => void;
  defaultDate?: Moment;
  defaultComment?: string;
}

const CandidateTaskDialog = ({
  candidateId,
  open,
  onClose,
  onSave,
  defaultDate,
  defaultComment = ''
}: CandidateTaskDialogProps) => {
  const [reminderDate, setReminderDate] = useState<Moment | null>(defaultDate ?? moment());
  const [comment, setComment] = useState<string>(defaultComment);

  const handleOnClose = useCallback(() => {
    setReminderDate(null);
    setComment('');
    onClose();
  }, [onClose]);

  const handleOnSave = useCallback(() => {
    onSave({
      comment,
      candidateId,
      reminderDate: reminderDate!.toISOString()
    });
    handleOnClose();
  }, [comment, candidateId, reminderDate, onSave, handleOnClose]);

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <DialogTitle>{'Add task'}</DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12}>
            <DatePicker<Moment>
              views={['year', 'month', 'day']}
              label="Reminder Date"
              inputFormat="DD/MM/YY"
              disableMaskedInput={false}
              value={reminderDate}
              onChange={setReminderDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  size="small"
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <COSTextField
              label="Comment"
              multiline
              rows={6}
              value={comment}
              onChange={(value) => setComment(value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="secondary" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleOnSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateTaskDialog;

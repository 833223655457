import MaterialReactTable, {
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_Localization,
  MRT_Row,
  MRT_TableInstance
} from 'material-react-table';
import React, { ReactNode, useMemo } from 'react';
import { TableContainerProps, ThemeProvider, createTheme, useTheme } from '@mui/material';

export interface COSCellProps<T extends Record<string, any>> {
  cell: MRT_Cell<T>;
  column: MRT_Column<T>;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}

export interface COSRowActions<T extends Record<string, any>> {
  cell: MRT_Cell<T>;
  row: MRT_Row<T>;
  table: MRT_TableInstance<T>;
}

interface COSTableProps<T extends Record<string, any>> {
  columns: Array<MRT_ColumnDef<T>>;
  data?: T[];
  localization?: MRT_Localization | undefined;
  enableRowActions?: boolean | undefined;
  enableRowNumbers?: boolean | undefined;
  enableStickyHeader?: boolean | undefined;
  enableToolbarInternalActions?: boolean | undefined;
  enableColumnActions?: boolean | undefined;
  enablePagination?: boolean | undefined;
  rowSelection?: any;
  setRowSelection?: (value?: any) => void;
  positionActionsColumn?: 'last' | 'first' | undefined;
  isLoading?: boolean;
  renderRowActions?: ({ cell, row, table }: COSRowActions<T>) => React.ReactNode;
  renderDetailPanel?: ({
    row,
    table
  }: {
    row: MRT_Row<T>;
    table: MRT_TableInstance<T>;
  }) => ReactNode;
  muiTableContainerProps?: TableContainerProps | (({ table }: any) => TableContainerProps);
  renderBottomToolbarCustomActions?: ({
    table
  }: {
    table: MRT_TableInstance<T>;
  }) => React.ReactNode;
}

const COSTable = <T extends Record<string, any>>({
  columns,
  data = [],
  enableRowActions = false,
  enableRowNumbers = false,
  enableStickyHeader = false,
  enableToolbarInternalActions = false,
  enableColumnActions = false,
  enablePagination = false,
  rowSelection = {},
  setRowSelection,
  positionActionsColumn = 'last',
  isLoading = false,
  renderDetailPanel,
  muiTableContainerProps,
  renderBottomToolbarCustomActions
}: COSTableProps<T>) => {
  const globalTheme = useTheme();

  const tableTheme = useMemo(
    () =>
      createTheme({
        components: {
          MuiTableHead: {
            styleOverrides: {
              root: {
                '&>*:first-child': { backgroundColor: '#F5F5F5 !important' }
              }
            }
          },
          MuiTableBody: {
            styleOverrides: {
              root: renderDetailPanel
                ? {
                    '&>*:nth-child(4n-1)': { backgroundColor: '#F5F5F5 !important' },
                    '&>*:nth-child(4n)': { backgroundColor: '#F5F5F5 !important' },
                    td: { border: '0px' }
                  }
                : {
                    '&>*:nth-child(even)': { backgroundColor: '#F5F5F5 !important' },
                    td: { border: '0px' }
                  }
            }
          }
        }
      }),
    [globalTheme, renderDetailPanel]
  );

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        muiTableProps={{
          sx: {
            tableLayout: 'fixed'
          }
        }}
        displayColumnDefOptions={{
          'mrt-row-numbers': {
            size: 10
          }
        }}
        muiTableContainerProps={muiTableContainerProps}
        columns={columns}
        data={data}
        state={{ rowSelection, isLoading }}
        enableRowActions={enableRowActions}
        enableRowNumbers={enableRowNumbers}
        enableStickyHeader={enableStickyHeader}
        enableToolbarInternalActions={enableToolbarInternalActions}
        enableColumnActions={enableColumnActions}
        enablePagination={enablePagination}
        positionActionsColumn={positionActionsColumn}
        enableRowSelection={!!setRowSelection}
        onRowSelectionChange={setRowSelection}
        renderDetailPanel={renderDetailPanel}
        muiTableBodyCellProps={{ sx: { paddingY: 0 } }}
        muiExpandButtonProps={({ table, row }) => ({
          size: 'small'
        })}
        muiExpandAllButtonProps={({ table }) => ({ size: 'small' })}
        muiTopToolbarProps={{ sx: { display: 'none' } }}
        rowNumberMode="static"
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      />
    </ThemeProvider>
  );
};
export default COSTable;

import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Box,
  TextField
} from '@mui/material';
import { Country, countriesData, countryToFlag } from '../../country-helper';
import React, { ChangeEvent, useState } from 'react';

interface Props {
  country: Country | null;
  disabled?: boolean;
  required?: boolean;
  onChange: (event: ChangeEvent<{}>, country: Country | null) => void;
  placeholder?: string;
}

const COSCountryAutocomplete = ({
  country,
  disabled = false,
  required = false,
  onChange,
  placeholder
}: Props) => {
  const [countries] = useState<Country[]>(countriesData);

  return (
    <Autocomplete<Country, false, false, false>
      options={countries}
      renderOption={(
        props: React.HTMLAttributes<HTMLLIElement>,
        option: Country,
        state: AutocompleteRenderOptionState
      ) => (
        <li {...props}>
          <Box sx={{ marginRight: 4, padding: 1 }}>
            {countryToFlag(option.alpha2)} {option.name} ({option.alpha3})
          </Box>
        </li>
      )}
      getOptionLabel={(option: any) =>
        `${countryToFlag(option.alpha2)} ${option.name} (${option.alpha3})`
      }
      fullWidth
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          margin="dense"
          variant="outlined"
          fullWidth
          required={required}
          disabled={disabled}
          placeholder={placeholder}
        />
      )}
      size="small"
      onChange={onChange}
      value={country}
    />
  );
};

export default COSCountryAutocomplete;

import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { TimePeriod, simpleInteractionTypeColors, useAsync } from '.';

import { CandidateInteractionsByUserResponse } from '../../services/api';
import { cadabraService } from '../../services/services';
import { capitalize } from '@mui/material';
import moment from 'moment';

interface IChartDatasetItem {
  label: string;
  data: number[];
  backgroundColor: string[];
  stack: string;
}

interface IChartData {
  title?: string;
  labels: string[];
  datasets: IChartDatasetItem[];
  legend?: any;
  tooltip?: any;
  annotations?: any[];
}

export interface ISearchInteractionsByUserInfo {
  chartData: IChartData[];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setParameters: any;
}

const useSearchInteractionsByUser = (): ISearchInteractionsByUserInfo => {
  const userSearchStats = useAsync<CandidateInteractionsByUserResponse[]>(
    cadabraService.getCandidateSearchInteractionsByUsers,
    [],
    [null, null, null, null]
  );

  const setParameters = (
    timePeriod: TimePeriod,
    startDate?: string | undefined,
    endDate?: string | undefined,
    userIds?: number[]
  ) => {
    userSearchStats.setParameters([timePeriod, startDate, endDate, userIds]);
  };

  const joinedPeriods = useMemo(
    () =>
      userSearchStats.data.reduce(
        (res: any, value: CandidateInteractionsByUserResponse) => [
          ...res,
          ...value.interactionsData.map((x) => ({ ...x, ...value, interactionsData: [] }))
        ],
        []
      ),
    [userSearchStats]
  );

  const createDateLabel = useCallback((data: any) => {
    const fromDate = moment(data.fromDate).format('DD/MM/YY');
    if (data.fromDate === data.toDate) {
      return fromDate;
    }
    const toDate = moment(data.toDate).format('DD/MM/YY');
    return `${fromDate} - ${toDate}`;
  }, []);

  const parseJoinedPeriods: any = useMemo(
    () =>
      (joinedPeriods ?? []).reduce((res: any, value: any) => {
        const key = createDateLabel(value);
        if (!(key in res)) {
          res[key] = [];
        }
        res[key].push(value);
        return res;
      }, {}),
    [joinedPeriods]
  );

  return {
    chartData: parseJoinedPeriods
      ? Object.keys(parseJoinedPeriods)
          .reverse()
          .map((key: string) => ({
            title: key,
            labels: parseJoinedPeriods[key].reduce((res: string[], t: any) => {
              if (!res.includes(t.userName)) {
                res.push(t.userName);
              }
              return res;
            }, []),
            datasets: parseUserData(parseJoinedPeriods[key]),
            legend: {
              labels: {
                filter: (item: any) => !item.fillStyle.endsWith('20')
              }
            },
            tooltip: {
              filter: (item: any) => item.dataset.label !== 'BEST'
            }
          }))
      : [],
    loading: userSearchStats.loading,
    setLoading: userSearchStats.setLoading,
    setParameters
  };
};

export default useSearchInteractionsByUser;

const parseUserData = (data: any) =>
  Object.values(
    (data ?? []).reduce((res: any, resp: any) => {
      if (!(resp.simpleInteractionType in res)) {
        const filteredData = data?.filter(
          (c: any) => c.simpleInteractionType === resp.simpleInteractionType
        );
        const label = capitalize(resp.simpleInteractionType);
        res[resp.simpleInteractionType] = [
          {
            label,
            data: filteredData.reduce(
              (res: number[], c: any) => [
                ...res,
                c.interactionsCount - resp.targetInteractionsCount
              ],
              []
            ),
            backgroundColor: filteredData.reduce(
              (res: string[], c: any) => [
                ...res,
                c.interactionsCount - resp.targetInteractionsCount > 0
                  ? `${simpleInteractionTypeColors[resp.simpleInteractionType]}BB`
                  : `${simpleInteractionTypeColors[resp.simpleInteractionType]}80`
              ],
              []
            ),
            stack: `${resp.simpleInteractionType}`,
            order: 1
          },
          {
            label: 'BEST',
            data: filteredData.reduce(
              (res: number[], c: any) => [
                ...res,
                c.targetInteractionsCount > c.bestInteractionsCount
                  ? 0
                  : c.interactionsCount > c.targetInteractionsCount
                  ? c.interactionsCount > c.bestInteractionsCount
                    ? 0
                    : c.bestInteractionsCount - c.interactionsCount
                  : c.bestInteractionsCount - c.targetInteractionsCount
              ],
              []
            ),
            backgroundColor: filteredData.reduce(
              (res: string[], c: any) => [
                ...res,
                `${simpleInteractionTypeColors[resp.simpleInteractionType]}20`
              ],
              []
            ),
            stack: `${resp.simpleInteractionType}`,
            order: 1
          }
        ];
      }
      return res;
    }, {})
  ).reduce((res: any[], value: any) => [...res, ...value], []);

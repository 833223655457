import { CircularProgress, TextField } from '@mui/material';

import React from 'react';

const defaultStyle = { padding: '10px 10px 10px 10px' };

const InputComponent = ({ ...other }) => <div {...other} />;

interface OutlinedDivProps {
  children: any;
  label?: string;
  style?: any;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  loading?: boolean;
}

const OutlinedDiv = ({
  children,
  label = '',
  style = {},
  className = '',
  disabled = false,
  required = false,
  loading = false
}: OutlinedDivProps) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      multiline
      fullWidth
      required={required}
      disabled={!!disabled}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        style: { ...defaultStyle, ...style },
        className
      }}
      inputProps={{
        children: loading ? <CircularProgress /> : children
      }}
    />
  );
};
export default OutlinedDiv;

export type TimePeriod = 'YEAR' | 'HALF_YEAR' | 'QUARTER' | 'MONTH' | 'WEEK' | 'DAY';

export const simpleInteractionTypeColors: any = {
  CONTACTED: '#F170AA',
  REPLIED: '#B26DC1',
  SUBMITTED: '#866CC1',
  INTERVIEW: '#3F91DF',
  OFFERED: '#69D7C8',
  PLACED: '#6CE3A6'
};

export { default as useMe } from './useMe';
export * from './useMe';

export { default as useUserContactChartData } from './useUserContactChartData';
export * from './useUserContactChartData';

export { default as useAsync } from './useAsync';
export * from './useAsync';

export { default as useFlag } from './useFlag';
export * from './useFlag';

export { default as useCandidates } from './useCandidates';
export * from './useCandidates';

export { default as useCandidateImage } from './useCandidateImage';
export * from './useCandidateImage';

export { default as useCandidateImport } from './useCandidateImport';
export * from './useCandidateImport';

export { default as useCandidateImportSuggestions } from './useCandidateImportSuggestions';
export * from './useCandidateImportSuggestions';

export { default as useCandidateHistory } from './useCandidateHistory';
export * from './useCandidateHistory';

export { default as useProjectCandidatesInProcess } from './useProjectCandidatesInProcess';
export * from './useProjectCandidatesInProcess';

export { default as useProjectCandidatesContacted } from './useProjectCandidatesContacted';
export * from './useProjectCandidatesContacted';

export { default as useProjectCandidateSuggestions } from './useProjectCandidateSuggestions';
export * from './useProjectCandidateSuggestions';

export { default as useDashboardInfo } from './useDashboardInfo';
export * from './useDashboardInfo';

export { default as useLookup } from './useLookup';
export * from './useLookup';

export { default as useObservable } from './useObservable';
export * from './useObservable';

export { default as useProjects } from './useProjects';
export * from './useProjects';

export { default as useUsers } from './useUsers';
export * from './useUsers';

export { default as useUser } from './useUser';
export * from './useUser';

export { default as useEventLogs } from './useEventLogs';
export * from './useEventLogs';

export { default as useUserEventLogs } from './useUserEventLogs';
export * from './useUserEventLogs';

export { default as useCandidateEventLogs } from './useCandidateEventLogs';
export * from './useCandidateEventLogs';

export { default as useProject } from './useProject';
export * from './useProject';

export { default as useProjectEventLogs } from './useProjectEventLogs';
export * from './useProjectEventLogs';

export { default as useWindowDimensions } from './useWindowDimensions';
export * from './useWindowDimensions';

export { default as useTasks } from './useTasks';
export * from './useTasks';

export { default as useSearchInteractions } from './useSearchInteractions';
export * from './useSearchInteractions';

export { default as useSearchInteractionsByUser } from './useSearchInteractionsByUser';
export * from './useSearchInteractionsByUser';

export { default as useInProcessInteractions } from './useInProcessInteractions';
export * from './useInProcessInteractions';

export { default as useInProcessInteractionsByUser } from './useInProcessInteractionsByUser';
export * from './useInProcessInteractionsByUser';

export { default as useQuery } from './useQuery';
export * from './useQuery';

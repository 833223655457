import { Link, SxProps, Theme } from '@mui/material';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const LEFT_MOUSE_CLICK = 0;

interface COSLinkProps {
  children?: React.ReactNode;
  href: string;
  sx?: SxProps<Theme>;
}

const COSLink = ({
  children,
  href,
  sx = {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    color: 'inherit',
    backgroundColor: 'transparent',
    textDecoration: 'none'
  }
}: COSLinkProps) => {
  const navigate = useNavigate();
  return (
    <Link
      href={href}
      sx={sx}
      onClick={(e) => {
        if (
          e.metaKey ||
          e.button !== LEFT_MOUSE_CLICK ||
          href.startsWith('mailto') ||
          href.startsWith('tel')
        ) {
          return;
        }
        e.preventDefault();
        navigate(href);
      }}
    >
      {children}
    </Link>
  );
};

export default COSLink;

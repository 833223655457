import { Container, Grid, Tab, Tabs } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Axios from 'axios';
import { COSTabPanel } from '../../helpers/ui';
import NewUserForm from './NewUserForm/NewUserForm';
import UserCandidateProjectEventLogs from './UserCandidateProjectEventLogs/UserCandidateProjectEventLogs';
import UserCandidateRelationComponent from './UserCandidateRelation/UserCandidateRelationComponent';
import UserContactActivityCalendarComponent from './UserActivityCalendar/UserContactActivityCalendarComponent';
import UserForm from './UserForm/UserForm';
import { parseQuery } from '../../helpers/url';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const UserComponent = () => {
  const location = useLocation();
  const { id, tabId } = parseQuery(location.search);
  const [tab, setTab] = useState(tabId ? +tabId : 0);
  const navigate = useNavigate();

  if (!id) {
    return (
      <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <NewUserForm />
        </Grid>
      </Grid>
    );
  }

  useEffect(() => {
    setTab(tabId ? +tabId : 0);
  }, [tabId, setTab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
    navigate(`/user?${id ? `id=${id}&` : ''}tabId=${newTab}`);
  };

  const contactActivityView = useMemo(() => <UserContactActivityCalendarComponent id={id} />, [id]);

  return (
    <Container maxWidth="xl">
      {id && (
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="User Info" />
          <Tab label="Activity" />
          <Tab label="Relations" />
        </Tabs>
      )}
      <COSTabPanel value={tab} index={0}>
        <Grid container spacing={1} sx={{ px: 3, py: 1 }}>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <UserForm id={id} />
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Grid container spacing={1} sx={{ px: 3 }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {contactActivityView}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </COSTabPanel>
      <COSTabPanel value={tab} index={1}>
        <UserCandidateProjectEventLogs id={id} />
      </COSTabPanel>
      <COSTabPanel value={tab} index={2}>
        <UserCandidateRelationComponent userId={id} />
      </COSTabPanel>
    </Container>
  );
};

export default withErrorHandler(UserComponent, Axios);

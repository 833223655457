import { useAsync, useMe } from '.';
import { useEffect, useMemo } from 'react';

import { InteractionTypeKey } from '../../services/cadabraService';
import { UserStatsResponse } from '../../services/api';
import { cadabraService } from '../../services/services';

export interface IUserContactChartData {
  labels: string[];
  datasets: Array<{
    label: string;
    data: number[];
    backgroundColor: string[];
  }>;
  responseRate: number;
  submissionRate: number;
  setParameters: any;
}

export const contactChartTypeKeys = [
  InteractionTypeKey.CONTACTED_LINKEDIN_MESSAGE,
  InteractionTypeKey.CONTACTED_IN_MAIL,
  InteractionTypeKey.CONTACTED_VIA_INVITATION,
  InteractionTypeKey.CONTACTED_PHONE_CALL,
  InteractionTypeKey.CONTACTED_EMAIL,
  InteractionTypeKey.CONTACTED_MEETING,
  InteractionTypeKey.PUSH_LINKEDIN_MESSAGE,
  InteractionTypeKey.PUSH_IN_MAIL,
  InteractionTypeKey.PUSH_VIA_INVITATION,
  InteractionTypeKey.PUSH_PHONE_CALL,
  InteractionTypeKey.PUSH_EMAIL,
  InteractionTypeKey.PUSH_MEETING,
  InteractionTypeKey.LEGACY,
  InteractionTypeKey.REPLIED,
  InteractionTypeKey.SUBMITTED
];

const useUserContactChartData = (
  startDate?: string | undefined,
  endDate?: string | undefined
): IUserContactChartData => {
  const { user } = useMe();
  const { data, setParameters } = useAsync<UserStatsResponse[]>(
    cadabraService.getStatsByUser,
    [],
    null
  );

  useEffect(() => {
    if (user) {
      setParameters([user.id, startDate, endDate, contactChartTypeKeys.join(',')]);
    }
  }, [user, startDate, endDate]);

  const contacts = useMemo(
    () =>
      data.reduce(
        (res, e) =>
          res +
          (e.type !== InteractionTypeKey.REPLIED && e.type !== InteractionTypeKey.SUBMITTED
            ? e.count
            : 0),
        0
      ),
    [data]
  );
  const replied = useMemo(
    () => data.reduce((res, e) => res + (e.type === InteractionTypeKey.REPLIED ? e.count : 0), 0),
    [data]
  );
  const submitted = useMemo(
    () => data.reduce((res, e) => res + (e.type === InteractionTypeKey.SUBMITTED ? e.count : 0), 0),
    [data]
  );
  const responseRate = useMemo(
    () => calculateRateInPercentage(replied, contacts),
    [data]
  );
  const submissionRate = useMemo(
    () => calculateRateInPercentage(submitted, contacts),
    [data]
  );

  return {
    labels: ['Me'],
    datasets: [
      {
        label: 'Contacted',
        data: [contacts],
        backgroundColor: ['#FF6384']
      },
      {
        label: 'Replied',
        data: [replied],
        backgroundColor: ['#36A2EB']
      },
      {
        label: 'Submitted',
        data: [submitted],
        backgroundColor: ['#FFCE56']
      }
    ],
    responseRate,
    submissionRate,
    setParameters
  };
};

export default useUserContactChartData;

const divide = (numerator: number, denominator: number): number => {
  return denominator === 0 ? 0 : numerator / denominator;
};

const calculateRateInPercentage = (a: number, b: number): number => Math.round(divide(a * 100, b) * 100) / 100;

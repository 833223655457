import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { COSGaugeChart, COSStatsChart } from '../../helpers/ui';
import React, { useEffect, useState } from 'react';
import { TimePeriod, useDashboardInfo, useMe } from '../../helpers/hooks';

import Axios from 'axios';
import moment from 'moment';
import withErrorHandler from '../../helpers/hoc/withErrorHandler/withErrorHandler';

const calculatePeriod = (timePeriod: TimePeriod) => {
  let startMoment;
  let endMoment;
  if (timePeriod === 'HALF_YEAR') {
    if (moment().month() < 6) {
      startMoment = moment().month(0).startOf('month').subtract(6, 'month');
      endMoment = moment().month(5).endOf('month');
    } else {
      startMoment = moment().month(6).startOf('month').subtract(6, 'months');
      endMoment = moment().month(11).endOf('month');
    }
  } else {
    const timePeriodValue: TimePeriod = timePeriod.toLocaleLowerCase() as TimePeriod;
    if (timePeriod === 'WEEK') {
      startMoment = moment()
        .startOf(timePeriodValue as moment.unitOfTime.StartOf)
        .subtract(1, 'day');
      endMoment = moment()
        .endOf(timePeriodValue as moment.unitOfTime.StartOf)
        .add(1, 'days');
    } else {
      startMoment = moment().startOf(timePeriodValue as moment.unitOfTime.StartOf);
      endMoment = moment().endOf(timePeriodValue as moment.unitOfTime.StartOf);
    }
  }
  return [startMoment, endMoment];
};

const HomeComponent = () => {
  const { user } = useMe();
  const [filter, setFilter] = useState<TimePeriod>('WEEK');
  const {
    personalChartData,
    companyChartData,
    userProcessChartData,
    responseRate,
    submissionRate,
    setParameters
  } = useDashboardInfo(
    'WEEK',
    moment().startOf('week').format('YYYY-MM-DD'),
    moment().endOf('week').format('YYYY-MM-DD'),
    user?.id
  );

  useEffect(() => {
    const [startMoment, endMoment] = calculatePeriod(filter);
    setParameters(
      filter,
      user?.id ? [user.id] : [],
      startMoment.format('YYYY-MM-DD'),
      endMoment.format('YYYY-MM-DD')
    );
  }, [filter]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, filter: TimePeriod) => {
    if (filter) {
      setFilter(filter);
    }
  };

  const control = {
    value: filter,
    onChange: handleChange,
    exclusive: true
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // TODO Replace with Stack
            '& > :not(style) + :not(style)': { mt: 2 }
          }}
        >
          <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
            <ToggleButton value="DAY" key="DAY">
              <Typography>D</Typography>
            </ToggleButton>
            <ToggleButton value="WEEK" key="WEEK">
              <Typography>W</Typography>
            </ToggleButton>
            <ToggleButton value="MONTH" key="MONTH">
              <Typography>M</Typography>
            </ToggleButton>
            <ToggleButton value="QUARTER" key="QUARTER">
              <Typography>Q</Typography>
            </ToggleButton>
            <ToggleButton value="HALF_YEAR" key="HALF_YEAR">
              <Typography>H</Typography>
            </ToggleButton>
            <ToggleButton value="YEAR" key="YEAR">
              <Typography>Y</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>
      <Grid item lg={6} md={12} sm={12}>
        <Grid container>
          <Grid item lg={8} md={8}>
            <COSStatsChart
              title="Contacts"
              labels={personalChartData.labels}
              datasets={personalChartData.datasets}
              legend={personalChartData.legend}
              tooltip={personalChartData.tooltip}
              annotations={personalChartData.annotations}
            />
          </Grid>
          <Grid item lg={4} md={4} sx={{ marginTop: 2 }}>
            <Grid container>
              <Grid item lg={12}>
                <Typography
                  sx={{
                    width: '80%',
                    margin: 'auto',
                    fontSize: 12,
                    fontFamily: 'Arial',
                    fontWeight: 'bold',
                    color: '#6A6A6A'
                  }}
                >
                  Response rate
                </Typography>
                <COSGaugeChart value={responseRate} animate={true} />
              </Grid>
              <Grid item lg={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    width: '80%',
                    margin: 'auto',
                    fontSize: 12,
                    fontFamily: 'Arial',
                    fontWeight: 'bold',
                    color: '#6A6A6A'
                  }}
                >
                  Submission rate
                </Typography>
                <COSGaugeChart value={submissionRate} animate={true} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={12}>
        <COSStatsChart
          title="Company"
          labels={companyChartData.labels}
          datasets={companyChartData.datasets}
          legend={companyChartData.legend}
          tooltip={companyChartData.tooltip}
        />
      </Grid>
      <Grid item lg={12} md={12} sx={{ width: '100%', height: '500px' }}>
        <COSStatsChart
          title="Process by user"
          labels={userProcessChartData.labels}
          datasets={userProcessChartData.datasets}
          legend={userProcessChartData.legend}
          tooltip={userProcessChartData.tooltip}
        />
      </Grid>
    </Grid>
  );
};

export default withErrorHandler(HomeComponent, Axios);

export interface Day {
  date: string;
  count: number;
  level: 0 | 1 | 2 | 3 | 4;
}
